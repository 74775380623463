export default {
  placeholder: 'Platzhalter',
  placeholderStrong: '**Platzhalter**',
  verifiedUnverified: {
    verified: 'Verifiziert',
    unverified: 'Nicht verifiziert',
  },
  defaultErrMsg: 'Etwas ist schief gelaufen!',
  defaultSuccessMsg: 'Ihre Änderungen wurden gespeichert.',
  noConceptSourceAvailable:
    '_Zu dieser Aktivität liegt aktuell keine Quelle vor._',
  general: {
    conceptActionsTableHeaders: {
      action: 'Aktivität',
      implementation: 'Umsetzung',
    },
    addMissingAction: 'Fehlende Maßnahme hinzufügen',
    headers: {
      'portfolio-dashboard': 'Dashboard',
      'portfolio-esgList': 'ESG',
      'portfolio-impactGoalList': 'Impact', // v2
      'portfolio-impactList': 'Impacts',
      'portfolio-impactComparison': 'Impact Vergleich',
      'portfolio-metrics': 'KPIs',
      'portfolio-task': 'Aufgaben',
      'portfolio-funds': 'Fonds ',
      'portfolio-impact-screening': 'Impact Screening',
      'admin-categories': 'Kategorien',
      'admin-accounts': 'Konto',
      'admin-users': 'Nutzer:in',
      'admin-kpis': 'KPI MGM',
      'admin-kpis-datapoint': 'Datapoint',
      'admin-kpis-aggregated': 'Aggregated',
      'admin-esgs': 'ESG MGM',
      'admin-sections': 'Section',
      'admin-reports': 'Fund Reports',
      'admin-funds': 'Fund MGM',
      'admin-audit': 'Audit',
      modules: 'Module',
      companyInfo: 'Profil',
      esg: 'ESG',
      impact: 'Impact',
      kpis: 'KPIs',
      impactGoal: 'Impact',
      openTasks: 'Offene Aufgaben',
      impactScreening: 'Wirkungsidentifikation',
      impactAssessment: 'Wirkungsbewertung',
      tasks: 'Aufgaben',
      csrd: 'CSRD',
      csrdDashBoard: 'Dashboard',
      csrdCompany: 'Company',
      csrdValueChain: 'Value Chains',
      csrdIROs: 'IROs',
      csrdStakeholders: 'Stakeholders',
      csrdESRS: 'ESRS',
    },
    generalNavigation: {
      invitePortfolioCompanies: 'Portfolio-Unternehmen einladen',
      inviteTeamMembers: 'Teammitglieder einladen',
      signOut: 'Abmelden',
      termsAndConditions: 'Bedingungen und Konditionen',
    },

    duration: 'Dauer',
    backButton: 'Zurück',
    saveAndClose: 'Speichern & schließen',
    submitButton: 'Absenden',
    submitFeedbackButton: 'Feedback abgeben',
    cancelButton: 'Abbrechen',
    evidence: {
      addEvidenceButton: 'Quelle hinzufügen',
      evidenceDialog: {
        saveEvidenceButton: 'Quelle speichern',
        title: 'Quelle hinzufügen',
        evidence: {
          title: 'Quelle',
          subtitle:
            'Bitte geben Sie einen direkten Link zu der Quelle, auf die Sie sich beziehen, oder bibliografische Informationen an, damit andere sie finden können.',
          placeholder:
            'https://psci.princeton.edu/tips/2020/7/20/the-impact-of-fast-fashion-on-the-environment',
        },
        keyInsights: {
          title: 'Wichtige Erkenntnisse',
          subtitle:
            'Geben Sie eine kurze Zusammenfassung der wichtigsten Erkenntnisse aus der Quelle',
        },
        comment: {
          placeholder:
            'Kurze Zusammenfassung für die wichtigsten Erkenntnisse aus der Quelle',
        },
        examples: {
          title: 'Beispiele',
          content: [
            'Der globale Markt für Kunststoffrecycling wird voraussichtlich mit einer CAGR von 5,82% wachsen in den Jahren 2019-2028.',
            'Verteilung von Kunststoffabfällen (alle jemals produzierten Abfälle). 79 % aller Kunststoffe landen auf Mülldeponien oder in der natürlichen Umwelt.',
          ],
        },
      },
    },

    contextInformation: 'Kontextinformationen',
    typicalKpis_Metrics: 'Typische zu berichtende KPIs / Metriken',
    interestingReads: 'Interessante Lektüre, die Sie weiterbringt',
    insightsOn: 'Einblicke in',
    nothingToDisclose: 'Keine Offenlegungsrisiken',
    description: 'Beschreibung',
    carEmployees: 'Mitarbeiter',
    carInsighstsOn: 'Einblicke in die Nachhaltigkeit in der Autohändlerbranche',
    carBestPracticeReports:
      'Best-Practice-Nachhaltigkeitsberichte, die Ihnen helfen, sich zu verbessern',
    notPlanned: 'Nicht geplant',
    plannedIn12Months: 'Geplant in den nächsten 12 Monaten',
    doingIt: 'Wird gemacht!',
    navigation: {
      previous: 'Zurück',
      continue: 'Weiter',
      areYouSure: 'Sind Sie sicher, dass Sie weitermachen wollen?',
      notComplete:
        'Sie haben noch nicht alle Pflichtfragen in diesem Abschnitt beantwortet. Denken Sie daran, dass Sie den Fragebogen erst abschicken können, wenn Sie alle Pflichtfragen beantwortet haben.',
      cancel: 'Abbrechen',
      delete: 'Löschen',
      update: 'Aktualisieren',
      add: 'Hinzufügen',
      getStarted: "Los geht's",
      close: 'Schließen',
    },
    validationErrors: {
      requiredError: 'Texteingabe ist obligatorisch!',
      lengthError: {
        prefix: 'Text muss weniger als ',
        suffix: 'Zeichen haben',
      },
    },
    formInput: {
      combobox: {
        header: {
          base: 'Drücken Sie nach der Eingabe "Enter", um einen neuen Artikel zu erstellen.',
          multiple: 'Sie können mehrere Einträge erstellen.',
          delete:
            'Sie können einen Eintrag löschen, indem Sie die Taste "Entf" drücken.',
        },
      },
    },
  },
  companyInfo: {
    basicInformation: 'Grundlegende Informationen',
    pitchLine: 'Pitchline',
    legalEntity: 'Juristische Person',
    founders: 'Gründer',
    headquarter: 'Hauptquartier',
    country: 'Land',
    foundingYear: 'Gründungsjahr',
    employeeHeadcount: 'Mitarbeiterzahl (geplant für dieses Jahr)',
    turnover: 'Umsatz (geplant für dieses Jahr)',
    fundingStage: 'Aktueller Stand der Finanzierung',
    additionalContextInformation: 'Zusätzliche Kontextinformationen',
    businessModel: 'Geschäftsmodell',
    incomeStream: 'Haupteinkommensquelle',
    products: 'Produkte/Dienstleistungen',
    industries: 'Industrien',
    customerGroup: 'Kundengruppe',
    csrd: {
      yes: 'Ja',
      no: 'Nein',
      iDontKnow: 'Weiß nicht',
      title:
        'Unterliegen den Offenlegungspflichten der Corporate Sustainability Reporting Directive (CSRD) und müssen gemäß den European Sustainability Reporting Standards (ESRS) berichten.',
      options: {
        2025: '2025 (Berichtsjahr 2024)',
        2026: '2026 (Berichtsjahr 2025)',
        2027: '2027 (Berichtsjahr 2026)',
        later: 'Zu einem späteren Zeitpunkt',
      },
    },
    explanation: {
      title: 'Eingabe Ihrer Unternehmensdaten',
      text: `
- In diesem Abschnitt können Sie Ihre Unternehmensinformationen eingeben und bearbeiten.
- Ihre Eingaben werden bei der Eingabe gespeichert und auf Ihrem Dashboard angezeigt.
- Die Daten werden uns dabei helfen, Ihnen in Zukunft vergleichbare Benchmarks anzubieten
`,
    },
  },

  modules: {
    header: {
      availableModules: 'Verfügbare Module',
    },
    dashboard: {
      sectionTitle: 'Key Facts',
      sectionSubTitle:
        'Status des Nachhaltigkeitsmanagements für das Portfolio',
      keyFacts: {
        numberOfVentures: 'Unternehmen im Portfolio',
        numberOfCompletedQuestionnaires:
          'Unternehmen mit abgeschlossener ESG-Bewertung',
        numberOfVenturesWithCriticalEsgIssues:
          'Unternehmen mit kritischen ESG-Problemen',
        numberOfVenturesWithCriticalDisclosureIssues:
          'Unternehmen mit kritischen Offenlegungsproblemen',
      },

      impactGoalPerf: {
        sectionTitle: 'Impact Goal Performance',
        sectionSubTitle: 'Aggregation über {n} Unternehmen',
        sectionImpactBySGD: 'Impact nach SDG',

        option: {
          positive: 'Positive impacts',
          negative: 'Negative impacts',
        },
        sectionImpactByGeo: 'Impact nach Geographie',
        geo: {
          legend: 'Anzahl der Wirkungen in dieser Geographie',
        },
      },
      esg: {
        sectionTitle: 'ESG performance',
        sectionSubTitle: 'Aggregation über {n} Unternehmen',
        totalEsgScore: 'Gesamtpunktzahl ESG',
        dimensionScore: 'Dimension Punktzahl',
        disclosures: 'Offenlegungen',
      },

      aggregatedKPIs: {
        sectionTitle: 'Aggregierte Kennzahlen',
        sectionSubTitle: 'Tatsächliche vs. Ziel-Leistung für alle Unternehmen',
        trackedMetrics: 'Verfolgte Kennzahlen',
      },
    },

    companyInfo: {
      header: 'Infos zum Unternehmen',
      title: 'Informationen zum Unternehmen',
      description:
        'Geben Sie grundlegende Informationen über Ihr Unternehmen an',
      buttonContent: "Los geht's",
    },

    esgAssessment: {
      header: 'ESG-Bewertung',
      title:
        'Module, die Ihnen helfen, Umwelt-, Sozial- und Governance-Themen, die ein **wesentliches finanzielles Risiko** für Ihr Unternehmen darstellen, zu identifizieren, zu verwalten und darüber zu berichten.',
      adverseImpactScreening: {
        title: 'Screening nachteiliger Wirkungen',
        description: 'Schnelles Impact Screening für Ihr Unternehmen',
        duration: 'sofort',
        buttonContent: "Los geht's",
        upcomingText: 'Demnächst',
      },
      esgAssessment: {
        title: 'ESG-Bewertung',
        description:
          'Verschaffen Sie sich einen schnellen Überblick inwiefern Sie die Themen Umwelt, Soziales und verantwortungsvolle Unternehmensführung berücksichtigen',
        duration: '~30 min',
        buttonContent: "Los geht's",
        notBooked: 'Sie haben dieses Modul nicht gebucht',
      },
      metrics: {
        title: 'KPI-Berichterstattung',
        description:
          'Wählen Sie Nachhaltigkeits-KPIs für Ihr Unternehmen, erstellen Sie Prognosen und verfolgen Sie Ihre Leistung',
        buttonContent: "Los geht's",
      },
    },
    impactGoals: {
      header: 'Wirkungsziele + Treibhausgas-Einsparungspotenzial',
      title:
        'Module, die Ihnen helfen, die **relevantesten sozialen und ökologischen Wirkungen Ihres Unternehmens** zu ermitteln, zu verwalten und zu melden (unabhängig von den direkten finanziellen Wirkungen auf Ihr Unternehmen).',
      impactGoals: {
        title: 'Wirkungsziele',
        description:
          'Definieren Sie die Wirkungsziele Ihres Unternehmens und schätzen Sie das Potenzial Ihrer Produkte und Dienstleistungen zur Verringerung der Treibhausgasemissionen in Bezug auf Ihre klimabezogenen Wirkungen ein.',
        duration: '15-30min',
        buttonContent: "Los geht's",
        getStartedWithImpactsText:
          'Sie haben noch keine Auswirkungen definiert. Legen Sie jetzt los!',
      },
      impactScoring: {
        title: 'Impact Scoring',
        description:
          'Definieren und bewerten Sie Ihre Wirkungen nach den internationalen Best Practices des Impact Management Projects',
        duration: '5 - 15 min pro Wirkungsziel',
        buttonContent: 'Verfügbar auf Anfrage',
        upcomingText: 'Verfügbar auf Anfrage',
      },
      ghgSavingPotential: {
        title: 'Treibhausgas-Einsparpotenzial',
        description:
          'Berechnen Sie das THG-Einsparpotenzial für die wichtigsten Klimawirkungen Ihrer Produkte und Dienstleistungen',
        duration: '~ca. 20 Minuten pro Klimawirkung',
        buttonContent: 'Speichern der Potenzialberechnung starten',
        upcomingText: 'Demnächst',
      },
    },
  },
  esg: {
    portfolioListView: {
      headers: {
        venture: { name: 'Unternehmen' },
        groupNames: 'Gruppen',
        totalscore: 'Gesamtpunktzahl ESG',
        envperformance: 'Umweltleistung',
        socperformance: 'Soziale Leistung',
        govperformance: 'Governance-Leistung',
        disclosureDetails: 'Offenlegungsdetails',
        investment: 'Investition',
        update: 'Datum der Einreichung',
        comment: 'Kommentare des Portfolio-Managers',
      },
      sortBy: 'Sortiert nach {column}',
      addComment: 'Kommentar hinzufügen',
    },
    header: {
      version: 'Version',
      compare: 'ESG-Ergebnisse vergleichen',
      type: 'Art der Bewertung',
      notComplete:
        'Sie haben den ESG-Fragebogen noch nicht ausgefüllt. Fangen Sie jetzt an!',
    },
    introduction: {
      welcomeTitle: 'Willkommen bei der ESG-Bewertung',
      welcomeBackTitle: 'Willkommen zurück zur ESG-Bewertung',
      welcomeText:
        'Dieser 30-minütige Fragebogen gibt Ihnen ein besseres Verständnis dafür, ob Ihr Unternehmen **die wichtigsten ESG*-Aspekte berücksichtigt**.',
      welcomeBackText: `Sie sind eingeladen, Ihre ESG*-Leistung neu zu bewerten. Die Bewertung zeigt die Daten, die Sie bei der letzten Bewertung eingegeben haben. **Bitte aktualisieren Sie Ihre Angaben, wenn sich etwas geändert hat**. Andernfalls werden die Angaben vom letzten Mal beibehalten.`,
      caption:
        '* ESG = Umwelt-, Sozial- und Governance-Kriterien, die Ihr Unternehmen gemäß den Vorschriften und internationalen Standardsetzern wie GRI, SASB oder KfW Capital einhalten sollte',
    },
    introduction_v2: {
      welcomeTitle: 'Willkommen bei der ESG-Bewertung',
      welcomeText:
        'Dieser Fragebogen gibt Ihnen ein besseres Verständnis dafür, ob Ihr Unternehmen **die wichtigsten ESG*-Aspekte berücksichtigt**. Die durchschnittliche Bearbeitungszeit beträgt 30 Minuten.',
      welcomeBackTitle: 'Willkommen zurück zur ESG-Bewertung',
      welcomeBackText: `Sie sind eingeladen, Ihre ESG*-Leistung neu zu bewerten. Die Bewertung zeigt die Daten, die Sie bei der letzten Bewertung eingegeben haben. **Bitte aktualisieren Sie Ihre Angaben, wenn sich etwas geändert hat**. Andernfalls werden die Angaben vom letzten Mal beibehalten.`,
      caption:
        '* ESG = Umwelt-, Sozial- und Governance-Kriterien, die Ihr Unternehmen gemäß den Vorschriften und internationalen Standardsetzern wie GRI, SASB oder KfW Capital einhalten sollte',
    },
    whoIsItFor: {
      title: 'Für wen ist es gedacht?',
      text: `
- Alle Unternehmen können die Bewertung nutzen, um einen ersten Überblick über ihre ESG-Leistung zu erhalten
- Die Bedeutung der verschiedenen ESG-Kategorien hängt von Ihrem Geschäftsmodell und Ihrem Entwicklungsstadium ab.
`,
    },
    questionStructure: {
      title: 'Struktur der Fragen',
      text: `Sie finden Fragen zu 15 verschiedenen Kategorien, 5 zu ökologischen, 5 zu sozialen und 5 zu Governance-Themen.

Jede Kategorie hat zwei Ebenen von Fragen:


- 1. Ebene: Ergreifen Sie Maßnahmen zu einem Thema?
- 2. Ebene: Welche Maßnahmen ergreifen Sie?
`,
    },
    questionStructure_v2: {
      text: ` - **Sie finden Fragen zu 15 verschiedenen Kategorien**, 5 zu Umwelt-, 5 zu Sozial- &amp; 5 zu Governance Themen.
- Für jede der 15 Kategorien werden **Maßnahmen vorgeschlagen, um das derzeitige Niveau der ESG-Leistung zu verfolgen oder zu verbessern**.
- Bitte wählen Sie für jede Kategorie die **Maßnahmen aus, die auf Ihr Unternehmen zutreffen** und geben Sie einige **Informationen über den Umsetzungsstatus**.
  `,
    },
    implementation: {
      title: 'Stand der Umsetzung einer Maßnahme',
      text1:
        'Für jede Frage werden verschiedene Antwortmöglichkeiten vorgeschlagen, durch deren Anklicken Sie den Umsetzungsstatus der Maßnahme ändern können.',
      text2:
        'Wenn Sie einmal klicken, ändert sich der Status in **Wird umgesetzt!**, z. B.',
      text3:
        'Wenn Sie zweimal klicken, ändert sich die Anzeige in **Geplant in den nächsten 12 Monaten**, z. B.',
      text4:
        'Wenn Sie ein drittes Mal klicken, wechselt die Anzeige wieder zu **Nicht geplant** (Standardeinstellung), z. B.',
      concept: 'Dienst-Fahrrad',
    },
    moreInformation: {
      title: 'Weitere Informationen',
      text: 'Weitere Einzelheiten zum ESG-Fragebogen finden Sie, wenn Sie auf das Info-Symbol unten rechts auf Ihrem Bildschirm klicken.',
    },

    tour: {
      title: 'Willkommen bei der ESG-Bewertung!',
      subtitle:
        '## ESG Modul: Schnelle Einführung.\n<br>\nDas folgende Video gibt Ihnen einen Überblick über die Durchführung einer ESG-Bewertung mit der ImpactNexus-Softwarelösung.',
      details: 'Details zur ESG-Bewertung',
      section1: {
        title: 'Hintergrund der Bewertung',
        text: '- Der Fragebogen wurde anhand von **15 verschiedenen ESG-Kategorien** erstellt, um die wichtigsten Umwelt-, Sozial- und Governance-Themen zu erfassen, die Unternehmen berücksichtigen sollten.\n- Diese Themen wurden **aus einer umfassenden Überprüfung bestehender ESG-Standards** für neue und etablierte Unternehmen (z. B. KfW Capital, GRI, SASB) abgeleitet.\n- Die ESG-Umfrage ist größtenteils so konzipiert, dass sie von allen Unternehmen** beantwortet werden kann, unabhängig von ihrem Entwicklungsstadium, Geschäftsmodell oder Sektor.\n- **Eine perfekte Leistung kann von einem jungen Unternehmen** nicht erwartet werden. Die Nutzer sollten diese Bewertung als ein Leitinstrument betrachten, das den Unternehmen hilft, **relevante ESG-Risiken und Verbesserungsbereiche zu identifizieren.**',
        example:
          '# Meine verschachtelte Liste\n\n- Posten 1\n - Unterposten 1.1\n - Unterposten 1.2\n- Posten 2\n - Unterposten 2.1\n - Unterposten 2.2\n- Posten 3',
      },
      section2: {
        title: 'Struktur der Fragen',
        text: '- Für jede der 15 Kategorien werden Maßnahmen vorgeschlagen, um das aktuelle Niveau der ESG-Leistung zu verfolgen oder zu verbessern. Die **Maßnahmen zur Verfolgung des Status quo der ESG-Leistung** sind dem Abschnitt ""Monitoring & Evaluation"" zugeordnet. **Maßnahmen zur Verbesserung des Status quo der ESG-Leistung** werden dem Abschnitt "Umsetzung" zugeordnet.\n- Bitte gehen Sie bei der Beantwortung des Fragebogens wie folgt vor.\n - Bitte wählen Sie für jeden Abschnitt die Maßnahmen aus, die für Ihr Unternehmen relevant sind, und geben Sie einen Umsetzungsstatus an. Bitte wählen Sie eine der folgenden Optionen:\n - Wird umgesetzt!\n - Geplant in den nächsten 12 Monaten.\n - Nicht geplant.\n - Bitte wählen Sie "Wir planen keine Maßnahmen", wenn keine der aufgelisteten Maßnahmen auf Ihr Unternehmen zutrifft.\n - Wenn relevante Maßnahmen fehlen, fügen Sie diese bitte im Abschnitt "Zusätzliche Maßnahmen" hinzu.\n - Falls erforderlich, können Sie auch einen Kommentar hinzufügen, der später in der Ergebnisübersicht angezeigt wird.',
      },
      section3: {
        title: 'Bewertung Ihrer ESG-Leistung',
        text: '- Die Bewertung basiert auf den Maßnahmen, die Sie ergreifen oder zu ergreifen planen. Die maximal erreichbare Punktzahl für jede der 15 ESG-Kategorien beträgt 20 Punkte. Für jeden der drei Bereiche Umwelt, Soziales und Unternehmensführung kann ein Unternehmen bis zu 100 Punkte erhalten. Die Punktevergabe erfolgt nach folgenden Regeln:\n - **In jedem Bereich muss eine Mindestanzahl von Maßnahmen ausgewählt werden**, um Punkte zu erhalten ("Schwellenwert").\n - **Maßnahmen, die zu einer Verbesserung des Status quo beitragen, erhalten eine höhere Punktzahl** als Maßnahmen, die der Nachverfolgung des Status quo dienen.\n - **Wenn eine Maßnahme bereits umgesetzt ist ("Wird umgesetzt!"), erhält das Unternehmen eine volle Punktzahl**, und wenn sie für die nächsten 12 Monate geplant ist, erhält es eine halbe Punktzahl. Für Maßnahmen, die nicht geplant sind, gibt es keine Punkte.\n - **Wenn Sie zusätzliche Maßnahmen hinzufügen, erhalten Sie Bonuspunkte.** Wenn Sie also für die vorgeschlagenen Maßnahmen keine Punkte erhalten, weil Sie z. B. der Meinung sind, dass keine relevanten Maßnahmen aufgeführt sind, können Sie dies durch die Angabe zusätzlicher Maßnahmen kompensieren und die damit verbundenen Bonuspunkte erhalten.\n - **Die Punktzahl reicht von 0 (niedrigste) bis 100 (höchste)**. Es ist jedoch wichtig, darauf hinzuweisen, dass es schwierig ist, eine Punktzahl nahe 100 zu erreichen (insbesondere für Unternehmen in der Anfangsphase):\n - 67-100: Gute Leistung\n - 34-66: Mittelmäßige Leistung\n - 0-33: Kritische Leistung',
      },
      section4: {
        title: 'Nächste Schritte',
        text: '- Nach der Auswertung der Bewertung ist es wichtig:\n - Nutzen Sie die Bewertungen, um **die kritischsten ESG-Themen** zu identifizieren. Versuchen Sie nicht, alle Themen auf einmal anzugehen.\n - **Recherchieren Sie nach Hebeln zur Verbesserung**. Überprüfen Sie die vorgeschlagenen Maßnahmen und sehen Sie sich die Verbesserungsvorschläge im ESG-Dashboard an.',
      },
    },
    outro: {
      titleSubmit: 'Übermitteln Sie Ihre Ergebnisse',
      haveYouFinished:
        'Haben Sie Ihre ESG-Bewertung abgeschlossen? Sie **können** Ihre ESG-Bewertung nach dem Einreichen nicht mehr bearbeiten',
      submitButton: 'Einreichen',
      youHaveNotAnswered:
        'Sie haben noch nicht alle Pflichtfragen beantwortet. Gehen Sie zurück, um die fehlende Frage zu finden und diesen Fragebogen zu vervollständigen. Ausgefüllte Abschnitte sind mit einem Häkchen versehen. Unvollständige Abschnitte haben einen blauen Fortschrittsbalken.',
      answersAreAlreadySubmitted:
        'Die Antworten wurden bereits übermittelt. Sie können sie nicht erneut einreichen.',
      submitYourResults: 'Übermitteln Sie Ihre Ergebnisse',
      areYouSureYouWantTo:
        'Sind Sie sicher, dass Sie Ihre Daten übermitteln wollen? Sobald Sie Ihre Daten übermittelt haben, können Sie **ihre ESG-Bewertung nicht mehr ändern**.',
      cancelButton: 'Abbrechen',
      submit_v2: 'Einreichen',
      congratulations:
        'Herzlichen Glückwunsch, Sie haben die Bewertung abgeschlossen!',
      congratulationsText: `
- Die Ergebnisse finden Sie auf der Registerkarte ESG,
- **Die Punktzahl reicht von 0 (niedrigste Punktzahl) bis 100 (höchste Punktzahl).** Es ist jedoch wichtig darauf hinzuweisen, dass es insbesondere für Unternehmen in der Anfangsphase schwierig ist, eine Punktzahl nahe an 100 zu erreichen.',
- **Nutzen Sie die Bewertungen, um die kritischsten ESG-Themen zu ermitteln** Versuchen Sie nicht, alle Themen auf einmal anzugehen.
- **Auf der Registerkarte ESG finden Sie für jede der 15 Kategorien einige Verbesserungsvorschläge.`,
      continueButton: 'Weiter',
      beforeYouGo: 'Bevor Sie zu den Ergebnissen kommen, ein kurzes Feedback',
      helpUsImprove: 'Helfen Sie uns, Ihr Erlebnis zu verbessern',
      howHappyWereYou:
        'Wie zufrieden waren Sie mit dem Modul (Struktur, Design und Inhalt)?',
      weLoveFeedback:
        'Wir freuen uns über Feedback! Bitte teilen Sie uns Ihre Verbesserungsvorschläge mit.',
      shareYourFeedback: 'Bitte teilen Sie Ihr Feedback hier mit',
      submitFeedback: 'Feedback abgeben',
    },
    resultView: {
      assessmentResult: 'Ergebnis für ',
    },
    sectionDetailsExplanations: {
      whyYouShouldCare: 'Warum Sie das interessieren sollte',
      energy:
        'Der Erde gehen die nicht-erneuerbaren Energieressourcen wie Kohle, Erdöl und Erdgas aus. Daher wird es immer wichtiger, Energie zu sparen und erneuerbare Energiequellen zu finden. Darüber hinaus sind die fossilen Energieressourcen eine der Hauptursachen für Umweltprobleme, z. B. durch die zunehmenden Treibhausgasemissionen.',

      ghgEmissions:
        'Der Klimawandel wird die Volkswirtschaften sowie der Bevölkerung schädigen, die Ressourcenknappheit verstärken und die Kosten der Geschäftstätigkeit drastisch beeinflussen. Daher ist es sowohl aus humanitären als auch aus geschäftlichen Gründen unerlässlich, dass Unternehmen aller Größenordnungen Maßnahmen ergreifen.',
      transportation:
        'Die Entscheidung für nachhaltige Verkehrsmittel senkt die Geschäftskosten, verringert die Abhängigkeit von ausländischem Öl, verbessert die Luftqualität, reduziert Treibhausgasemissionen und fördert die öffentliche Gesundheit.',
      resourceConsumptionAndUsage:
        'Die Aktivitäten des Menschen haben die vorhandenen Ressourcen ständig unter Druck gesetzt und bei einigen sogar zu deren Erschöpfung geführt. Eine nachhaltige Bewirtschaftung der Ressourcen ist eine der besten Möglichkeiten, um die Erhaltung dieser unschätzbaren Ressourcen für künftige Generationen zu gewährleisten.',
      pollutionAndEnvironmentalHarm:
        'Wirtschaftliche Tätigkeiten können die Umwelt extrem schädigen. So entstehen beispielsweise bei vielen Produktionsprozessen Schadstoffe, die der Umwelt und allen Lebewesen in ihr (einschließlich des Menschen) langfristig schaden. Um diese Schäden einzudämmen, ist es wichtig, alle Arten von Schadstoffen, die mit der Unternehmenstätigkeit verbunden sind, zu überwachen und sie, wo immer möglich, zu reduzieren.',
      labor:
        'Die Einhaltung von Arbeits- und Menschenrechten entlang der gesamten Wertschöpfungskette ist aus ethischer, wirtschaftlicher und rechtlicher Sicht wichtig. In den letzten Jahren sind die Erwartungen von Kunden, Investoren und Aufsichtsbehörden gestiegen, was zu ständig steigenden Kosten für Fehlverhalten führt (z. B. in Form von hohen Geldstrafen und Reputationsschäden).',
      workplace:
        'Wenn Sie gute und faire Leistungspakete anbieten und sich um die Bedürfnisse Ihrer Mitarbeiter kümmern, kann das die Arbeitsmoral verbessern. Das wiederum kann die Motivation der Mitarbeiter erhöhen, die Mitarbeiterbindung steigern und zu mehr Produktivität beitragen. Darüber hinaus kann die Schaffung eines guten Arbeitsumfelds erheblich zu einer positiven Marke beitragen.',
      diversityInclusion:
        'Eine Organisation, die Vielfalt, Gleichberechtigung und Einbeziehung in den Vordergrund stellt, schafft ein Umfeld, in dem individuelle Unterschiede in verschiedenen Dimensionen respektiert und geschätzt werden. Ein solches Arbeitsumfeld führt zu einer besseren Zusammenarbeit zwischen den Mitarbeitern, erhöht die Mitarbeiterbindung und trägt zu mehr Produktivität bei.',
      societalHarm:
        'Unternehmen können mit ihren Produkten, Technologien und Geschäftsmodellen nachteilige Wirkungen auf ihre Kunden, Nutzer und die Gesellschaft insgesamt haben. Dementsprechend ist es von entscheidender Bedeutung, dass Unternehmen sich verantwortungsvoll verhalten und ihre Praktiken anpassen, um Risiken zu vermeiden und das gesellschaftliche Wohlergehen zu optimieren.',
      socialResponsibility:
        'Soziale Verantwortung kann dazu beitragen, dass Kunden Ihr Unternehmen als positive Kraft in der Gesellschaft wahrnehmen, zeigt Rechenschaftspflicht gegenüber Investoren und hilft Mitarbeiter zu gewinnen und zu halten. Die durchgeführten Projekte können dazu beitragen, das Bewusstsein für wichtige Anliegen zu schärfen.',
      data: 'Alle Unternehmen verfügen über Daten, z. B. Personalakten, Kundendaten, Produktinformationen, Finanztransaktionen usw. Die Einhaltung ordnungsgemäßer Datenschutzverfahren ist entscheidend, um Cyberkriminalität, Datenlecks und Datenverluste zu verhindern. Hinzu kommt, dass es immer mehr Geldstrafen für unangemessenes Verhalten gibt (z. B. im Zusammenhang mit der DSGVO).',
      stakeholderEngagement:
        'Die Einbindung von Stakeholdern hilft Organisationen, proaktiv die Bedürfnisse und Wünsche aller Personen zu berücksichtigen, die ein Interesse an ihrer Organisation haben. Dies kann Verbindungen, Vertrauen, Zuversicht und Akzeptanz für die wichtigsten Initiativen Ihrer Organisation fördern',
      businessEthics:
        'Unethisches Verhalten kann sowohl für Unternehmen als auch für die Gesellschaft schnell zum Schaden werden. Daher ist es von entscheidender Bedeutung, dass alle Mitarbeiter eines Unternehmens in die Lage versetzt werden, sich ethisch zu verhalten. Dies kann bedeuten, dass ein klarer Verhaltenskodex mit Leitlinien für ethische Entscheidungen aufgestellt wird, aber noch wichtiger ist, dass alle Mitarbeiter in die Lage versetzt werden, sich angemessen zu verhalten (z. B. durch Schulungen und eine geeignete Unternehmenskultur).',
      managementAdvisoryBoard:
        'Ein vielfältiges Managementteam und ein vielfältiger Beirat bieten die Möglichkeit, bessere Geschäftsentscheidungen zu treffen. Vielfältige Teams bringen mehr Erfahrungen und Perspektiven ein, wodurch Risiken besser erkannt werden können und mehr innovative Ideen entstehen.',
      regulationsStandards:
        'Für den langfristigen Erfolg eines Unternehmens ist es von zentraler Bedeutung, die einschlägigen Vorschriften und Normen zu berücksichtigen. In einem ersten Schritt ist die Einhaltung der einschlägigen Vorschriften von größter Bedeutung. In vielen Branchen ist es jedoch fast ebenso wichtig, Branchenstandards einzuhalten, um Kunden und Investoren zu gewinnen. Sowohl bei den Vorschriften als auch bei den Standards gewinnt die Nachhaltigkeit zunehmend an Bedeutung. Es gibt immer mehr Nachhaltigkeitsvorschriften zu Themen wie CO2, Transparenz der Lieferkette oder Datenverarbeitung. Gleichzeitig erwarten Kunden und Investoren zunehmend, dass Unternehmen ein dezidiertes Nachhaltigkeitsmanagement aufbauen und sich durch anerkannte Labels und Zertifikate auszeichnen.',
      unGlobalCompactPrinciples: `Der UN Global Compact ist ein nicht verbindlicher Pakt der Vereinten Nationen. Es handelt sich um einen prinzipienbasierten Rahmen für Unternehmen mit zehn Schlüsselprinzipien. Für die Bewertung bitten wir die Unternehmen, über die übergreifenden Themen Menschenrechte, Arbeit, Umwelt und Korruptionsbekämpfung zu berichten. Die vollständigen 10 Prinzipien sind:
#### Menschenrechte
- [Prinzip 1](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-1): Unternehmen sollen den Schutz der international verkündeten Menschenrechte unterstützen und achten; und
- [Prinzip 2](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-2): Sicherstellen, dass sie nicht an Menschenrechtsverletzungen beteiligt sind.
#### Arbeit
- [Prinzip 3](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-3): Unternehmen sollen die Vereinigungsfreiheit und die wirksame Anerkennung des Rechts auf Tarifverhandlungen wahren;
- [Prinzip 4](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-4): die Abschaffung aller Formen von Zwangs- und Pflichtarbeit;
- [Prinzip 5](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-5): die effektive Abschaffung der Kinderarbeit; und
- [Prinzip 6](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-6): die Beseitigung von Diskriminierung in Beschäftigung und Beruf.
#### Umwelt
- [Prinzip 7](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-7): Unternehmen sollten einen vorsorgenden Ansatz im Umgang mit Umweltproblemen unterstützen;
- [Prinzip 8](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-8): Initiativen zur Förderung einer größeren Verantwortung für die Umwelt ergreifen; und
- [Prinzip 9](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-9): Förderung der Entwicklung und Verbreitung von umweltfreundlichen Technologien.
#### Anti-Korruption
- [Prinzip 10](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-10): Unternehmen sollen gegen Korruption in all ihren Formen vorgehen, einschließlich Erpressung und Bestechung.
Weitere Informationen gibt es hier: [https://www.unglobalcompact.org/what-is-gc/mission/principles](https://www.unglobalcompact.org/what-is-gc/mission/principles))
`,
      controversialBusinessAreasActivities: `
Transparenz in Bezug auf kontroverse Geschäftsbereiche und Aktivitäten ist sehr wichtig, um als nachhaltiges Unternehmen zu gelten. In einem kontroversen Bereich tätig zu sein, bedeutet jedoch nicht automatisch, dass die Tätigkeit negativ ist. Oft spielen der spezifische Kontext und subjektive Präferenzen eine wichtige Rolle. Berücksichtigen Sie daher bitte die unternehmensspezifischen Erläuterungen.

Die ausgewählten Optionen fassen verschiedene Ausschlusskriterien der wichtigsten Akteure in diesem Bereich zusammen. Siehe z. B.:

- IFC (link: [http://www.ifc.org/exclusionlist](http://www.ifc.org/exclusionlist))
- KfW Capital (link: [https://www.kfw.de/PDF/Download-Center/Konzernthemen/Nachhaltigkeit/Ausschlussliste_EN.pdf](https://www.kfw.de/PDF/Download-Center/Konzernthemen/Nachhaltigkeit/Ausschlussliste_EN.pdf))
- FNG (link: [https://www.fng-siegel.org/media/downloads/FNG_Label_2021-Rules_of_Procedure.pdf](https://www.fng-siegel.org/media/downloads/FNG_Label_2021-Rules_of_Procedure.pdf))`,
      publicOrLegalControversies:
        'Transparenz in Bezug auf öffentliche Beschwerden oder rechtliche Schritte ist entscheidend, um als nachhaltiges Unternehmen zu gelten. Es ist wichtig, darauf hinzuweisen, dass eine Verwicklung in eine Kontroverse nicht automatisch bedeutet, dass man sich eines Fehlverhaltens schuldig gemacht hat. Es kann sich um falsche Behauptungen handeln, die berichtigt werden müssen. Wenn die Kontroversen gerechtfertigt sind, ist es wichtig zu klären, wie zukünftige Kontroversen vermieden werden sollen.',
      oecdGuidelinesForMultinationalEnterprises: `
Die [**OECD-Leitsätze für multinationale Unternehmen**](https://www.oecd-ilibrary.org/governance/oecd-leitsatze-fur-multinationale-unternehmen_9789264122352-de) sind Empfehlungen, die von Regierungen an multinationale Unternehmen gerichtet werden, die in oder aus den beitretenden Ländern tätig sind. Sie enthalten **freiwillige Grundsätze und Standards für verantwortungsvolles unternehmerisches Verhalten** in Bereichen wie Beschäftigung und Arbeitsbeziehungen, Menschenrechte, Umwelt, Offenlegung von Informationen, Bekämpfung von Bestechung, Verbraucherinteressen, Wissenschaft und Technologie, Wettbewerb und Besteuerung.
Die OECD-Leitsätze zielen nicht darauf ab, eine Ungleichbehandlung zwischen **multinationalen und einheimischen Unternehmen** einzuführen; sie spiegeln bewährte Verfahren für alle wider. Die OECD-Leitsätze sind für die Unternehmen nicht rechtsverbindlich, wohl aber für die Regierungen der Unterzeichnerstaaten, die verpflichtet sind, für die Umsetzung und Einhaltung der Leitsätze zu sorgen. Es wird zwar eingeräumt, dass **kleine und mittlere Unternehmen** möglicherweise nicht über die gleichen Kapazitäten verfügen wie größere Unternehmen, aber die Regierungen, die den Leitsätzen beitreten, ermutigen sie dennoch, die Empfehlungen der Leitsätze so weit wie möglich zu befolgen.`,
      unGuidingPrinciplesOnBusinessAndHumanRights: `
Die **UN-Leitprinzipien für Wirtschaft und Menschenrechte**

The **[UN Guiding Principles on Business and Human Rights](http: //www.ohchr.org/Documents/Publications/GuidingPrinciplesBusinessHR_EN.pdf)** sind eine Reihe von Leitlinien für Staaten und Unternehmen **zur Verhinderung, Bewältigung und Behebung von Menschenrechtsverletzungen im Rahmen von Geschäftstätigkeiten**.
Die **Grundsätze gelten für alle Staaten und für alle Unternehmen**, sowohl für transnationale als auch für andere, unabhängig von ihrer Größe, Branche, ihrem Standort, ihren Eigentumsverhältnissen und ihrer Struktur.
In diesem Zusammenhang erfordert die Verpflichtung zur Achtung der Menschenrechte, dass die Unternehmen:

- es vermeiden, durch ihre Aktivitäten nachteilige Wirkungen auf die Menschenrechte zu verursachen oder dazu beizutragen, und gegen sie vorgehen, falls welche auftreten
- sich bemühen, nachteilige Wirkungen auf die Menschenrechte, die direkt mit ihren Tätigkeiten, Produkten oder Dienstleistungen verbunden sind, durch ihre Geschäftsbeziehungen zu verhindern oder abzuschwächen, auch wenn sie nicht zu diesen Wirkungen beigetragen haben
**Die IAO-Übereinkommen**
- Vereinigungsfreiheit und effektive Anerkennung des Rechts auf Tarifverhandlungen
- Abschaffung aller Formen von Zwangs- oder Pflichtarbeit
- Die tatsächliche Abschaffung der Kinderarbeit
- Die Beseitigung von Diskriminierung in Beschäftigung und Beruf
`,
      paiIndicators: `Die PAI-Indikatoren sind eine Liste wichtigster negativer Nachhaltigkeitswirkungen (Principal Adverse Impacts), die von Finanzprodukten (z.B. Investmentfonds) und deren Investitionen (z.B. Unternehmen) ausgehen können.Sie wurden von den Europäischen Aufsichtsbehörden (ESAs) in [Anhang I](https://ec.europa.eu/finance/docs/level-2-measures/C_2022_1931_1_EN_annexe_acte_autonome_part1_v6.pdf) der Verordnung über die Offenlegung von Informationen über nachhaltige Finanzierungen (SFDR) festgelegt wurden.`,
    },
    scoring: {
      totalPoints:
        'Die Gesamtpunktzahl für die Frage wird als Summe der Punktzahlen der Kategorien berechnet, mit einem Maximum von {maxValue} Punkten.',
      monitoringEvaluation: 'Überwachung und Bewertung',
      implementation: 'Umsetzung',
      additionalMeasures: 'Zusätzliche Maßnahmen',
      youCanObtain:
        'Sie können Bonuspunkte für individuelle Maßnahmen erhalten:',
      category: 'Kategorie',
      pointsImplemented: '{points} Punkte für jede durchgeführte Maßnahme und',
      pointsPlanned: '{points} für jede geplante Maßnahme',
      maximumPoints: 'Maximale Punktzahl für diese Kategorie: {points}',
    },
    questionnaire: {
      clickOnceOrTwice:
        'Klicken Sie einmal, wenn Sie die Maßnahme bereits durchführen, und zweimal, wenn Sie planen, sie in den nächsten 12 Monaten durchzuführen. Wenn Sie keine Maßnahmen durchführen wollen, klicken Sie das Kästchen unten an.',
      additionalMeasuresTitle: 'Zusätzliche Maßnahmen',

      additionalMeasures:
        'Wenn Ihr Unternehmen zusätzliche ESG-Maßnahmen umsetzt, die oben nicht aufgeführt sind, können Sie hier benutzerdefinierte Maßnahmen und zugehörige Aktivitäten definieren.',
      createNewMeasure:
        'Erstellen Sie eine neue Maßnahme und bestätigen Sie mit der Eingabetaste.',
      weDoNotPlanAnyMeasures:
        'Wir planen keine Maßnahmen. Diese ESG-Kategorie ist für unser Unternehmen nicht relevant.',
      mandatory: 'Diese Frage ist obligatorisch',
      optional: '(optional)',
      pleaseExplainWhyYouDoNotPlan:
        'Bitte erläutern Sie in dem nachstehenden Textfeld, warum Sie keine Maßnahmen durchführen wollen.',
      schemes: {
        monitoringEvaluation: {
          title: 'Überwachung und Bewertung',
          description: 'Behalten Sie den Überblick über den Status quo',
        },
        implementation: {
          title: 'Umsetzung',
          description:
            'Verbessern Sie den Status quo, indem Sie Maßnahmen ergreifen',
        },
        reductionSubstitution: {
          title: 'Substitution & Reduktion',
          description:
            'Verbessern Sie den Status quo, indem Sie bessere Alternativen finden oder Ihre vermeidbaren negativen Wirkungen minimieren',
        },
        compensation: {
          title: 'Entschädigung',
          description:
            'Verbessern Sie den Status quo, indem Sie Ihre unvermeidlichen negativen Wirkungen abmildern',
        },
        additionalMeasures: {
          title: 'Zusätzliche Maßnahmen',
          description:
            'Hinzufügen von benutzerdefinierten Maßnahmen und deren Umsetzungsstatus zusätzlich zu den vorgeschlagenen Maßnahmen',
        },
      },
    },
    surveyPages: {
      disclosureSubsectionScoreCritical: 'Kritisch',
      disclosureSubsectionScoreGood: 'Gut',
      skipped: 'Ausgelassen',
      good: 'Gut',
      moderate: 'Mittelmäßig',
      critical: 'Kritisch',
      questionAdditionalInformation: 'Zusätzliche Informationen',
      questionScoringRules: 'Regeln für die Punktevergabe',
      notAvailable: 'N/A',
    },
    sectionDetails: {
      ghgEmissions: {
        leversComments: {
          c1: 'Der Leitfaden hilft Unternehmen bei der Erstellung eines THG-Emissionsinventars (Ermittlung, Verfolgung, Berechnung und Berichterstattung von THG-Emissionen).',
          c2: 'Scope-3-Emissionen Standard\nermöglicht es Unternehmen, die Wirkungen ihrer gesamten Wertschöpfungskette auf die Emissionen zu bewerten und festzustellen, wo sie ihre Reduzierungsmaßnahmen konzentrieren müssen.',
          c3: 'Technischer Leitfaden für die Berechnung von Scope-3-Emissionen',
          c4: 'Leitfaden für Scope-2-Emissionen: standardisiert, wie Organisationen Emissionen aus gekauftem oder erworbenem Strom, Dampf, Wärme und Kälte messen',
          c5: 'THG-Bilanzierungstool für Projekte zum Kohlenstoffausgleich.',
          c6: '9 Schritte, die Unternehmen unternehmen können, um die Emissionen von Geschäftsreisen zu reduzieren',
          c7: 'Wie man eine Ökobilanz für seine Produkte und Dienstleistungen erstellt',
          c8: 'Maßnahmen, die Unternehmen zum Ausgleich von Kohlenstoffemissionen ergreifen können.',
          c9: 'Wege zur umweltfreundlichen Gestaltung von Fabriken',
          c10: 'Wie man einen nachhaltigkeitsbewussten Lieferanten auswählt',
          c11: 'Wie man umweltbewusste Lieferanten auswählt',
          c12: 'Tipps und Empfehlungen für Unternehmen bei der Auswahl umweltbewusster Lieferanten',
        },
      },
      energy: {
        leversComments: {
          c1: 'Energiemanagementsystem Leitfaden für Unternehmen - vom Bundesministerium für Umwelt, Naturschutz und Reaktorsicherheit (BMU)',
          c2: 'Praktischer Leitfaden für die Einführung eines Energiemanagementsystems - von der Organisation der Vereinten Nationen für industrielle Entwicklung (UNIDO)',
          c3: '10 Strategien für das Energiemanagement',
          c4: '7 Strategien, um Ihr Unternehmen energieeffizienter zu machen',
          c5: 'Wie Unternehmen ein Energieaudit durchführen können.',
        },
      },
      transportation: {
        leversComments: {
          c1: 'Leitfaden für die Umsetzung einer Strategie für die Telearbeit.',
          c2: '9 Schritte, die Unternehmen unternehmen können, um die Emissionen von Geschäftsreisen zu reduzieren',
          c3: '7 Top-Tipps für nachhaltigeres/grüneres Reisen im Beruf',
          c4: 'Wie man einen nachhaltigkeitsbewussten Lieferanten auswählt',
          c5: 'Wie man umweltbewusste Lieferanten auswählt',
          c6: 'Tipps und Empfehlungen für Unternehmen bei der Auswahl umweltbewusster Lieferanten',
        },
      },
      resourceConsumptionAndUsage: {
        leversComments: {
          c1: 'Wie man eine Ökobilanz für seine Produkte und Dienstleistungen erstellt',
          c2: '6 Schritte zu einer effektiveren Wassereinsparung für Unternehmen',
          c3: 'Wie neue Unternehmen ihr Abfallmanagement verbessern können',
          c4: 'Die 20 besten Tipps zur Abfallvermeidung am Arbeitsplatz',
          c5: 'Schritt-für-Schritt-Leitfaden für nachhaltiges Lieferkettenmanagement des Bundesministeriums für Umwelt, Naturschutz, Bau und Reaktorsicherheit (BMUB)',
          c6: 'Ein Leitfaden für Unternehmen und politische Entscheidungsträger zum Thema Lebenszyklusdenken und -bewertung von der EU-Kommission für Umwelt',
          c7: 'Wie man einen nachhaltigkeitsbewussten Lieferanten auswählt',
          c8: 'Wie man umweltbewusste Lieferanten auswählt',
        },
      },
      pollutionAndEnvironmentalHarm: {
        leversComments: {
          c1: 'Wie man eine Ökobilanz für seine Produkte und Dienstleistungen erstellt',
          c2: 'Maßnahmen, die Unternehmen zum Ausgleich von Kohlenstoffemissionen ergreifen können.',
          c3: 'Ein Leitfaden für Unternehmen und politische Entscheidungsträger zum Thema Lebenszyklusdenken und -bewertung von der EU-Kommission für Umwelt',
          c4: 'Wie Unternehmen die biologische Vielfalt verbessern können',
          c5: 'Hier sind 5 Möglichkeiten für Unternehmen, die Luft zu reinigen',
          c6: 'Wie Unternehmen ihr Umweltbewusstsein verbessern können',
          c7: 'Wie Unternehmen umweltbewusster werden können',
          c8: 'Wie Sie Ihr Unternehmen umweltfreundlicher machen, indem Sie Umweltverschmutzung vermeiden',
          c9: '5 einfache Möglichkeiten, wie Hersteller die Luftverschmutzung reduzieren können',
          c10: '7 Empfehlungen für die Einbeziehung indigener Perspektiven in Überlegungen zur biologischen Vielfalt.',
          c11: 'Tipps und Empfehlungen für Unternehmen bei der Auswahl umweltbewusster Lieferanten',
        },
      },
      labor: {
        leversComments: {
          c1: 'Die besten OECD-Praktiken im Bereich Beschäftigung.',
          c2: 'UN-Leitfaden für den Umgang mit den Menschenrechten und deren Einhaltung durch Unternehmen.',
          c3: 'Hebt die wichtigsten internationalen Lohnabkommen hervor.',
          c4: 'Leitlinien der Internationalen Arbeitsorganisation zu Mindestlohn, existenzsicherndem Lohn und gerechtem Lohn.',
        },
      },

      workplace: {
        leversComments: {
          c1: 'Leitfaden für die Umsetzung einer Strategie für die Telearbeit.',
          c2: 'Ein interessantes und grundlegendes Instrument, das Unternehmen helfen kann, den fairen Lohn in einem bestimmten Land zu ermitteln.',
          c3: 'Liste der besten Sozialleistungen für Arbeitnehmer.',
          c4: 'Ein Leitfaden für Sozialleistungen.',
          c5: 'Interessanter Leitfaden zur Erstellung von Richtlinien für das Home Office mit Tools, Vorlagen und Mustern.',
          c6: 'Wie können psychische Probleme am Arbeitsplatz angegangen werden?',
          c7: 'Gerechte Entlohnung muss für den spezifischen Kontext eines Unternehmens bestimmt werden (d.h. Geografie, Sektor, Art der Arbeitsplätze, Branchenbenchmarks)',
          c8: 'Die Messung des Mitarbeiterengagements ist von entscheidender Bedeutung, um über die Bedürfnisse Ihrer Mitarbeiter auf dem Laufenden zu bleiben, und dieser Blog gibt einen Einblick in dieses Thema',
          c9: 'Hebt die wichtigsten internationalen Lohnabkommen hervor.',
          c10: 'Leitlinien der Internationalen Arbeitsorganisation zu Mindestlohn, existenzsicherndem Lohn und gerechtem Lohn.',
        },
      },
      diversityInclusion: {
        leversComments: {
          c1: 'Wie Sie Ihre Politik der Vielfalt und Integration um das Thema Alter erweitern können',
          c2: 'Ein genauerer Blick auf die Diversity-Gewinner zeigt, was echte Fortschritte bei den Diversity-Aspekten in Ihrem Unternehmen bewirken kann.',
          c3: 'Mit einer fundierten Diversitäts- und Eingliederungsstrategie kann Ihr Unternehmen Spitzenkräfte gewinnen und innovative Ergebnisse erzielen.',
          c4: 'Die wichtigsten Voraussetzungen für eine wirklich vielfältige und integrative Kultur in Ihrem Unternehmen',
          c5: 'Wie man einen behindertengerechten Arbeitsplatz schafft',
          c6: 'Wie man Vorurteile ausräumt, faire Bezahlung anwendet und sich auf die Ergebnisse und die Schaffung eines integrativen Einstellungsverfahrens konzentriert, um die Gleichstellung der Geschlechter am Arbeitsplatz zu fördern.',
        },
      },
      societalHarm: {
        leversComments: {
          c1: 'Strategien zum Umgang mit "Fake News" als Unternehmen.',
          c2: 'Die Vorteile einer personalisierten Preisgestaltung verstehen und das Wohlbefinden der Verbraucher für Ihr Unternehmen steigern.',
          c3: 'Wie Sie Ihren Markt in einzelne Kundengruppen aufteilen.',
          c4: 'Ein Leitfaden, der erklärt, warum Qualität für den Unternehmenserfolg wichtig ist.',
          c5: 'Das Verständnis dafür, wie Produktqualität den Unternehmen hilft, Kunden zu binden, ihre Marke zu etablieren und ihre Kosten zu verwalten.',
          c6: 'Kluge Strategien, wie Sie Ihre Produkte preislich so gestalten, dass sie Kunden anziehen.',
          c7: 'Ein Leitfaden über den Preis des fairen Handels und die Vorteile seiner Anwendung.',
          c8: 'Leitfaden für eine faire Preisgestaltung für Ihr Produkt.',
          c9: 'Wie und wann ein Verbrauchervertrag zustande kommt und wie Sie sicher sein können, dass Ihre Transaktionen legal und fair sind',
          c10: 'Wie ethisches Verkaufen die Zufriedenheit von Kunden und Teams erhöhen und zum Wachstum eines Unternehmens beitragen kann.',
        },
      },
      socialResponsibility: {
        leversComments: {
          c1: 'Gründe, warum Corporate Social Advocacy gut für Ihre Marke ist',
          c2: 'Vier einfache Möglichkeiten zur Unterstützung lokaler Gemeinschaften.',
        },
      },
      data: {
        leversComments: {
          c1: 'Tieferes Verständnis des Datenschutzes und der regulatorischen Praktiken',
          c2: 'Durch Blogs wie diesen, die die Informationen vereinfachen, werden technische Dinge wie die Datenerfassung auf freiwilliger Basis leichter verständlich',
          c3: 'Verständnis der GDPR und ihrer Richtlinien zur Gewährleistung einer fairen und rechtmäßigen Nutzung von Daten',
          c4: 'Welche Grundsätze werden für die faire Nutzung und Erfassung von Daten angewandt?',
          c5: 'Ein Leitfaden für den eingeschränkten Zugang zu Daten',
          c6: 'Blog zum Thema Datenschutz und Zugangsbeschränkung',
          c7: 'Hilfreich für das Verständnis von Datensicherheit, Datenschutz und Privatsphäre für Unternehmen/Organisationen',
          c8: 'Vertieftes Verständnis der Datenschutz-Grundverordnung, die für alle Organisationen, insbesondere in der EU, unerlässlich ist',
        },
      },
      stakeholderEngagement: {
        leversComments: {
          c1: 'Die Messung des Mitarbeiterengagements ist von entscheidender Bedeutung, um über die Bedürfnisse Ihrer Mitarbeiter auf dem Laufenden zu bleiben, und dieser Blog gibt einen Einblick in dieses Thema',
          c2: 'Vermittelt ein umfassendes Verständnis dafür, wie ein effektives gesellschaftliches Engagement aussehen kann, was es mit sich bringt und warum die Einbindung von Stakeholdern so wichtig ist',
          c3: 'Hilfreich für ein umfassendes Verständnis dessen, was Stakeholder Management',
          c4: 'Es ist wichtig zu verstehen, wie die Beziehung zu Kunden und Nutzern gestärkt werden kann und welche Instrumente eingesetzt werden können.',
        },
      },
      businessEthics: {
        leversComments: {
          c1: 'Wie ethisches Verkaufen die Zufriedenheit von Kunden und Teams steigern und zum Wachstum eines Unternehmens beitragen kann.',
          c2: 'Das Steuerverwaltungssystem verstehen und ein Leitfaden für den Umgang damit',
          c3: 'Ein ausführliches Papier darüber, was zum Schutz von Hinweisgebern getan werden kann und welche Rechte und Leistungen ihnen gewährt werden können',
          c4: 'Blog über das Verständnis von sexueller Belästigung, wie sie vermieden werden kann und welche Mechanismen Unternehmen einrichten können',
          c5: 'Blog über Möglichkeiten zur Verhinderung von Belästigung am Arbeitsplatz',
          c6: 'Blog zum Schutz von Whistleblowern vor Vergeltungsmaßnahmen am Arbeitsplatz',
          c7: 'Ein Blog zur Erläuterung verschiedener Möglichkeiten zum Schutz geistigen Eigentums',
          c8: 'Wege zum Schutz des geistigen Eigentums',
          c9: 'Korruptionsbekämpfungsmaßnahmen und -politiken, die Unternehmen in ihre Strategien und Arbeitsabläufe einbinden können',
          c10: '10 Wege zur Verringerung des Risikos von Korruption und Bestechung in Unternehmen',
        },
      },
      managementAdvisoryBoard: {
        leversComments: {
          c1: 'Tipps zur Verbesserung der Geschlechtervielfalt in einer Organisation',
          c2: '5 Schlüsselprinzipien, die die Grundlage von Strategien zur Geschlechtervielfalt bilden sollten',
          c3: 'Wie Sie die kulturelle Vielfalt in Ihrer Organisation sicherstellen',
          c4: 'Ein Artikel darüber, warum Altersvielfalt wichtig ist und wie sie gewährleistet werden kann',
          c5: 'Ein Bericht über das Management der Altersvielfalt und wie Organisationen dabei vorgehen können',
          c6: 'Neun Aktionsschritte, die die Forscher den Arbeitgebern vorschlagen, um die Altersvielfalt und Integration am Arbeitsplatz zu stärken',
          c7: 'Strategien, die Organisationen helfen, kulturelle und ethnische Vielfalt am Arbeitsplatz zu gewährleisten',
          c8: 'Wege zur Gewährleistung und Verbesserung der Geschlechtervielfalt am Arbeitsplatz',
          c9: 'Fünf Strategien zur Förderung der Vielfalt am Arbeitsplatz',
        },
      },
      gc: {
        leversComments: {
          c1: 'Warum ESG für Organisationen wichtig ist',
          c2: 'Ein Leitfaden zur Integration von ESG in Unternehmen',
          c3: 'Ein Leitfaden zur Ermittlung von Vorschriften und Gesetzen, die eingehalten werden müssen',
          c4: 'Einhaltung von Vorschriften am Arbeitsplatz: 9 Tipps',
          c5: '(Da es sich um eine Unternehmensseite handelt, die ihre eigenen Dienste propagiert, war ich mir nicht sicher, ob sie als Konkurrenz betrachtet werden könnten, aber es ist trotzdem hilfreich, die vorgeschlagenen Schritte zu sehen)',
        },
      },
      gris: {
        gri_102: 'Allgemeine Angaben',
        gri_201: 'Wirtschaftliche Leistung',
        gri_205: 'Anti-Korruption',
        gri_301: 'Werkstoffe',
        gri_302: 'Energie',
        gri_305: 'Emissionen',
        gri_306: 'Abfall',
        gri_403: 'Förderung der Gesundheit der Arbeitnehmer',
        gri_405: 'Vielfalt und Chancengleichheit',
        gri_409: 'Zwangs- oder Pflichtarbeit',
        gri_418: 'Datenschutz für Kunden',
        // gri_409: 'Gesundheit und Sicherheit am Arbeitsplatz',
      },
      sdgs: {
        sdg_3: 'Gute Gesundheit und Wohlbefinden',
        sdg_5: 'Gleichberechtigung der Geschlechter',
        sdg_7: 'Erschwingliche und saubere Energie',
        sdg_8: 'Menschenwürdige Arbeit und Wirtschaftswachstum',
        sdg_9: 'Industrie, Innovation und Infrastruktur',
        sdg_12: 'Verantwortungsbewusster Konsum und Produktion',
        sdg_13: 'Klimapolitik',
        sdg_14: 'Leben unter Wasser',
        sdg_15: 'Leben an Land',
        sdg_16: 'Friedensgerechtigkeit und starke Institutionen',
        sdg_17: 'Partnerschaften für die Ziele',
      },
      kpis: {
        ghgEmissions: {
          kpi_1:
            'Anzahl der Treibhausgasemissionen (idealerweise aufgeteilt in die THG Protocol Scopes 1, 2 und 3)',
        },
        energy: {
          kpi_1: 'Gesamtenergieverbrauch pro Jahr',
          kpi_2:
            'Menge/Prozentsatz der erzeugten/genutzten erneuerbaren Energie',
        },
        transportation: {
          kpi_1: 'Anzahl der Fahrten nach Verkehrsträger',
          kpi_2:
            'Menge/Prozentsatz der erzeugten/genutzten erneuerbaren EnergieGesamtzahl der zurückgelegten Kilometer/Gütertransport für jeden Verkehrsträger',
        },
        resourceConsumptionAndUsage: {
          kpi_1: 'Menge der erzeugten Abfälle',
          kpi_2: 'Menge der recycelten Abfälle',
          kpi_3: 'Menge der beschafften wiederverwendeten Materialien',
          kpi_4: 'Menge der beschafften aufgearbeiteten/recycelten Produkte',
        },
        pollutionAndEnvironmentalHarm: {
          kpi_1:
            'Menge der verursachten Umweltverschmutzung für jede Art von Schadstoff',
        },
        labor: {
          kpi_1: 'Prozentsatz der überprüften Lieferanten',
          kpi_2:
            'Anzahl der eingereichten, behandelten und behobenen Beschwerden',
        },
        workplace: {
          kpi_1:
            'Anzahl der flexiblen Arbeitsregelungen, aufgeschlüsselt nach Arten von Regelungen',
          kpi_2:
            'Gesamtwert der Sozial- und Gesundheitsleistungen pro Mitarbeiter',
          kpi_3: 'Anzahl der Arbeitnehmer, die den Mindestlohn erhalten',
          kpi_4:
            'Zahl der Beschäftigten, die einen existenzsichernden Lohn erhalten',
          kpi_5:
            'Lohnunterschied zwischen verschiedenen Beschäftigten eines Unternehmens',
        },
        diversityInclusion: {
          kpi_1: 'Anteil weiblicher/behinderter/ethnischer Mitarbeiter',
          kpi_2: 'Verhältnis von weiblichen zu männlichen Beschäftigten',
        },
        societalHarm: {
          kpi_1: 'Net Promoter Score',
          kpi_2: 'Soziale Wertschöpfung',
        },
        socialResponsibility: {
          kpi_1:
            'Durchschnittlicher Zeitaufwand der Mitarbeiter für Initiativen zur sozialen Verantwortung',
          kpi_2:
            'Anzahl der Initiativen zur sozialen Verantwortung, aufgeschlüsselt nach Art der Initiative',
        },
        data: {
          kpi_1: 'Anzahl der festgestellten Datenverluste/-lecks',
          kpi_2: 'Anzahl der identifizierten und behobenen Bedrohungen',
        },
        stakeholderEngagement: {
          kpi_1: 'Anzahl der pro Jahr organisierten Stakeholder-Treffen',
          kpi_2:
            'Anzahl der erhaltenen Rückmeldungen von Stakeholdern, aufgeschlüsselt nach Art der Stakeholder',
        },
        businessEthics: {
          kpi_1:
            'Anteil der geschulten Mitarbeiter, aufgeschlüsselt nach relevanten ethischen Bereichen im Verhaltenskodex wie Nichtdiskriminierung, Bestechungsbekämpfung oder Whistleblowing',
        },
        managementAdvisoryBoard: {
          kpi_1:
            'Anteil der Mitglieder des Managementteams oder des Beirats nach Geschlecht, kulturellem oder ethnischem Hintergrund, Alter, Behinderung oder Fachwissen',
          kpi_2: 'Anzahl der pro Jahr abgehaltenen Vorstandssitzungen',
        },
        regulationsStandards: {
          kpi_1: 'Anzahl der Rechtsstreitigkeiten oder Klagen',
          kpi_2:
            'Art und Anzahl der erreichten Qualitäts- und Industriestandards',
          kpi_3:
            'Art und Anzahl der erhaltenen Nachhaltigkeitszertifizierungen',
        },
        ghgEmissions_v2: {
          kpi_1:
            'Anzahl der Treibhausgasemissionen (idealerweise aufgeteilt in die THG Protocol Scopes 1, 2 und 3)',
        },
        energy_v2: {
          kpi_1: 'Gesamtenergieverbrauch pro Jahr',
          kpi_2:
            'Menge/Prozentsatz der erzeugten/genutzten erneuerbaren Energie',
        },
        transportation_v2: {
          kpi_1: 'Anzahl der Fahrten nach Verkehrsträger',
          kpi_2:
            'Menge/Prozentsatz der erzeugten/genutzten erneuerbaren EnergieGesamtzahl der zurückgelegten Kilometer/Gütertransport für jeden Verkehrsträger',
        },
        resourceConsumptionAndUsage_v2: {
          kpi_1: 'Menge der erzeugten Abfälle',
          kpi_2: 'Menge der recycelten Abfälle',
          kpi_3: 'Menge der beschafften wiederverwendeten Materialien',
          kpi_4: 'Menge der beschafften aufgearbeiteten/recycelten Produkte',
        },
        pollutionAndEnvironmentalHarm_v2: {
          kpi_1:
            'Menge der verursachten Umweltverschmutzung für jede Art von Schadstoff',
        },
        labor_v2: {
          kpi_1: 'Prozentsatz der überprüften Lieferanten',
          kpi_2:
            'Anzahl der eingereichten, behandelten und behobenen Beschwerden',
        },
        workplace_v2: {
          kpi_1:
            'Anzahl der flexiblen Arbeitsregelungen, aufgeschlüsselt nach Arten von Regelungen',
          kpi_2:
            'Gesamtwert der Sozial- und Gesundheitsleistungen pro Mitarbeiter',
          kpi_3: 'Anzahl der Arbeitnehmer, die den Mindestlohn erhalten',
          kpi_4:
            'Zahl der Beschäftigten, die einen existenzsichernden Lohn erhalten',
          kpi_5:
            'Lohnunterschied zwischen verschiedenen Beschäftigten eines Unternehmens',
        },
        diversityInclusion_v2: {
          kpi_1: 'Anteil weiblicher/behinderter/ethnischer Mitarbeiter',
          kpi_2: 'Verhältnis von weiblichen zu männlichen Beschäftigten',
        },
        societalHarm_v2: {
          kpi_1: 'Net Promoter Score',
          kpi_2: 'Soziale Wertschöpfung',
        },
        socialResponsibility_v2: {
          kpi_1:
            'Durchschnittlicher Zeitaufwand der Mitarbeiter für Initiativen zur sozialen Verantwortung',
          kpi_2:
            'Anzahl der Initiativen zur sozialen Verantwortung, aufgeschlüsselt nach Art der Initiative',
        },
        data_v2: {
          kpi_1: 'Anzahl der festgestellten Datenverluste/-lecks',
          kpi_2: 'Anzahl der identifizierten und behobenen Bedrohungen',
        },
        stakeholderEngagement_v2: {
          kpi_1: 'Anzahl der pro Jahr organisierten Stakeholder-Treffen',
          kpi_2:
            'Anzahl der erhaltenen Rückmeldungen von Stakeholdern, aufgeschlüsselt nach Art der Stakeholder',
        },
        businessEthics_v2: {
          kpi_1:
            'Anteil der geschulten Mitarbeiter, aufgeschlüsselt nach relevanten ethischen Bereichen im Verhaltenskodex wie Nichtdiskriminierung, Bestechungsbekämpfung oder Whistleblowing',
        },
        managementAdvisoryBoard_v2: {
          kpi_1:
            'Anteil der Mitglieder des Managementteams oder des Beirats nach Geschlecht, kulturellem oder ethnischem Hintergrund, Alter, Behinderung oder Fachwissen',
          kpi_2: 'Anzahl der pro Jahr abgehaltenen Vorstandssitzungen',
        },
        regulationsStandards_v2: {
          kpi_1: 'Anzahl der Rechtsstreitigkeiten oder Klagen',
          kpi_2:
            'Art und Anzahl der erreichten Qualitäts- und Industriestandards',
          kpi_3:
            'Art und Anzahl der erhaltenen Nachhaltigkeitszertifizierungen',
        },
      },
    },
    comparison: {
      current: 'Aktuell',
      previous: 'Vorgänger',
      latestPerformanceComparedToPrevious:
        'Aktuelle Performance im Vergleich mit der Vorgängerversion',
      latestPerformanceComparedToIxBenchmark:
        'Aktuelle Performance im Vergleich mit dem ImpactNexus Benchmark',
      latestPerformanceComparedToBenchmarks:
        'Aktuelle Performance im Vergleich mit Benchmarks',
      differenceToPrevious: 'Abweichung zur Vorgängerversion',
      portfolioAverage: 'Abweichung zum Portfolio-Durchschnitt',
      ixAverage: 'Abweichung zum ImpactNexus-ESG-Benchmark',
      comparisonToPreviousVersion: 'Vergleich zur Vorgängerversion',
      itIsNotPossible:
        'Es ist nicht möglich, den ausgewählten Fragebogen mit der Vorgängerversion zu vergleichen. Prüfen Sie, ob eine Vorgängerversion existiert und ob beide Fragebögen eingereicht wurden.',
      commentsTitle: 'Kommentare zum PDF Export',
      commentsText:
        'Möchten Sie kommentieren, was zu den Veränderungen in den ESG-Bewertungen beigetragen hat? Dies wird im PDF-Export angezeigt.',
      commentsEnvironmental: 'Kommentar zur Dimension Umwelt',
      commentsSocial: 'Kommentar zur Dimension Soziales',
      commentsGovernmental: 'Kommentar zur Dimension Unternehmensführung',
      errorMessage:
        'Ein Fehler ist aufgetreten. Wenn der Fehler nach einem Refresh weiter besteht, klicken Sie bitte das Feedback-Symbol oben rechts, um einen Fehler zu melden.',
    },
  },
  companyPerspective: {
    registrationPage: {
      impactIntelligence: 'Intelligentes Nachhaltigkeitsmanagement',
      registration: 'Registration',
      portfolioOwnerHasRequestedYou:
        '<b>{company}</b> hat Sie aufgefordert, sich bei ImpactNexus zu registrieren',
      selfRegister:
        '<b>{company}</b> - bitte hier registrieren, um folgende Tools nutzen zu können:',
      teamHasInvitedYou:
        '<b>{inviter}</b> hat Sie eingeladen, dem Team beizutreten',
      youAreRegisteredFor: 'Sie sind registriert für die',
      esgModule: 'ESG-Modul',
      theToken: 'Das Token',
      theImpactToken: 'Der Impact Token',
      esgDidNotMatch:
        'für die Registrierung zum ESG-Modul nicht übereinstimmen. Bitte kontaktieren Sie Ihren Portfoliobesitzer oder den ImpactNexus Support.',
      impactGoalModule: 'Modul Wirkungsziel',
      impactGoalDidNotMatch:
        'für die Registrierung zum Impact-Zielmodul nicht übereinstimmen. Bitte kontaktieren Sie Ihren Portfoliobesitzer oder den ImpactNexus Support.',
      metricsModuleDidNotMatch:
        'für die Registrierung im Metrics-Modul nicht übereinstimmen. Bitte kontaktieren Sie Ihren Portfoliobesitzer oder den ImpactNexus Support.',
      impactModuleDidNotMatch:
        'Die Registrierung für das Impact-Modul stimmte nicht überein. Bitte wenden Sie sich an Ihren Portfoliobesitzer oder an den ImpactNexus-Support.',
      kpiModule: 'KPI-Modul',
      impactModule: 'Impact-Modul',
      firstName: 'Vorname',
      enterFirstName: 'Vornamen eingeben',
      firstNameIsRequired: 'Vorname ist erforderlich',
      firstNameMustBeLessThan: 'Vorname muss weniger als 25 Zeichen lang sein',
      lastName: 'Nachname',
      enterLastName: 'Nachnamen eingeben',
      lastNameIsRequired: 'Nachname ist erforderlich',
      lastNameMustBeLessThan: 'Nachname muss weniger als 25 Zeichen lang sein',
      email: 'E-Mail',
      enterEmail: 'E-Mail eingeben',
      emailIsRequired: 'E-Mail ist erforderlich',
      emailMustBeValid: 'E-Mail muss gültig sein',
      password: 'Kennwort',
      enterPassword: 'Passwort eingeben',
      passwordIsRequired: 'Passwort ist erforderlich',
      passwordMustContain:
        'Das Passwort muss mindestens einen Kleinbuchstaben, eine Zahl und einen Großbuchstaben enthalten',
      confirmPassword: 'Passwort bestätigen',
      confirmPasswordPlaceholder: 'Kennwort bestätigen',
      confirmNewPassword: 'Neues Kennwort bestätigen',
      theTwoPasswordsDoNotMatch: 'Die beiden Passwörter stimmen nicht überein',
      companyName: 'Firmenname',
      enterCompanyName: 'Geben Sie den Firmennamen ein',
      companyNameIsRequired: 'Firmenname ist erforderlich',
      companyNameMustBeLessThan:
        'Firmenname muss weniger als 25 Zeichen lang sein',
      companyUrl: 'Unternehmens-URL',
      enterCompanyUrl: 'Firmen-URL eingeben',
      urlIsNotValid: 'URL ist nicht gültig',
      companyLogo: 'Firmenlogo',
      selectCompanyImages: 'Firmenlogo zum Hochladen auswählen',
      uploadedSuccessfully: 'Erfolgreich hochgeladen',
      failedToUpload: 'Hochladen fehlgeschlagen',
      agreeTermsAndConditions: 'Bedingungen zustimmen',
      iAcceptSharingData:
        ' Ich akzeptiere die gemeinsame Nutzung von Daten mit <b>{company}</b>',
      byAccepting:
        'Indem Sie zustimmen, erklären Sie sich damit einverstanden, dass {company} Zugriff auf die Bewertungsdaten Ihres Vorhabens erhält',
      register: 'Sign up',
      youDidNotPass:
        "Sie haben in der URL nicht die notwendigen Token für eine gültige Registrierung übergeben. Bitte überprüfen Sie Ihren Registrierungslink erneut oder oder <a href='mailto:help@impactnexus.io'>E-Mail an uns</a>.",
      checkingValidityOfUrls: 'Überprüfung der Gültigkeit von URL-Tokens...',
      thankYouForRegisetring:
        'Vielen Dank für Ihre Registrierung bei ImpactNexus. Wir haben Ihnen eine E-Mail zur Aktivierung Ihres Kontos gesendet.',
      itMightTakeFewMinutes:
        'In manchen Fällen kann es ein paar Minuten dauern, bis Sie auf Ihr Konto zugreifen können.',
      backMainPage: 'Anmelden',
      agreeToContinue: 'Zustimmung zur Fortsetzung',
      filesCount: '{n} Dateien',
    },
    homePage: {
      noVentures: 'Ihrem Konto sind keine Unternehmen zugewiesen',
      noCompaniesInPortfolio:
        "Vielen Dank, dass Sie sich bei ImpactNexus registriert haben. Es scheint, dass Sie keine Unternehmen in Ihrem Portfolio haben. Sie können sie einladen, indem Sie auf die Schaltfläche 'Portfoliounternehmen einladen' in der linken Seitenleiste klicken.",
    },
    kpis: {
      tooltipEditNotAllowed: 'Sie können diesen KPI nicht bearbeiten',
      indicatorSets: 'Indikatorensätze',
      searchForKpis: 'Suche nach KPIs',
      addSelectedApis: 'Ausgewählte KPIs hinzufügen',
      noResults: 'Keine Ergebnisse, ändern Sie den Suchbegriff oder die Filter',
      enableIndicator:
        'Aktivieren Sie die Indikatorsätze auf der linken Seite oder geben Sie oben einen anderen Suchbegriff ein',
      nameColumn: 'Name',
      unitColumn: 'Einheit',
      typeColumn: 'Art',
      taxonomyColumn: 'Taxonomie',
      assignedToColumn: 'AssignTo',
      editColumn: 'Bearbeiten',
      editKpis: 'Bearbeiten',
      addKpis: 'Hinzufügen',
      updateKpis: 'Update',
      kpisToYourAccount: 'Zuweisung der ausgewählten KPIs',
      kpisToTrack: 'Zu verfolgende KPIs',
      partsOfThisFormCannotBeEdited:
        'Teile dieses Formulars können nicht bearbeitet werden, da die KPI-Vorlage nicht von Ihrem Konto erstellt wurde (z. B. wenn sie zur ImpactNexus KPI-Datenbank gehört). Sie können Ihre kontospezifischen Teile dieses Formulars, wie z.B. Kommentare, trotzdem bearbeiten.',
      goal: 'Ziel',
      goalNone: 'Kein',
      goalReduce: 'Reduzieren',
      goalIncrease: 'Erhöhen',
      goalApproximate: 'Annähern',
      addPotentialCategories: 'Mögliche Kategorien für diese Metrik hinzufügen',
      createCategoryInstruction:
        'Drücken Sie <kbd>enter</kbd>, um die Kategorie zu erstellen.',
      comment: 'Kommentar',
      searchPredefinedKpis: 'Suche nach vordefinierten KPIs',
      defineCustomKpi: 'Benutzerdefinierte KPI definieren',
      overviewOfKpis: 'Überblick über die KPIs',
      headers: {
        kpi: 'KPI',
        relatedFrameworks: 'Verwandte Frameworks',
        goal: 'Ziel',
        fund: 'Fond',
        unit: 'Einheit',
        targetProgress: 'Geplante Fortschritte',
        impact: 'Wirkung',
        values: 'Werte',
        actions: 'Aktionen',
        comments: 'Kommentare',
        group: 'Gruppen',
        company: 'Unternehmen',
      },
      thereWasAProblem:
        'Es gab ein Problem und die letzte Aktion wurde abgebrochen',
      customKpis: 'Benutzerdefinierte KPIs',
      yourTarget: 'Ihr Ziel',
      actual: 'Ist-Wert',
      target: 'Soll-Wert',
      recordActualKpi:
        'Erfassen Sie die aktuellen KPI-Werte, indem Sie auf die Zahlen oder auf die ⋯-Symbole in der Tabelle klicken.',
      recordTargetKpi:
        'Zeichnen Sie die Ziel-KPI-Werte auf, indem Sie auf die Zahlen oder die ⋯-Symbole in der Tabelle klicken.',
      editKpi: 'KPI bearbeiten',
      deleteKpi: 'KPI löschen',
      addComment: 'Kommentar hinzufügen',
      deletingKpi: 'KPI-Löschung',
      areYouSureDeleteKpi: 'Sind Sie sicher, dass Sie die KPI löschen wollen',
      tour: {
        welcome: 'Willkommen im KPI-Modul!',
        quickTour: 'KPI-Modul-Schnelltour',
        theFollowingVideo:
          'Das folgende Video gibt Ihnen einen Überblick über alle wichtigen Funktionen des Key Performance Indicator (KPI) Moduls der ImpactNexus Softwarelösung.',
        howToReport: 'Wie berichtet man über PAI-Indikatoren?',
        theFollowingVideo2:
          'Das folgende Video gibt Ihnen einen Überblick über alle wichtigen Funktionen des Indikatorenmoduls Principle Adverse Impact (PAI) der ImpactNexus Softwarelösung. Die PAI-Indikatoren sind ein entscheidendes Element der Berichterstattung nach der Sustainable Finance Disclosure Regulation (SFDR).',
        paiIndicators: `### Wie beginnt man mit den PAI-Indikatoren?

Kurz und bündig:
Risikokapital- und Private-Equity-Fonds, die gemäß SFDR als Artikel 8+ oder Artikel 9 eingestuft sind, müssen über 14 obligatorische PAI-Indikatoren und 2 zusätzliche PAI-Indikatoren berichten.

Öffnen Sie im Modul "KPIs" der ImpactNexus-Software den Abschnitt "SFDR", und Sie werden alle Daten sehen, die für die Berichterstattung über die PAI erforderlich sind. Weitere Informationen zu jedem PAI erhalten Sie, wenn Sie auf das graue Info-Kreis-Symbol neben dem Namen des PAI klicken. In dem Popup finden Sie die Abschnitte "Beschreibung" und "Verwendungshinweise", die Ihnen weitere Informationen über die PAI liefern.

Die Dateneingabe erfolgt in der Spalte für den jeweiligen Zeitraum. Mit einem Klick auf die 3 Punkte öffnet sich ein Popup mit weiteren Eingabemöglichkeiten. Kommentare können für jeden PAI im Allgemeinen oder für den spezifischen Datenpunkt im Popup an den Fondsmanager zurückgegeben werden.

Für Rückmeldungen an ImpactNexus verwenden Sie bitte die Schaltfläche oben rechts.`,
        kpiModuleInfoVentureQuickTour: `### Wie beginnt man mit dem KPI-Modul?

Kurz und bündig:

Das Modul gibt einen Überblick über alle KPIs, die Ihnen von Ihrem Fondsmanager zugewiesen wurden, sowie über die von Ihnen selbst gewählten KPIs.

Die KPIs sind nach verschiedenen Bereichen sortiert, z.B. "Custom-KPIs", "IRIS + IMP Taxonomy" oder "SFDR". Durch Anklicken der Abschnitte können Sie die Liste der Kennzahlen auf Unternehmensebene ausrollen, für die Sie Daten bereitstellen sollen.

Sie können alle Informationen zu einer Kennzahl einsehen, indem Sie auf das graue Info-Kreis-Symbol neben dem Namen der Kennzahl klicken. Zusätzlich zu den allgemeinen Informationen über den KPI können Sie das Diagramm sehen, das die Leistung der gewählten Kennzahl über den Berichtszeitraum darstellt. Sie können benutzerdefinierte KPIs bearbeiten, nicht aber zugewiesene KPIs (z. B. die Beschreibung oder die Einheit).

Für jeden KPI sehen Sie die Einheit, den Zielfortschritt und die Werte pro Jahr. Sie können die Zielwerte für die benutzerdefinierten KPIs selbst wählen. Sie können sie jedoch nicht für die KPIs ändern, die Ihnen vom Portfoliomanager zugewiesen wurden.

Das KPI-Modul ermöglicht auch das *Hinzufügen neuer KPIs*. Um eine Anleitung dazu zu erhalten, fahren Sie bitte mit dem Mauszeiger über die entsprechenden Schaltflächen: "Benutzerdefinierte KPIs definieren" und "Vordefinierte KPIs suchen".

- **Vordefinierte KPIs suchen:** In diesem Abschnitt können Sie die KPIs aus den vorgegebenen "Indikatorensätzen" auswählen. Dabei handelt es sich um IRIS-Indikatoren, die sich auf das Impact Reporting beziehen, und PAI-Unternehmensdaten, die sich auf das SFDR Reporting beziehen. Normalerweise weisen die Fondsmanager den Portfoliounternehmen KPIs aus diesen Sets zu. Es ist jedoch auch möglich, dass die Unternehmen zusätzliche KPIs auswählen, die sie verfolgen möchten.
- **Benutzerdefinierte KPIs definieren:** Wenn es einen benutzerdefinierten KPI gibt, den Sie in Ihrem Unternehmen verfolgen möchten, klicken Sie auf diese Schaltfläche und geben Sie einen Namen und eine Einheit für den KPI an. Außerdem können Sie eine Beschreibung verfassen, ein Ziel festlegen und Kommentare hinterlassen.`,
      },
    },
    tasks: {
      defineTask: 'Aufgabe definieren',
      headers: {
        venture_name: 'Unternehmen',
        task_goal: 'Aufgabe',
        task_label: 'Aufgabe',
        task: 'Aufgabe',
        goalWhy: 'Ziel (Warum)',
        origin: 'Herkunft',
        startDate: 'Startdatum',
        dueDate: 'Fälligkeitsdatum',
        status: 'Status',
        comments: 'Kommentare',
        updatedAt: 'Aktualisiert am',
        actions: 'Aktionen',
        endDate: 'Enddatum',
      },
      typeToFilterRows: 'Tippen, um Zeile zu Filtern',
      taskOverview: 'Aufgabenübersicht',
      add: 'Hinzufügen',
      edit: 'Bearbeiten',
      task: 'Aufgabe',
      capitalTask: 'Aufgabe',
      whatAreYouPlanning: 'Was planen Sie zu tun?',
      whatIsThePurpose:
        'Was ist der Zweck dieser Aufgabe, was wollen Sie erreichen?',
      partsOfThisFormCanNotBeEdited:
        'Teile dieses Formulars können nicht bearbeitet werden, da die Aufgabenvorlage nicht von Ihrem Konto erstellt wurde. Sie können Ihre kontospezifischen Teile dieses Formulars dennoch bearbeiten.',
      howWasThisTaskCreated: 'Wie wurde diese Aufgabe erstellt?',
      whatIsTheCurrentProgress: 'Wie ist der aktuelle Stand dieser Aufgabe?',
      update: 'Update',
      editTask: 'Aufgabe bearbeiten',
      deleteTask: 'Aufgabe löschen',
      areYouSureDelete: 'Sind Sie sicher, dass Sie die Aufgabe löschen wollen',
      tasksOverview:
        'Das folgende **Video** gibt Ihnen einen Überblick über alle wichtigen Funktionen des Aufgabenmoduls der ImpactNexus Softwarelösung.',
      tasksOverviewTitle: 'Willkommen im Aufgabenmodul!',
    },
  },
  feedback: {
    title: 'Rückmeldung',
    helpUsImprove: 'Helfen Sie uns, Ihr Erlebnis zu verbessern',
    text: 'Probleme?\n* Es tut uns leid, wenn etwas noch nicht funktioniert und wir freuen uns, es zu beheben! Lassen Sie uns wissen, was passiert ist und wir werden uns so schnell wie möglich bei Ihnen melden\n\nVerbesserungsvorschläge?\n* Wir freuen uns über Feedback! Bitte lassen Sie uns wissen, was Ihnen gefällt und wie wir uns verbessern können',
    yourFeedback: 'Ihr Feedback',
    submitFeedback: 'Einreichen',
    thanks: 'Thank you for your feedback!',
  },

  database: {
    question: {
      pitchLine: {
        label: 'Pitchline',
        sublabel:
          'Beschreiben Sie das Wertversprechen Ihres Unternehmens in einem Satz',
        tooltip: '',
      },
      legalEntity: {
        label: 'Juristische Person',
        sublabel:
          'Falls zutreffend: Welche Rechtsform haben Sie (z.B. GmbH, e.G., Ltd.)?',
        tooltip: '',
      },
      founders: {
        label: 'Gründer',
        sublabel:
          'Nennen Sie die Gründer Ihres Unternehmens (Vor- und Nachname). Bestätigen Sie jede Eingabe mit enter.',
        tooltip: '',
      },
      headquarter: {
        label: 'Hauptquartier',
        sublabel:
          'Bitte geben Sie die Stadt ein, in der sich Ihr Hauptsitz befindet.',
        tooltip: '',
      },
      country: {
        label: 'Land',
        sublabel:
          'Bitte geben Sie die Land ein, in der sich Ihr Hauptsitz befindet.',
        tooltip: '',
      },
      foundingYear: {
        label: 'Gründungsjahr',
        sublabel:
          'Falls zutreffend: WWann wurde Ihre Organisation als juristische Person gegründet?',
        tooltip: '',
      },
      employeeHeadcountPlannedForThisYear: {
        label: 'Mitarbeiterzahl (geplant für dieses Jahr)',
        sublabel:
          'Bitte geben Sie die Anzahl der fest angestellten Mitarbeiter an, ohne freie Mitarbeiter oder Praktikanten',
        tooltip: '',
      },
      turnoverPlannedForThisYear: {
        label: 'Umsatz (geplant für dieses Jahr)',
        sublabel: '',
        tooltip: '',
      },
      currentFundingStage: {
        label: 'Aktueller Stand der Finanzierung',
        sublabel: '',
        tooltip: '',
      },

      businessModel: {
        label: 'Geschäftsmodell',
        sublabel:
          'Bitte wählen Sie einen oder mehrere Geschäftsmodelltypen aus, die auf Sie zutreffen.',
        tooltip: '',
      },
      mainIncomeStream: {
        label: 'Haupteinkommensquelle',
        sublabel:
          'Bitte wählen Sie eine oder mehrere Einkommensquellen aus, die auf Sie zutreffen.',
        tooltip: '',
      },
      products_Services: {
        label: 'Produkte/Dienstleistungen',
        sublabel:
          'Welche Produkte oder Dienstleistungen bieten Sie an? Bestätigen Sie jeden Eintrag mit enter.',
        tooltip: '',
      },
      industries: {
        label: 'Industrien',
        sublabel:
          'Bitte wählen Sie eine oder mehrere Industrien aus, die auf Sie zutreffen.',
        tooltip: '',
      },
      subindustries: {
        label: 'Teilindustrien',
        sublabel: '',
        tooltip: '',
      },
      technologies: {
        label: 'Technologien',
        sublabel: '',
        tooltip: '',
      },
      customerGroup: {
        label: 'Kundengruppe',
        sublabel:
          'Wer wird für Ihre Produkte und Dienstleistungen bezahlen (bitte konkretisieren Sie, z. B. private Verbraucher, KMU oder Produktionsunternehmen)? Bestätigen Sie jeden Eintrag mit enter.',
        tooltip: '',
      },
      doYouMonitorGreenhouseGasGhgEmissionsGeneratedDirectlyOr: {
        label:
          'Überwachen Sie die Treibhausgasemissionen (THG), die direkt oder indirekt durch Ihr Unternehmen entstehen?',
        sublabel: 'z.B. die Berechnung von CO2-Fußabdrücken',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      thereAreGenerallyThreeTypesOfEmissionsScope12: {
        label:
          'Im Allgemeinen gibt es drei Arten von Emissionen (Scope 1, 2, 3). Welche davon überwachen Sie?',
        sublabel: '',
        tooltip: '',
      },
      scope1DirectGhgEmissions: {
        label: 'Bereich 1 (Direkte Treibhausgasemissionen)',
        sublabel:
          'Emissionen aus Quellen, die dem Unternehmen gehören oder von ihm kontrolliert werden (z. B. Emissionen aus der Beheizung von Unternehmenseinrichtungen oder Firmenfahrzeugen)',
        tooltip: '',
      },
      scope2ElectricityIndirectGhgEmission: {
        label: 'Bereich 2 (Indirekte THG-Emissionen durch Elektrizität)',
        sublabel:
          'Emissionen aus der Erzeugung von eingekauftem Strom, der vom Unternehmen verbraucht wird (z. B. Heizung und Kühlung für den Eigenverbrauch)',
        tooltip: '',
      },
      scope3OtherIndirectGhgEmissions: {
        label: 'Bereich 3 (Andere indirekte Treibhausgasemissionen)',
        sublabel:
          'Emissionen, die eine Folge der Aktivitäten des Unternehmens sind, aber aus Quellen stammen, die nicht dem Unternehmen gehören oder von ihm kontrolliert werden (z. B. Emissionen aus geleasten Anlagen, Pendelverkehr der Mitarbeiter, Geschäftsreisen, Franchiseunternehmen)',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouTakeMeasuresToMakeYourEnergyConsumptionMore: {
        label:
          'Ergreifen Sie Maßnahmen, um Ihren Energieverbrauch nachhaltiger zu gestalten?',
        sublabel:
          'z.B. Nutzung erneuerbarer Energien, Verbesserung der Energieeffizienz,...',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v2: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToSustainablyManageYourEnergy: {
        label:
          'Welche Maßnahmen ergreifen Sie, um Ihren Energieverbrauch nachhaltig zu steuern?',
        sublabel: '',
        tooltip: '',
      },
      monitorEnergyUsage: {
        label: 'Überwachung des Energieverbrauchs',
        sublabel: 'z.B. Verfolgung der verbrauchten kWh',
        tooltip: '',
      },
      procureRenewableEnergy: {
        label: 'Beschaffung erneuerbarer Energie',
        sublabel:
          'z. B. Wechsel des Energieversorgers, Installation von Sonnenkollektoren',
        tooltip: '',
      },
      reduceEnergyUsageUseEnergy_EfficientTechnologies: {
        label:
          'Senkung des Energieverbrauchs und Einsatz energieeffizienter Technologien',
        sublabel:
          'z.B. Verwendung von automatischen Abschaltvorrichtungen für elektrische Geräte, Verwendung von Geräten mit höherer Stromeinsparung wie LED-Leuchten',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v2: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      areYouCommittedToMakingSustainableTransportationChoices: {
        label: 'Setzen Sie sich für eine nachhaltige Mobilität ein?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v3: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseSustainableTransportationActionsDoYouFollowIn: {
        label:
          'Welche dieser nachhaltigen Verkehrsmaßnahmen verfolgen Sie in Ihrem Unternehmen?',
        sublabel: '',
        tooltip: '',
      },
      reducingBusinessRelatedTravel: {
        label: 'Verringerung der dienstlich bedingten Reisen',
        sublabel: 'durch Nutzung von Telekonferenzdiensten - z. B. Zoom, Skype',
        tooltip: '',
      },
      favourUsingEco_FriendlyModesOfTransportation: {
        label: 'Bevorzugen Sie die Nutzung umweltfreundlicher Verkehrsmittel',
        sublabel:
          'z.B. öffentliche Verkehrsmittel, Elektrofahrzeuge, Fahrrad, zu Fuß gehen, ...',
        tooltip: '',
      },
      compensatingForUnavoidableTransportationRelatedEmissions: {
        label: 'Ausgleich für unvermeidbare verkehrsbedingte Emissionen',
        sublabel: 'z.B. durch die Verwendung von Kohlenstoffkompensationen',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v3: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouTakeMeasuresToReduceTheResourceUsageIn: {
        label:
          'Ergreifen Sie Maßnahmen zur Verringerung des Ressourcenverbrauchs in Ihrem Unternehmen?',
        sublabel:
          'z. B. Verringerung der Abfallerzeugung oder des Wasserverbrauchs',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v4: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureSustainableResourceManagement: {
        label:
          'Welche Maßnahmen ergreifen Sie, um ein nachhaltiges Ressourcenmanagement in Ihrem Unternehmen zu gewährleisten?',
        sublabel: '',
        tooltip: '',
      },
      minimizeWasteGenerationAndMaximizeWasteRecyclingThroughEfficientProcesses:
        {
          label:
            'Minimierung des Abfallaufkommens und Maximierung des Abfallrecyclings durch effiziente Prozesse oder durch Design',
          sublabel:
            'z. B. effiziente Maschinen zur Vermeidung von Materialabfällen, zirkuläres oder Cradle2Cradle-Produktdesign zur Vermeidung von Kunststoffabfällen',
          tooltip: '',
        },
      minimizeWaterUseAndWaterConsumptionThroughWaterEfficiencyAnd: {
        label:
          'Minimierung der Wassernutzung und des Wasserverbrauchs durch Maßnahmen zur Wassereffizienz und -einsparung',
        sublabel:
          'z. B. durch effizientes Abwassermanagement, wassersparende Geräte',
        tooltip: '',
      },
      useOfRecycledMaterialsAnd_OrRefurbishedEquipment: {
        label:
          'Verwendung von recycelten Materialien und/oder aufgearbeiteten Geräten',
        sublabel:
          'z. B. recycelter Kunststoff/Metall oder aufbereitete IT-Geräte',
        tooltip: '',
      },
      procureAndUseSustainablyProducedProductsAndRawMaterials: {
        label:
          'Beschaffung und Verwendung von nachhaltig produzierten Produkten und Rohstoffen',
        sublabel:
          'z. B. Lebensmittel, Bürobedarf, Mineralien und/oder nachhaltige Ersatzstoffe wie alternative Kunststoffe, wiederverwertbare Verpackungen',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v4: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouTakeMeasuresToAvoidNegativeEnvironmentalImpactsGenerated: {
        label:
          'Ergreifen Sie Maßnahmen, um negative Umweltauswirkungen zu vermeiden, die unmittelbar durch die Erbringung Ihrer Dienstleistungen oder die Herstellung/Lieferung Ihrer Produkte entstehen?',
        sublabel: 'z. B. Verschmutzung, Verlust der biologischen Vielfalt',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v5: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNoNotRelevant: {
        label:
          'Bitte erläutern Sie, warum Sie "Nein, nicht relevant" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToManageAndReduce_: {
        label:
          'Welche Maßnahmen ergreifen Sie zur Bewältigung und Verringerung/Vermeidung negativer Umweltauswirkungen?',
        sublabel: '',
        tooltip: '',
      },
      monitoringEnvironmentalPollution: {
        label: 'Überwachung der Umweltverschmutzung',
        sublabel: 'z. B. durch Überwachung der Luft- oder Wasserqualität',
        tooltip: '',
      },
      usingMoreEco_FriendlyAlternativesWherePossible: {
        label: 'Verwendung umweltfreundlicherer Alternativen, wo immer möglich',
        sublabel: 'z. B. ungiftige Chemikalien, organische Materialien',
        tooltip: '',
      },
      assureThatThereAreNoSpillsOrLeaksOfHazardous: {
        label:
          'Sicherstellen, dass keine gefährlichen oder giftigen Materialien verschüttet werden oder auslaufen',
        sublabel:
          'z. B. durch Behandlung, Handhabung, Lagerung und Entsorgung gefährlicher Stoffe wie Farben, Batterien oder Chemikalien gemäß den üblichen Sicherheitsverfahren',
        tooltip: '',
      },
      conductingNoActivitiesThatAreHarmfulToBiodiversity_Sensitive: {
        label:
          'Verzicht auf Aktivitäten, die für die biologische Vielfalt empfindliche Gebiete schädlich sind',
        sublabel:
          'z.B. Operationen in Regenwäldern, Zerstörung von Lebensräumen',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v5: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      businessModelOther: {
        label: 'Geschäftsmodell (Sonstiges)',
        sublabel:
          "Falls Sie 'Sonstiges' gewählt haben, beschreiben Sie dies bitte hier.",
        tooltip: '',
      },
      mainIncomeStreamOther: {
        label: 'Haupteinkommensquelle (Sonstiges)',
        sublabel:
          "Falls Sie 'Sonstiges' gewählt haben, beschreiben Sie dies bitte hier.",
        tooltip: '',
      },
      industriesOther: {
        label: 'Industrien (Sonstiges)',
        sublabel: 'Sie können Ihre Branche nicht finden? Bitte hier angeben.',
        tooltip: '',
      },
      subindustriesOther: {
        label: 'Unterindustrien (Sonstige)',
        sublabel:
          "Falls Sie 'Sonstiges' gewählt haben, beschreiben Sie dies bitte hier.",
        tooltip: '',
      },
      technologiesOther: {
        label: 'Technologien (Sonstiges)',
        sublabel:
          "Falls Sie 'Sonstiges' gewählt haben, beschreiben Sie dies bitte hier.",
        tooltip: '',
      },
      doYouTakeIntoAccountEmploymentHumanRightsLegislationsFor: {
        label:
          'Berücksichtigen Sie die Arbeits- und Menschenrechtsgesetze für Ihr Unternehmen und/oder Ihre wichtigsten Zulieferer?',
        sublabel:
          'z.B. keine Zwangsarbeit, keine Kinderarbeit, Einhaltung der regionalen Arbeitsgesetzgebung',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v6: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      adherenceToWhichOfTheFollowingDoYouMonitor_: {
        label:
          'Die Einhaltung welcher der folgenden Punkte wird in Ihrem Betrieb überwacht/gewährleistet?',
        sublabel: '',
        tooltip: '',
      },
      humanRightsForOwnVenture: {
        label: 'Menschenrechte für das eigene Unternehmen',
        sublabel:
          'z.B. keine Zwangsarbeit, keine Kinderarbeit, kein Menschenhandel',
        tooltip: '',
      },
      regionalLaborLegislationOccupationalHealthSafetyStandards: {
        label:
          'Regionale Arbeitsgesetzgebung und Standards für Gesundheit und Sicherheit am Arbeitsplatz',
        sublabel: 'z.B. Mindestlöhne, Arbeitszeiten, Arbeitssicherheit',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v6: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouWorkTogetherWithKeySuppliersOrExternalService: {
        label:
          'Arbeiten Sie mit wichtigen Lieferanten oder externen Dienstleistern in Ihrer Wertschöpfungskette zusammen?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v7: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      doYouAdditionallyTakeIntoAccountEmploymentHumanRightsLegislations: {
        label:
          'Berücksichtigen Sie zusätzlich die Arbeits- und Menschenrechtsgesetze für Ihre wichtigsten Lieferanten/externen Dienstleister?',
        sublabel: '',
        tooltip: '',
      },
      humanRightsForKeySuppliers_ExternalServiceProviders: {
        label: 'Menschenrechte für wichtige Lieferanten/externe Dienstleister',
        sublabel:
          'z.B. keine Zwangsarbeit, keine Kinderarbeit, kein Menschenhandel',
        tooltip: '',
      },
      regionalLaborLegislationOccupationalHealthSafetyStandards_v2: {
        label:
          'Regionale Arbeitsgesetzgebung und Standards für Gesundheit und Sicherheit am Arbeitsplatz',
        sublabel: 'z.B. Mindestlöhne, Arbeitszeiten, Arbeitssicherheit',
        tooltip: '',
      },
      doesYourVentureProvideAQualityWorkplaceForItsEmployees: {
        label:
          'Bietet Ihr Unternehmen einen hochwertigen Arbeitsplatz für seine Mitarbeiter?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v8: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingCriteriaDoesYourVentureCurrentlySatisfy: {
        label:
          'Welche der folgenden Kriterien erfüllt Ihr Unternehmen derzeit?',
        sublabel: '',
        tooltip: '',
      },
      family_FriendlyWorkplace: {
        label: 'Familienfreundlicher Arbeitsplatz',
        sublabel:
          'z. B. Elternurlaub, Unterstützung bei der Kinderbetreuung, Pflege von Angehörigen',
        tooltip: '',
      },
      flexibleWorkingRoutine: {
        label: 'Flexibler Arbeitsalltag',
        sublabel:
          'z. B. Home-Office, Teilzeitstellen, Job-Sharing-Optionen, Freistellungen',
        tooltip: '',
      },
      livingWage: {
        label: 'Existenzsichere Löhne',
        sublabel:
          'z. B. Befriedigung aller Grundbedürfnisse, angemessener Lebensstandard',
        tooltip: '',
      },
      financialSocialAnd_OrHealthBenefitsInAdditionTo: {
        label:
          'Finanzielle, soziale und/oder gesundheitliche Leistungen zusätzlich zu den gesetzlich vorgeschriebenen Leistungen',
        sublabel:
          'z. B. betriebliche Altersvorsorge, zusätzliche Urlaubstage, vom Unternehmen bezahlte Gesundheitschecks',
        tooltip: '',
      },
      employeeTrainings: {
        label: 'Mitarbeiterschulungen',
        sublabel: 'z.B. externe Schulungen, Coaching-Möglichkeiten',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v7: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v8: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doesYourVentureSupportDiversityAndInclusionInTheWorkplace: {
        label:
          'Unterstützt Ihr Unternehmen Vielfalt und Integration am Arbeitsplatz?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v9: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingDiversityInclusionCharacteristicsAreSupportedBy: {
        label:
          'Welche der folgenden Merkmale von Vielfalt und Integration werden von Ihrem Unternehmen unterstützt?',
        sublabel:
          'z. B. in der Einstellungsstrategie, durch einen behindertengerechten Arbeitsplatz, durch das Angebot von Sprachkursen',
        tooltip: '',
      },
      genderDiversity: {
        label: 'Geschlechtervielfalt',
        sublabel: '',
        tooltip: '',
      },
      culturalEthnicDiversity: {
        label: 'Kulturelle und ethnische Vielfalt',
        sublabel: '',
        tooltip: '',
      },
      age_RelatedDiversity: {
        label: 'Altersbedingte Vielfalt',
        sublabel: '',
        tooltip: '',
      },
      disabilityInclusion: {
        label: 'Eingliederung von Behinderten',
        sublabel: '',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v9: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doesYourVentureMakeContributionsToSociety: {
        label: 'Leistet Ihr Unternehmen einen Beitrag zur Gesellschaft?',
        sublabel:
          'z. B. Unterstützung lokaler Gemeinschaften, Forschungsprojekte, Pro-bono-Aktivitäten',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v10: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingSocietalContributionsBestDescribeYourActivities: {
        label:
          'Welcher der folgenden Beiträge zur Gesellschaft beschreibt Ihre Tätigkeit am besten?',
        sublabel: '',
        tooltip: '',
      },
      localCommunityDevelopment: {
        label: 'Entwicklung der lokalen Gemeinschaft',
        sublabel:
          'z.B. Zusammenarbeit mit benachbarten gemeinnützigen Initiativen',
        tooltip: '',
      },
      promotingPositiveSocialChange: {
        label: 'Förderung des positiven sozialen Wandels',
        sublabel:
          'z.B. den Mitarbeitern die Möglichkeit geben, an freiwilligen sozialen Aktivitäten teilzunehmen',
        tooltip: '',
      },
      advocatingSustainabilityInSociety: {
        label: 'Für Nachhaltigkeit in der Gesellschaft eintreten',
        sublabel:
          'z. B. Veranstaltung von Programmen zur Sensibilisierung für die Umwelt, Teilnahme an lokalen Diskussionen',
        tooltip: '',
      },
      fightingEnvironmentalDamages: {
        label: 'Reduzierung von Umweltschäden verursacht durch Konsum',
        sublabel:
          'z. B. Ermutigung Ihrer Mitarbeiter zum Verzicht auf Papier und Fleisch sowie zum Reduzieren, Wiederverwenden und Recyceln',
        tooltip: '',
      },
      philanthropy: {
        label: 'Philanthropie',
        sublabel:
          'z. B. eine Spendenaktion durchführen, Bildungsprogramme sponsern',
        tooltip: '',
      },
      creatingPublicKnowledgeAnd_OrOpenSourceTools: {
        label: 'Schaffung von öffentlichem Wissen und/oder Open-Source-Tools',
        sublabel:
          'z. B. Erstellung eines kostenlosen Whitepapers zum Thema Nachhaltigkeit, Unterstützung bei der Entwicklung eines Open-Source-WIKI',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v10: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouTakeMeasuresToAvoidNegativeSocialImpactsGenerated: {
        label:
          'Ergreifen Sie Maßnahmen zur Vermeidung negativer sozialer Wirkungen, die als direkte Folge der Erbringung Ihrer Dienstleistungen oder der Herstellung/Lieferung Ihrer Produkte entstehen?',
        sublabel:
          'z. B. faire Preise und Vermarktung, gute Produktqualität, Vermeidung von Gesundheitsrisiken für die Betroffenen',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v11: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToManageAndReduce__v2: {
        label:
          'Welche Maßnahmen ergreifen Sie, um negative soziale Wirkungen zu bewältigen und zu verringern bzw. zu verhindern?',
        sublabel: '',
        tooltip: '',
      },
      usingFairSellingPractices: {
        label: 'Anwendung fairer Verkaufspraktiken',
        sublabel:
          'z.B. kein irreführendes Marketing, faire Kundenverträge, keine Monopole',
        tooltip: '',
      },
      assureAccessibility_Affordability: {
        label: 'Zugänglichkeit / Erschwinglichkeit sicherstellen',
        sublabel:
          'z. B. differenzierte Preise für unterversorgte Kundengruppen, Nutzung digitaler Tools durch ältere Menschen',
        tooltip: '',
      },
      optimizeCustomerWelfare: {
        label: 'Optimieren Sie das Wohlbefinden Ihrer Kunden',
        sublabel: 'z.B. Gesundheitsrisiken vermeiden, Qualität optimieren',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v11: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouFulfillBestPracticesAndRegulatoryStandardsForThe: {
        label:
          'Erfüllen Sie bewährte Verfahren und gesetzliche Standards für den Umgang mit Daten?',
        sublabel:
          'z. B. Einhaltung der Datenschutzgrundverordnung, Transparenz über die Datennutzung, Datensicherheit',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v12: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingMeasuresBestDescribeDataProcessingAnd: {
        label:
          'Welche der folgenden Maßnahmen beschreiben am besten die Datenverarbeitung und die Datenschutzaktivitäten in Ihrem Unternehmen?',
        sublabel: '',
        tooltip: '',
      },
      consentualDataCollection: {
        label: 'Einvernehmliche Datenerhebung',
        sublabel: 'z. B. Web-Cookies, Informationen über Benutzerkonten',
        tooltip: '',
      },
      fairLawfulUsage: {
        label: 'Faire und rechtmäßige Nutzung',
        sublabel:
          'z.B. Verwendung der Daten nur für den vom Kunden genehmigten Zweck',
        tooltip: '',
      },
      restrictedAccess: {
        label: 'Eingeschränkter Zugang',
        sublabel:
          'z.B. nur einer Handvoll Personen den autorisierten Zugang zu den Daten zu ermöglichen',
        tooltip: '',
      },
      dataSecurity: {
        label: 'Sicherheit der Daten',
        sublabel: 'z.B. Penetrationstests, Firewall, Anti-Malware, Anti-Virus',
        tooltip: '',
      },
      trainingsForEmployees: {
        label: 'Schulungen für Mitarbeiter',
        sublabel:
          'z.B. Erkennen von allgemeinen Datensicherheitsbedrohungen wie E-Mail-Viren',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v12: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouActivelyEngageWithYourStakeholders: {
        label: 'Engagieren Sie sich aktiv mit Ihren Stakeholdern?',
        sublabel:
          'z. B. Ihre Kunden, Lieferanten oder die Gemeinschaft, in der Sie tätig sind',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v13: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseStakeholdersHaveYouInteractedWithInThe: {
        label:
          'Mit welchen dieser Interessengruppen hatten Sie in den letzten sechs Monaten zu tun?',
        sublabel:
          'Bitten Sie z. B. Kunden um Feedback, führen Sie Umfragen zur Mitarbeiterzufriedenheit durch, erkundigen Sie sich nach der Situation Ihrer Zulieferer oder beziehen Sie die Stakeholder in Ihrer lokalen Gemeinschaft mit ein.',
        tooltip: '',
      },
      customersUsers: {
        label: 'Kunden und Benutzer',
        sublabel: '',
        tooltip: '',
      },
      employees: {
        label: 'Mitarbeiter',
        sublabel: '',
        tooltip: '',
      },
      suppliersVendors: {
        label: 'Lieferanten und Verkäufer',
        sublabel: '',
        tooltip: '',
      },
      localCommunitiesYouOperateIn: {
        label: 'Lokale Gemeinschaften, in denen Sie tätig sind',
        sublabel: '',
        tooltip: '',
      },
      governments: {
        label: 'Regierungen',
        sublabel: '',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v13: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      whichOfTheFollowingEthicalBehaviorsAreRespectedInYour: {
        label:
          'Welche der folgenden ethischen Verhaltensweisen werden in Ihrem Unternehmen beachtet?',
        sublabel: '',
        tooltip: '',
      },
      anti_BriberyAnti_Corruption: {
        label: 'Anti-Bestechung und Anti-Korruption',
        sublabel: '',
        tooltip: '',
      },
      taxCompliance: {
        label: 'Einhaltung von Steuervorschriften',
        sublabel: '',
        tooltip: '',
      },
      respectOfTheIntellectualPropertyOfOthers: {
        label: 'Respekt vor dem geistigen Eigentum anderer',
        sublabel: 'z.B. durch transparente Offenlegung der wahren Herkunft',
        tooltip: '',
      },
      whistleblowing: {
        label: 'Whistleblowing',
        sublabel: '',
        tooltip: '',
      },
      fairCompetition: {
        label: 'Fairer Wettbewerb',
        sublabel:
          'z. B. durch Vermeidung von falscher, irreführender und trügerischer Werbung',
        tooltip: '',
      },
      anti_Harassment: {
        label: 'Anti-Belästigung',
        sublabel: '',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v14: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouHaveAQualifiedAndDiverseManagementTeam: {
        label:
          'Verfügen Sie über ein qualifiziertes und vielfältiges Managementteam?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v14: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseCharacteristicsBestDefineTheCompositionOfThe: {
        label:
          'Welche dieser Eigenschaften beschreiben die Zusammensetzung des Managementteams am besten?',
        sublabel: '',
        tooltip: '',
      },
      genderDiversity_v2: {
        label: 'Geschlechtervielfalt',
        sublabel: '',
        tooltip: '',
      },
      culturalEthnicDiversity_v2: {
        label: 'Kulturelle und ethnische Vielfalt',
        sublabel: '',
        tooltip: '',
      },
      age_RelatedDiversity_v2: {
        label: 'Altersbedingte Vielfalt',
        sublabel: '',
        tooltip: '',
      },
      disabilityInclusion_v2: {
        label: 'Eingliederung von Menschen mit Behinderungen',
        sublabel: '',
        tooltip: '',
      },
      expertise: {
        label: 'Fachwissen',
        sublabel: 'z. B. Fachleute mit unterschiedlichen Fähigkeiten',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v15: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      genderDiversity_v3: {
        label: 'Geschlechtervielfalt',
        sublabel: '',
        tooltip: '',
      },
      culturalEthnicDiversity_v3: {
        label: 'Kulturelle und ethnische Vielfalt',
        sublabel: '',
        tooltip: '',
      },
      age_RelatedDiversity_v3: {
        label: 'Altersbedingte Vielfalt',
        sublabel: '',
        tooltip: '',
      },
      disabilityInclusion_v3: {
        label: 'Eingliederung von Menschen mit Behinderungen',
        sublabel: '',
        tooltip: '',
      },
      expertise_v2: {
        label: 'Fachwissen',
        sublabel: 'z. B. Fachleute mit unterschiedlichen Fähigkeiten',
        tooltip: '',
      },
      independence: {
        label: 'Unabhängigkeit',
        sublabel:
          'unabhängige Berater sind nicht bei dem Unternehmen angestellt und machen keine Geschäfte mit dem Unternehmen',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v16: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doYouTakeIntoAccountRegulationAsWellAsQuality: {
        label:
          'Berücksichtigen Sie dabei sowohl Vorschriften als auch Qualitäts- und Nachhaltigkeitsstandards?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v15: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingMeasuresDoYouTakeToComply: {
        label:
          'Welche der folgenden Maßnahmen ergreifen Sie zur Einhaltung von Vorschriften und Normen?',
        sublabel: '',
        tooltip: '',
      },
      identifyAndComplyWithRelevantRegulations: {
        label: 'Identifizierung und Einhaltung der einschlägigen Vorschriften',
        sublabel:
          'z. B. über spezielle Ressourcen verfügen, Richtlinien zur Einhaltung von Vorschriften wie der DSGVO entwickeln',
        tooltip: '',
      },
      adhereToQualityAndIndustryStandards: {
        label: 'Einhaltung von Qualitäts- und Industriestandards',
        sublabel: 'z.B. HACCP, ISO-Normen',
        tooltip: '',
      },
      activelyManageEsgPerformance: {
        label: 'Aktives Management der ESG-Leistung',
        sublabel:
          'z. B. über spezielle Ressourcen für das ESG-Management verfügen, Nachhaltigkeitszertifikate erwerben oder eine ESG-Politik des Unternehmens ausarbeiten',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v17: {
        label:
          'Bitte geben Sie ein kurzes Beispiel an, das Ihre Auswahl rechtfertigt (z. B. Maßnahmen, die Sie durchführen oder Ziele, die Sie verfolgen).',
        sublabel:
          'Auch wenn keine der oben genannten Antwortmöglichkeiten zutrifft, erklären Sie bitte, was Sie tun.',
        tooltip: '',
      },
      doesYourVentureViolateOneOrMoreOfTheCore: {
        label:
          'Verstößt Ihr Vorhaben gegen eines oder mehrere der Kernprinzipien des UN Global Compact?',
        sublabel:
          'Bitte wählen Sie die Grundsätze aus, gegen die Ihr Vorhaben verstößt. Liegt kein Verstoß vor, wählen Sie bitte die Option "Keiner der oben genannten".',
        tooltip: '',
      },
      pleaseExplainToWhatExtentYouViolateThePrinciples: {
        label:
          'Erläutern Sie bitte, inwieweit Sie gegen den/die Grundsatz(e) verstoßen.',
        sublabel: '',
        tooltip: '',
      },
      pleaseIndicateIfYouAreActiveInAnyOfThe: {
        label:
          'Bitte geben Sie an, ob Sie in einem der unten aufgeführten Geschäftsbereiche tätig sind',
        sublabel:
          'Bitte geben Sie an, wenn Sie einen der unten aufgeführten umstrittenen Geschäftsbereiche direkt produzieren, verkaufen oder bewerben. Sie können Ihre Aktivitäten in dem freien Text unten näher spezifizieren. Wenn Sie in keinem der aufgelisteten Bereiche tätig sind, wählen Sie bitte "Keine der oben genannten".',
        tooltip: '',
      },
      pleaseExplainToWhatExtentYouAreActiveInThe: {
        label:
          'Bitte erläutern Sie, in welchem Umfang Sie in den Geschäftsbereichen tätig sind.',
        sublabel: '',
        tooltip: '',
      },
      pleaseIndicateIfYouDerivedAnyRevenuesFromFossilFuels: {
        label:
          'Bitte geben Sie an, ob Sie Einnahmen aus fossilen Brennstoffen erzielt haben',
        sublabel: '',
        tooltip:
          'Dazu gehören Einnahmen aus der Exploration, dem Abbau, der Gewinnung, dem Vertrieb oder der Veredelung von Stein- und Braunkohle, aus der Exploration, der Gewinnung, dem Vertrieb (einschließlich Transport, Lagerung und Handel) oder der Veredelung flüssiger fossiler Brennstoffe und aus der Exploration und Gewinnung fossiler gasförmiger Brennstoffe oder aus deren Vertrieb (einschließlich Transport, Lagerung und Handel).',
      },
      pleaseProvideDetailsOnYourRevenuesFromFossilFuels: {
        label:
          'Bitte machen Sie genaue Angaben zu Ihren Einnahmen aus fossilen Brennstoffen',
        sublabel: '',
        tooltip: '',
      },
      fairPay: {
        label: 'Gerechte Entlohnung',
        sublabel:
          'z. B. kein geschlechtsspezifisches Lohngefälle, faires Lohnverhältnis zwischen Geschäftsführenden und Arbeitnehmenden',
        tooltip: '',
      },
      fightNegativeSocialImpacts: {
        label: 'Negative soziale Wirkungen bekämpfen',
        sublabel:
          'z. B. durch technologische Risiken oder potenziellen Missbrauch wie Fake News oder Deep Fakes',
        tooltip: '',
      },
      doYouHaveAnAdvisoryBoard: {
        label: 'Haben Sie einen Beirat?',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseCharacteristicsBestDefineTheCompositionOfThe_v2: {
        label:
          'Welche dieser Merkmale beschreiben die Zusammensetzung des Beirats am besten?',
        sublabel: '',
        tooltip: '',
      },
      haveThereBeenAnyPublicComplaintsOrLegalActionsOn: {
        label:
          'Gab es in den letzten drei Jahren öffentliche Beschwerden oder rechtliche Schritte in den Bereichen Soziales, Umwelt oder Unternehmensführung?',
        sublabel:
          'z. B. zu Themen wie Arbeitnehmerrechte, Verbraucherschäden oder Umweltverschmutzung',
        tooltip: '',
      },
      pleaseProvideDetailsOnAllReportedComplaintsClaimsOrLegal: {
        label:
          'Bitte machen Sie detaillierte Angaben zu allen gemeldeten Beschwerden, Ansprüchen oder rechtlichen Schritten',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v16: {
        label: 'Bitte erklären Sie, warum Sie "Nein" gewählt haben.',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToReduceGhgEmissionsNot: {
        label:
          'Welche Maßnahmen ergreifen Sie zur Verringerung der Treibhausgasemissionen, die nicht mit Energie oder Verkehr zusammenhängen?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToMakeYourEnergyConsumption: {
        label:
          'Welche Maßnahmen ergreifen Sie, um Ihren Energieverbrauch nachhaltiger zu gestalten?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v2: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToMakeYourCompanysTransportation: {
        label:
          'Welche Maßnahmen ergreifen Sie, um die Transportaktivitäten Ihres Unternehmens nachhaltiger zu gestalten?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v3: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureSustainableResourceConsumption: {
        label:
          'Welche Maßnahmen ergreifen Sie, um einen nachhaltigen Ressourcenverbrauch und -einsatz in Ihrem Unternehmen sicherzustellen?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v4: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToAvoidNegativeEnvironmentalImpacts: {
        label:
          'Welche Maßnahmen ergreifen Sie, um negative Wirkungen auf die Umwelt zu vermeiden, die unmittelbar durch Ihr Unternehmen entstehen?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v5: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      withRegardToYourCompanyAndYourSupplyChainWhat: {
        label:
          'In Bezug auf Ihr Unternehmen und Ihre Lieferkette: Welche Maßnahmen ergreifen Sie, um die Arbeits- und Menschenrechtsgesetze sowie die Standards für Gesundheit und Sicherheit am Arbeitsplatz einzuhalten?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v6: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureTheQualityOf: {
        label:
          'Welche Maßnahmen ergreifen Sie, um die Qualität Ihres Arbeitsplatzes für Ihre Mitarbeiter zu sichern?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v7: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureDiversityAndInclusion: {
        label:
          'Welche Maßnahmen ergreifen Sie, um Vielfalt und Integration in Ihrem Unternehmen zu gewährleisten?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v8: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToAvoidSocietalHarmGenerated: {
        label:
          'Welche Maßnahmen ergreifen Sie, um gesellschaftliche Schäden zu vermeiden, die direkt von Ihrem Unternehmen ausgehen?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v9: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToMakePositiveContributionsTo: {
        label:
          'Welche Maßnahmen ergreifen Sie, um einen positiven Beitrag zur Gesellschaft zu leisten?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v10: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureAndImproveResponsible: {
        label:
          'Welche Maßnahmen ergreifen Sie, um eine verantwortungsvolle Datenverarbeitung und den Datenschutz in Ihrem Unternehmen zu gewährleisten und zu verbessern?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v11: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureActiveStakeholderEngagement: {
        label:
          'Welche Maßnahmen ergreifen Sie, um eine aktive Einbeziehung der Stakeholder zu gewährleisten?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v12: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureEthicalBehaviorIn: {
        label:
          'Welche Maßnahmen ergreifen Sie, um ethisches Verhalten in Ihrem Unternehmen sicherzustellen?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v13: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      withRegardToYourManagementTeamOrYourAdvisoryBoard: {
        label:
          'In Bezug auf Ihr Managementteam oder Ihren Beirat: Welche Maßnahmen ergreifen Sie, um eine qualifizierte und vielfältige Zusammensetzung zu gewährleisten?  ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v14: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToComplyWithRelevantRegulations: {
        label:
          'Welche Maßnahmen ergreifen Sie, um die einschlägigen Vorschriften, Standards und ESG-Anforderungen einzuhalten?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v15: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      doesYourVentureAdhereToTheOecdGuidelinesForMultinational: {
        label:
          'Hält sich Ihr Unternehmen an die OECD-Leitsätze für multinationale Unternehmen?',
        sublabel:
          'Wählen Sie zwischen "comply" ("befolge") oder "explain" ("erkläre") und geben Sie an, ob Ihr Unternehmen die OECD-Leitsätze für multinationale Unternehmen befolgt. Wenn Sie "Darüber wollen wir nicht berichten" wählen, erklären Sie bitte, warum Sie diese Antwortoption gewählt haben. *Unternehmen, die verantwortungsbewusst und gesetzeskonform handeln, können davon ausgehen, dass sie die Leitlinien einhalten.*',
        tooltip: '',
      },
      additionalComments_v16: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      doesYourVentureAdhereToTheUnGuidingPrinciplesOn: {
        label:
          'Hält sich Ihr Unternehmen an die UN-Leitprinzipien für Wirtschaft und Menschenrechte einschließlich der IAO-Übereinkommen?',
        sublabel:
          'Wählen Sie zwischen "comply" ("befolge") oder "explain" ("erkläre") und geben Sie an, ob sich Ihr Unternehmen an die UN-Leitprinzipien für Wirtschaft und Menschenrechte hält. Wenn Sie "Darüber wollen wir nicht berichten" wählen, erklären Sie bitte, warum Sie diese Antwortoption gewählt haben. *Unternehmen, die verantwortungsbewusst und gesetzeskonform handeln, können davon ausgehen, dass sie die Grundsätze einhalten.*',
        tooltip: '',
      },
      additionalComments_v17: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v18: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v19: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v20: {
        label: 'Zusätzliche Kommentare',
        sublabel: '',
        tooltip: '',
      },
    },
    option: {
      n1: {
        label: '1',
        sublabel: '',
        tooltip: '',
      },
      n2_10: {
        label: '2-10',
        sublabel: '',
        tooltip: '',
      },
      n11_50: {
        label: '11-50',
        sublabel: '',
        tooltip: '',
      },
      n51_200: {
        label: '51-200',
        sublabel: '',
        tooltip: '',
      },
      n201_500: {
        label: '201-500',
        sublabel: '',
        tooltip: '',
      },
      n501_1000: {
        label: '501-1000',
        sublabel: '',
        tooltip: '',
      },
      n1001_5000: {
        label: '1001-5000',
        sublabel: '',
        tooltip: '',
      },
      n10000Plus: {
        label: '10000+',
        sublabel: '',
        tooltip: '',
      },
      lessThanEuros0_1M: {
        label: '<\u20AC0.1m',
        sublabel: '',
        tooltip: '',
      },
      euros0_1M_Euros0_5M: {
        label: '\u20AC0.1m - \u20AC0.5m',
        sublabel: '',
        tooltip: '',
      },
      euros0_5M_Euros1M: {
        label: '\u20AC0.5m - \u20AC1m',
        sublabel: '',
        tooltip: '',
      },
      lessThanEuros1M: {
        label: '<\u20AC1m',
        sublabel: '',
        tooltip: '',
      },
      euros1M_Euros5M: {
        label: '\u20AC1m - \u20AC5m',
        sublabel: '',
        tooltip: '',
      },
      euros5M_Euros10M: {
        label: '\u20AC5m - \u20AC10m',
        sublabel: '',
        tooltip: '',
      },
      greatherThanEuros10M: {
        label: '>\u20AC10m',
        sublabel: '',
        tooltip: '',
      },
      bootstrapping_NoExternalFunding: {
        label: 'Bootstrapping / keine externe Finanzierung',
        sublabel: '',
        tooltip: '',
      },
      pre_Seed: {
        label: 'Pre-Seed',
        sublabel: '',
        tooltip: '',
      },
      seed: {
        label: 'Seed',
        sublabel: '',
        tooltip: '',
      },
      seriesA: {
        label: 'Series A',
        sublabel: '',
        tooltip: '',
      },
      seriesB: {
        label: 'Series B',
        sublabel: '',
        tooltip: '',
      },
      seriesCPlus: {
        label: 'Series C+',
        sublabel: '',
        tooltip: '',
      },
      manufacturing: {
        label: 'Herstellung',
        sublabel: '',
        tooltip: '',
      },
      marketplaceEcommerce: {
        label: 'Marktplatz und elektronischer Handel',
        sublabel: '',
        tooltip: '',
      },
      saas: {
        label: 'SaaS',
        sublabel: '',
        tooltip: '',
      },
      other: {
        label: 'Sonstiges',
        sublabel: '',
        tooltip: '',
      },
      advertising: {
        label: 'Werbung',
        sublabel: '',
        tooltip: '',
      },
      commission: {
        label: 'Kommission',
        sublabel: '',
        tooltip: '',
      },
      sellingOwnInventory: {
        label: 'Verkauf von eigenem Inventar',
        sublabel: '',
        tooltip: '',
      },
      subscription: {
        label: 'Abonnement',
        sublabel: '',
        tooltip: '',
      },
      other_v2: {
        label: 'Sonstiges',
        sublabel: '',
        tooltip: '',
      },
      dating: {
        label: 'Partnersuche',
        sublabel: '',
        tooltip: '',
      },
      education: {
        label: 'Bildung',
        sublabel: '',
        tooltip: '',
      },
      energy: {
        label: 'Energie',
        sublabel: '',
        tooltip: '',
      },
      enterpriseSoftware: {
        label: 'Unternehmenssoftware',
        sublabel: '',
        tooltip: '',
      },
      eventTech: {
        label: 'Veranstaltungstechnik',
        sublabel: '',
        tooltip: '',
      },
      fashion: {
        label: 'Mode',
        sublabel: '',
        tooltip: '',
      },
      fintech: {
        label: 'Fintech',
        sublabel: '',
        tooltip: '',
      },
      food: {
        label: 'Lebensmittel',
        sublabel: '',
        tooltip: '',
      },
      gaming: {
        label: 'Spielen',
        sublabel: '',
        tooltip: '',
      },
      health: {
        label: 'Gesundheit',
        sublabel: '',
        tooltip: '',
      },
      homeLiving: {
        label: 'Wohnen zu Hause',
        sublabel: '',
        tooltip: '',
      },
      hosting: {
        label: 'Hosting',
        sublabel: '',
        tooltip: '',
      },
      jobsRecruitment: {
        label: 'Rekrutierung von Stellen',
        sublabel: '',
        tooltip: '',
      },
      kids: {
        label: 'Kinder',
        sublabel: '',
        tooltip: '',
      },
      legal: {
        label: 'Rechtliches',
        sublabel: '',
        tooltip: '',
      },
      marketing: {
        label: 'Marketing',
        sublabel: '',
        tooltip: '',
      },
      media: {
        label: 'Medien',
        sublabel: '',
        tooltip: '',
      },
      music: {
        label: 'Musik',
        sublabel: '',
        tooltip: '',
      },
      realEstate: {
        label: 'Immobilien',
        sublabel: '',
        tooltip: '',
      },
      robotics: {
        label: 'Robotik',
        sublabel: '',
        tooltip: '',
      },
      security: {
        label: 'Sicherheit',
        sublabel: '',
        tooltip: '',
      },
      semiconductors: {
        label: 'Halbleiter',
        sublabel: '',
        tooltip: '',
      },
      sports: {
        label: 'Sport',
        sublabel: '',
        tooltip: '',
      },
      telecom: {
        label: 'Telekommunikation',
        sublabel: '',
        tooltip: '',
      },
      transportation: {
        label: 'Transport',
        sublabel: '',
        tooltip: '',
      },
      travel: {
        label: 'Reisen',
        sublabel: '',
        tooltip: '',
      },
      wellnessBeauty: {
        label: 'Wellness-Schönheit',
        sublabel: '',
        tooltip: '',
      },
      other_v3: {
        label: 'Sonstiges',
        sublabel: '',
        tooltip: '',
      },
      accessories: {
        label: 'Zubehör',
        sublabel: '',
        tooltip: '',
      },
      accommodation: {
        label: 'Unterkunft',
        sublabel: '',
        tooltip: '',
      },
      adtech: {
        label: 'Adtech',
        sublabel: '',
        tooltip: '',
      },
      agritech: {
        label: 'Agritech',
        sublabel: '',
        tooltip: '',
      },
      apparel: {
        label: 'Kleidung',
        sublabel: '',
        tooltip: '',
      },
      autonomousSensorTech: {
        label: 'Autonome und Sensortechnik',
        sublabel: '',
        tooltip: '',
      },
      banking: {
        label: 'Bankwesen',
        sublabel: '',
        tooltip: '',
      },
      bettingGambling: {
        label: 'Wetten und Glücksspiele',
        sublabel: '',
        tooltip: '',
      },
      biotechnology: {
        label: 'Biotechnologie',
        sublabel: '',
        tooltip: '',
      },
      boardGames: {
        label: 'Brettspiele',
        sublabel: '',
        tooltip: '',
      },
      bookingSearch: {
        label: 'Buchung & Suche',
        sublabel: '',
        tooltip: '',
      },
      businessTravel: {
        label: 'Geschäftsreisen',
        sublabel: '',
        tooltip: '',
      },
      cleanEnergy: {
        label: 'Saubere Energie',
        sublabel: '',
        tooltip: '',
      },
      cloudInfrastructure: {
        label: 'Wolke und Infrastruktur',
        sublabel: '',
        tooltip: '',
      },
      consolePcGaming: {
        label: 'Konsolen- und PC-Spiele',
        sublabel: '',
        tooltip: '',
      },
      construction: {
        label: 'Bauwesen',
        sublabel: '',
        tooltip: '',
      },
      contentProduction: {
        label: 'Produktion von Inhalten',
        sublabel: '',
        tooltip: '',
      },
      crmSales: {
        label: 'CRM & Vertrieb',
        sublabel: '',
        tooltip: '',
      },
      cryptoAndDefi: {
        label: 'Krypto und Defi',
        sublabel: '',
        tooltip: '',
      },
      dataProtection: {
        label: 'Datenschutz',
        sublabel: '',
        tooltip: '',
      },
      deviceSecurityAntivirus: {
        label: 'Gerätesicherheit & Antivirus',
        sublabel: '',
        tooltip: '',
      },
      ecommerceSolutions: {
        label: 'Lösungen für den elektronischen Handel',
        sublabel: '',
        tooltip: '',
      },
      energyEfficiency: {
        label: 'Energie-Effizienz',
        sublabel: '',
        tooltip: '',
      },
      energyProviders: {
        label: 'Energieversorger',
        sublabel: '',
        tooltip: '',
      },
      energyStorage: {
        label: 'Energiespeicherung',
        sublabel: '',
        tooltip: '',
      },
      esports: {
        label: 'Esports',
        sublabel: '',
        tooltip: '',
      },
      financialManagementSolutions: {
        label: 'Lösungen für die Finanzverwaltung',
        sublabel: '',
        tooltip: '',
      },
      fitness: {
        label: 'Fitness',
        sublabel: '',
        tooltip: '',
      },
      foodLogisticsDelivery: {
        label: 'Lebensmittellogistik und -lieferung',
        sublabel: '',
        tooltip: '',
      },
      footwear: {
        label: 'Schuhe',
        sublabel: '',
        tooltip: '',
      },
      healthPlatform: {
        label: 'Plattform Gesundheit',
        sublabel: '',
        tooltip: '',
      },
      identityAccess: {
        label: 'Identität und Zugang',
        sublabel: '',
        tooltip: '',
      },
      in_StoreRetailRestaurantTech: {
        label: 'Einzelhandels- und Restauranttechnik in Geschäften',
        sublabel: '',
        tooltip: '',
      },
      innovativeFood: {
        label: 'Innovative Lebensmittel',
        sublabel: '',
        tooltip: '',
      },
      insurance: {
        label: 'Versicherung',
        sublabel: '',
        tooltip: '',
      },
      intellectualProperty: {
        label: 'Geistiges Eigentum',
        sublabel: '',
        tooltip: '',
      },
      kitchenCookingTech: {
        label: 'Küche & Kochtechnik',
        sublabel: '',
        tooltip: '',
      },
      legalDocumentsManagement: {
        label: 'Verwaltung juristischer Dokumente',
        sublabel: '',
        tooltip: '',
      },
      legalInformation: {
        label: 'Rechtliche Informationen',
        sublabel: '',
        tooltip: '',
      },
      legalMatterManagement: {
        label: 'Verwaltung von Rechtsangelegenheiten',
        sublabel: '',
        tooltip: '',
      },
      logisticsDelivery: {
        label: 'Logistik und Lieferung',
        sublabel: '',
        tooltip: '',
      },
      luxury: {
        label: 'Luxus',
        sublabel: '',
        tooltip: '',
      },
      maintenance: {
        label: 'Wartung',
        sublabel: '',
        tooltip: '',
      },
      marketingAnalytics: {
        label: 'Marketing-Analytik',
        sublabel: '',
        tooltip: '',
      },
      medicalDevices: {
        label: 'Medizinische Geräte',
        sublabel: '',
        tooltip: '',
      },
      mobileGaming: {
        label: 'Mobile Spiele',
        sublabel: '',
        tooltip: '',
      },
      mobility: {
        label: 'Mobilität',
        sublabel: '',
        tooltip: '',
      },
      mortgagesLending: {
        label: 'Hypotheken und Kredite',
        sublabel: '',
        tooltip: '',
      },
      navigationMapping: {
        label: 'Navigation und Kartierung',
        sublabel: '',
        tooltip: '',
      },
      oilGas: {
        label: 'Öl und Gas',
        sublabel: '',
        tooltip: '',
      },
      onlineTravelAgency: {
        label: 'Online-Reisebüro',
        sublabel: '',
        tooltip: '',
      },
      payments: {
        label: 'Zahlungen',
        sublabel: '',
        tooltip: '',
      },
      pharmaceuticals: {
        label: 'Pharmazeutika',
        sublabel: '',
        tooltip: '',
      },
      publicSafety: {
        label: 'Öffentliche Sicherheit',
        sublabel: '',
        tooltip: '',
      },
      publishing: {
        label: 'Veröffentlichung',
        sublabel: '',
        tooltip: '',
      },
      realEstateServices: {
        label: 'Dienstleistungen im Immobilienbereich',
        sublabel: '',
        tooltip: '',
      },
      realEstateSoftware: {
        label: 'Immobilien-Software',
        sublabel: '',
        tooltip: '',
      },
      regtech: {
        label: 'Regtech',
        sublabel: '',
        tooltip: '',
      },
      regtechCompliance: {
        label: 'Regtech und Einhaltung der Vorschriften',
        sublabel: '',
        tooltip: '',
      },
      searchBuyAndRent: {
        label: 'Suchen, kaufen und mieten',
        sublabel: '',
        tooltip: '',
      },
      self_ServiceAndLawyerMarketplace: {
        label: 'Selbstbedienung und Anwaltsmarktplatz',
        sublabel: '',
        tooltip: '',
      },
      socialMedia: {
        label: 'Soziale Medien',
        sublabel: '',
        tooltip: '',
      },
      sportLeagueClub: {
        label: 'Sportliga & Verein',
        sublabel: '',
        tooltip: '',
      },
      sportMedia: {
        label: 'Sportmedien',
        sublabel: '',
        tooltip: '',
      },
      sportPlatformApplication: {
        label: 'Sport-Plattform & Bewerbung',
        sublabel: '',
        tooltip: '',
      },
      sportSupplements: {
        label: 'Sport-Ergänzungen',
        sublabel: '',
        tooltip: '',
      },
      sportEquipment: {
        label: 'Sportgeräte',
        sublabel: '',
        tooltip: '',
      },
      streaming: {
        label: 'Streaming',
        sublabel: '',
        tooltip: '',
      },
      travelAnalyticsSoftware: {
        label: 'Reiseanalyse und Software',
        sublabel: '',
        tooltip: '',
      },
      vehicleProduction: {
        label: 'Fahrzeugproduktion',
        sublabel: '',
        tooltip: '',
      },
      wasteSolutions: {
        label: 'Lösungen für Abfälle',
        sublabel: '',
        tooltip: '',
      },
      water: {
        label: 'Wasser',
        sublabel: '',
        tooltip: '',
      },
      wealthManagement: {
        label: 'Vermögensverwaltung',
        sublabel: '',
        tooltip: '',
      },
      workspaces: {
        label: 'Arbeitsbereiche',
        sublabel: '',
        tooltip: '',
      },
      other_v4: {
        label: 'Sonstiges',
        sublabel: '',
        tooltip: '',
      },
      n3DTechnology: {
        label: '3D-Technik',
        sublabel: '',
        tooltip: '',
      },
      artificialIntelligence: {
        label: 'Künstliche Intelligenz',
        sublabel: '',
        tooltip: '',
      },
      augmentedReality: {
        label: 'Erweiterte Realität',
        sublabel: '',
        tooltip: '',
      },
      autonomousSensorTech_v2: {
        label: 'Autonome und Sensortechnik',
        sublabel: '',
        tooltip: '',
      },
      bigData: {
        label: 'Große Daten',
        sublabel: '',
        tooltip: '',
      },
      blockchain: {
        label: 'Blockchain',
        sublabel: '',
        tooltip: '',
      },
      computerVision: {
        label: 'Computer Vision',
        sublabel: '',
        tooltip: '',
      },
      connectedDevice: {
        label: 'Angeschlossenes Gerät',
        sublabel: '',
        tooltip: '',
      },
      deepLearning: {
        label: 'Tiefes Lernen',
        sublabel: '',
        tooltip: '',
      },
      deepTech: {
        label: 'Tiefe Technik',
        sublabel: '',
        tooltip: '',
      },
      hardware: {
        label: 'Hardware',
        sublabel: '',
        tooltip: '',
      },
      iotInternetofthings: {
        label: 'IOT internetofthings',
        sublabel: '',
        tooltip: '',
      },
      machineLearning: {
        label: 'Maschinelles Lernen',
        sublabel: '',
        tooltip: '',
      },
      mobileApp: {
        label: 'Mobile App',
        sublabel: '',
        tooltip: '',
      },
      nanotech: {
        label: 'Nanotech',
        sublabel: '',
        tooltip: '',
      },
      naturalLanguageProcessing: {
        label: 'Verarbeitung natürlicher Sprache',
        sublabel: '',
        tooltip: '',
      },
      quantumTechnologies: {
        label: 'Quanten-Technologien',
        sublabel: '',
        tooltip: '',
      },
      recognitionTechnology: {
        label: 'Technologie zur Erkennung',
        sublabel: '',
        tooltip: '',
      },
      virtualReality: {
        label: 'Virtuelle Realität',
        sublabel: '',
        tooltip: '',
      },
      other_v5: {
        label: 'Sonstiges',
        sublabel: '',
        tooltip: '',
      },
      yesAlreadyDoingIt_PlannedInTheNext12: {
        label:
          'Ja, ich habe es bereits getan / geplant in den nächsten 12 Monaten',
        sublabel: '',
        tooltip: '',
      },
      no: {
        label: 'Nein',
        sublabel: '',
        tooltip: '',
      },
      noNotRelevant: {
        label: 'Nein, nicht relevant',
        sublabel: '',
        tooltip: '',
      },
      yesAlreadyHavingOne_PlannedInTheNext12: {
        label: 'Ja, habe bereits eine / geplant in den nächsten 12 Monaten',
        sublabel: '',
        tooltip: '',
      },
      yes: {
        label: 'Ja',
        sublabel: '',
        tooltip: '',
      },
      noButPlannedInTheNext12Months: {
        label: 'Nein, aber geplant für die nächsten 12 Monate',
        sublabel: '',
        tooltip: '',
      },
      noNotPlanned: {
        label: 'Nein, nicht geplant',
        sublabel: '',
        tooltip: '',
      },
      naturalResources: {
        label: 'Natürliche Ressourcen',
        sublabel:
          'Bereitstellung von Produkten aus natürlichen Ressourcen (Landwirtschaft, Forstwirtschaft, Fischerei, Bergbau, Gas, Wasser, Abfall usw.)',
        tooltip: '',
      },
      manufacturing_v2: {
        label: 'Herstellung',
        sublabel:
          'Entwicklung von Produkten mit eigener Produktion (Hardware, Chemikalien, verarbeitete Lebensmittel, usw.)',
        tooltip: '',
      },
      softwareDigitalTechnologies: {
        label: 'Software und digitale Technologien',
        sublabel:
          'Bereitstellung von digitalen Dienstleistungen oder digitaler Infrastruktur',
        tooltip: '',
      },
      tradeIntermediation: {
        label: 'Handel und Vermittlungsdienste',
        sublabel:
          'Handel und Verkauf von Produkten und/oder Dienstleistungen anderer Unternehmen (Groß- und Einzelhandel, Finanzvermittlung)',
        tooltip: '',
      },
      technicalAndPhysicalServices: {
        label: 'Technische und physische Dienstleistungen',
        sublabel:
          'Bereitstellung von Gütern mit körperlicher Arbeit (Logistik, Verpflegung, Teilung, Bau, Handwerk, Unterkunft usw.)',
        tooltip: '',
      },
      informationServices: {
        label: 'Informationsdienste',
        sublabel:
          'Erbringung geistiger und wissensbasierter Dienstleistungen (Beratung, Kunst, Unterhaltung usw.)',
        tooltip: '',
      },
      other_v6: {
        label: 'Sonstiges',
        sublabel: '',
        tooltip: '',
      },
      humanRightsPrinciples: {
        label: 'Menschenrechte',
        sublabel: '',
        tooltip: `
1. Unternehmen sollen den Schutz der internationalen Menschenrechte unterstützen und achten.
2. Unternehmen sollen sicherstellen, dass sie sich nicht an Menschenrechtsverletzungen mitschuldig machen.`,
      },
      laborPrinciples: {
        label: 'Arbeitsnormen',
        sublabel: '',
        tooltip: `
3. Unternehmen sollen die Vereinigungsfreiheit und die wirksame Anerkennung des Rechts auf Kollektivverhandlungen wahren.
4. Unternehmen sollen für die Beseitigung aller Formen von Zwangsarbeit eintreten.
5. Unternehmen sollen für die Abschaffung von Kinderarbeit eintreten.
6. Unternehmen sollen für die Beseitigung von Diskriminierung bei Anstellung und Erwerbstätigkeit eintreten.`,
      },
      environmentPrinciples: {
        label: 'Umwelt',
        sublabel: '',
        tooltip: `
8. Unternehmen sollen Initiativen ergreifen, um größeres Umweltbewusstsein zu fördern.
9. Unternehmen sollen die Entwicklung und Verbreitung umweltfreundlicher Technologien beschleunigen.`,
      },
      anti_CorruptionPrinciple: {
        label: 'Korruptionsprävention',
        sublabel: '',
        tooltip: `
10. Unternehmen sollen gegen alle Arten der Korruption eintreten, einschließlich Erpressung und Bestechung.
        `,
      },
      noneOfTheAbove: {
        label: 'Keiner der oben genannten Punkte',
        sublabel: '',
        tooltip: '',
      },
      animalTesting: {
        label: 'Tierversuche',
        sublabel: '',
        tooltip: '',
      },
      alcoholicBeverages: {
        label: 'Alkoholische Getränke',
        sublabel: '',
        tooltip: '',
      },
      driftNetFishingInTheMarineEnvironment: {
        label: 'Treibnetzfischerei in der Meeresumwelt',
        sublabel: '',
        tooltip: '',
      },
      nuclearEnergyAndNuclearPowerPlants: {
        label: 'Kernenergie und Kernkraftwerke',
        sublabel: '',
        tooltip: '',
      },
      chlorinatedHydrocarbon: {
        label: 'Chlorkohlenwasserstoff',
        sublabel: '',
        tooltip: '',
      },
      embryoResearch: {
        label: 'Embryonenforschung',
        sublabel: '',
        tooltip: '',
      },
      violentVideoGames: {
        label: 'Gewalttätige Videospiele',
        sublabel: '',
        tooltip: '',
      },
      gambling: {
        label: 'Glücksspiel',
        sublabel: '',
        tooltip: '',
      },
      greenGeneticEngineering: {
        label: 'Grüne Gentechnik',
        sublabel: '',
        tooltip: '',
      },
      furs: {
        label: 'Pelze',
        sublabel: '',
        tooltip: '',
      },
      ozone_DepletingSubstances: {
        label: 'Ozon abbauende Stoffe',
        sublabel: '',
        tooltip: '',
      },
      pesticides_Herbicides: {
        label: 'Pestizide/Herbizide',
        sublabel: '',
        tooltip: '',
      },
      pornography: {
        label: 'Pornografie',
        sublabel: '',
        tooltip: '',
      },
      militaryArmamentWeaponsAndMunition: {
        label: 'Militärische Rüstung, Waffen und Munition',
        sublabel: '',
        tooltip: '',
      },
      tobacco: {
        label: 'Tabak',
        sublabel: '',
        tooltip: '',
      },
      unbondedAsbestosFibers: {
        label: 'Nicht gebundene Asbestfasern',
        sublabel: '',
        tooltip: '',
      },
      noneOfTheAbove_v2: {
        label: 'Keiner der oben genannten Punkte',
        sublabel: '',
        tooltip: '',
      },
      thereAreRevenuesFromFossilFuels: {
        label: 'Es gibt Einnahmen aus fossilen Brennstoffen',
        sublabel: '',
        tooltip: '',
      },
      noThereAreNoRevenuesFromFossilFuels: {
        label: 'Nein, es gibt keine Einnahmen aus fossilen Brennstoffen',
        sublabel: '',
        tooltip: '',
      },
      thereHaveBeenPublicComplaintsOrLegalActionsInThe: {
        label:
          'In den letzten 3 Jahren gab es öffentliche Beschwerden oder rechtliche Schritte',
        sublabel: '',
        tooltip: '',
      },
      noThereHaveBeenNoPublicComplaintsOrLegalActions: {
        label:
          'Nein, in den letzten 3 Jahren gab es keine öffentlichen Beschwerden oder rechtliche Schritte',
        sublabel: '',
        tooltip: '',
      },
      n5001_10000: {
        label: '5001-10000',
        sublabel: '',
        tooltip: '',
      },
      yesWeAdhereToTheOecdGuidelinesForMultinationalEnterprises: {
        label:
          'Ja, wir halten uns an die OECD-Leitsätze für multinationale Unternehmen.',
        sublabel: '',
        tooltip: '',
      },
      noWeDontAdhereToTheOecdGuidelinesForMultinational: {
        label:
          'Nein, wir halten uns nicht an die OECD-Leitsätze für multinationale Unternehmen.',
        sublabel: '',
        tooltip: '',
      },
      weDontWantToReportOnThis: {
        label: 'Darüber wollen wir nicht berichten.',
        sublabel: '',
        tooltip: '',
      },
      yesWeAdhereToTheGuidingPrinciplesOnBusinessAnd: {
        label:
          'Ja, wir halten uns an die Leitprinzipien für Wirtschaft und Menschenrechte, einschließlich der Grundsätze und Rechte, die in den acht grundlegenden IAO-Übereinkommen festgelegt sind',
        sublabel: '',
        tooltip: '',
      },
      noWeDontAdhereToTheGuidingPrinciplesOnBusiness: {
        label:
          'Nein, wir halten uns nicht an die Leitprinzipien für Wirtschaft und Menschenrechte, einschließlich der Grundsätze und Rechte, die in den acht grundlegenden IAO-Übereinkommen festgelegt sind',
        sublabel: '',
        tooltip: '',
      },
      weDontWantToReportOnThis_v2: {
        label: 'Darüber wollen wir nicht berichten.',
        sublabel: '',
        tooltip: '',
      },
      other_v7: {
        label: 'Sonstiges',
        sublabel: '',
        tooltip: '',
      },
      defenseAndSpaceManufacturing: {
        label: 'Verteidigungs- und Raumfahrtindustrie',
        sublabel: '',
        tooltip: '',
      },
      computerHardwareManufacturing: {
        label: 'Herstellung von Computer-Hardware',
        sublabel: '',
        tooltip: '',
      },
      softwareDevelopment: {
        label: 'Software-Entwicklung',
        sublabel: '',
        tooltip: '',
      },
      computerNetworkingProducts: {
        label: 'Computervernetzungsprodukte',
        sublabel: '',
        tooltip: '',
      },
      technologyInformationAndInternet: {
        label: 'Technologie, Information und Internet',
        sublabel: '',
        tooltip: '',
      },
      semiconductorManufacturing: {
        label: 'Herstellung von Halbleitern',
        sublabel: '',
        tooltip: '',
      },
      telecommunications: {
        label: 'Telekommunikation',
        sublabel: '',
        tooltip: '',
      },
      lawPractice: {
        label: 'Anwaltskanzlei',
        sublabel: '',
        tooltip: '',
      },
      legalServices: {
        label: 'Juristische Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      businessConsultingAndServices: {
        label: 'Unternehmensberatung und Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      biotechnologyResearch: {
        label: 'Biotechnologische Forschung',
        sublabel: '',
        tooltip: '',
      },
      medicalPractices: {
        label: 'Medizinische Praktiken',
        sublabel: '',
        tooltip: '',
      },
      hospitalsAndHealthCare: {
        label: 'Krankenhäuser und Gesundheitsversorgung',
        sublabel: '',
        tooltip: '',
      },
      pharmaceuticalManufacturing: {
        label: 'Pharmazeutische Herstellung',
        sublabel: '',
        tooltip: '',
      },
      veterinaryServices: {
        label: 'Tierärztliche Dienste',
        sublabel: '',
        tooltip: '',
      },
      medicalEquipmentManufacturing: {
        label: 'Herstellung medizinischer Geräte',
        sublabel: '',
        tooltip: '',
      },
      personalCareProductManufacturing: {
        label: 'Herstellung von Körperpflegeprodukten',
        sublabel: '',
        tooltip: '',
      },
      retailApparelAndFashion: {
        label: 'Einzelhandel mit Bekleidung und Mode',
        sublabel: '',
        tooltip: '',
      },
      sportingGoodsManufacturing: {
        label: 'Herstellung von Sportartikeln',
        sublabel: '',
        tooltip: '',
      },
      tobaccoManufacturing: {
        label: 'Herstellung von Tabakwaren',
        sublabel: '',
        tooltip: '',
      },
      retailGroceries: {
        label: 'Lebensmitteleinzelhandel',
        sublabel: '',
        tooltip: '',
      },
      foodAndBeverageManufacturing: {
        label: 'Lebensmittel- und Getränkeherstellung',
        sublabel: '',
        tooltip: '',
      },
      computersAndElectronicsManufacturing: {
        label: 'Computer und Elektronikherstellung',
        sublabel: '',
        tooltip: '',
      },
      manufacturing_v3: {
        label: 'Herstellung',
        sublabel: '',
        tooltip: '',
      },
      furnitureAndHomeFurnishingsManufacturing: {
        label: 'Herstellung von Möbeln und Einrichtungsgegenständen',
        sublabel: '',
        tooltip: '',
      },
      retail: {
        label: 'Einzelhandel',
        sublabel: '',
        tooltip: '',
      },
      entertainmentProviders: {
        label: 'Anbieter von Unterhaltung',
        sublabel: '',
        tooltip: '',
      },
      gamblingFacilitiesAndCasinos: {
        label: 'Glücksspieleinrichtungen und Kasinos',
        sublabel: '',
        tooltip: '',
      },
      travelArrangements: {
        label: 'Reisearrangements',
        sublabel: '',
        tooltip: '',
      },
      hospitality: {
        label: 'Gastfreundschaft',
        sublabel: '',
        tooltip: '',
      },
      restaurants: {
        label: 'Restaurants',
        sublabel: '',
        tooltip: '',
      },
      spectatorSports: {
        label: 'Zuschauersport',
        sublabel: '',
        tooltip: '',
      },
      foodAndBeverageServices: {
        label: 'Lebensmittel- und Getränkeservice',
        sublabel: '',
        tooltip: '',
      },
      moviesVideosAndSound: {
        label: 'Filme, Videos und Ton',
        sublabel: '',
        tooltip: '',
      },
      broadcastMediaProductionAndDistribution: {
        label: 'Produktion und Vertrieb von Rundfunkmedien',
        sublabel: '',
        tooltip: '',
      },
      museumsHistoricalSitesAndZoos: {
        label: 'Museen, historische Stätten und Zoos',
        sublabel: '',
        tooltip: '',
      },
      artistsAndWriters: {
        label: 'Künstler und Schriftsteller',
        sublabel: '',
        tooltip: '',
      },
      performingArts: {
        label: 'Darstellende Künste',
        sublabel: '',
        tooltip: '',
      },
      recreationalFacilities: {
        label: 'Freizeiteinrichtungen',
        sublabel: '',
        tooltip: '',
      },
      banking_v2: {
        label: 'Bankwesen',
        sublabel: '',
        tooltip: '',
      },
      insurance_v2: {
        label: 'Versicherung',
        sublabel: '',
        tooltip: '',
      },
      financialServices: {
        label: 'Finanzdienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      leasingRealEstate: {
        label: 'Vermietung von Immobilien',
        sublabel: '',
        tooltip: '',
      },
      investmentBanking: {
        label: 'Investmentbanking',
        sublabel: '',
        tooltip: '',
      },
      investmentManagement: {
        label: 'Investitionsmanagement',
        sublabel: '',
        tooltip: '',
      },
      accounting: {
        label: 'Buchhaltung',
        sublabel: '',
        tooltip: '',
      },
      construction_v2: {
        label: 'Bauwesen',
        sublabel: '',
        tooltip: '',
      },
      wholesaleBuildingMaterials: {
        label: 'Großhandel mit Baumaterialien',
        sublabel: '',
        tooltip: '',
      },
      architectureAndPlanning: {
        label: 'Architektur und Planung',
        sublabel: '',
        tooltip: '',
      },
      civilEngineering: {
        label: 'Bauingenieurwesen',
        sublabel: '',
        tooltip: '',
      },
      aviationAndAerospaceComponentManufacturing: {
        label: 'Herstellung von Komponenten für die Luft- und Raumfahrt',
        sublabel: '',
        tooltip: '',
      },
      motorVehicleManufacturing: {
        label: 'Kraftfahrzeugbau',
        sublabel: '',
        tooltip: '',
      },
      chemicalManufacturing: {
        label: 'Chemische Produktion',
        sublabel: '',
        tooltip: '',
      },
      machineryManufacturing: {
        label: 'Herstellung von Maschinen',
        sublabel: '',
        tooltip: '',
      },
      mining: {
        label: 'Bergbau',
        sublabel: '',
        tooltip: '',
      },
      oilAndGas: {
        label: 'Öl und Gas',
        sublabel: '',
        tooltip: '',
      },
      shipbuilding: {
        label: 'Schiffbau',
        sublabel: '',
        tooltip: '',
      },
      utilities: {
        label: 'Versorgungsunternehmen',
        sublabel: '',
        tooltip: '',
      },
      textileManufacturing: {
        label: 'Textilherstellung',
        sublabel: '',
        tooltip: '',
      },
      paperAndForestProductManufacturing: {
        label: 'Papier- und Forstprodukteherstellung',
        sublabel: '',
        tooltip: '',
      },
      railroadEquipmentManufacturing: {
        label: 'Herstellung von Eisenbahnausrüstungen',
        sublabel: '',
        tooltip: '',
      },
      farming: {
        label: 'Landwirtschaft',
        sublabel: '',
        tooltip: '',
      },
      ranching: {
        label: 'Ranching',
        sublabel: '',
        tooltip: '',
      },
      dairyProductManufacturing: {
        label: 'Herstellung von Molkereiprodukten',
        sublabel: '',
        tooltip: '',
      },
      fisheries: {
        label: 'Fischerei',
        sublabel: '',
        tooltip: '',
      },
      primaryAndSecondaryEducation: {
        label: 'Primäre und sekundäre Bildung',
        sublabel: '',
        tooltip: '',
      },
      higherEducation: {
        label: 'Höhere Bildung',
        sublabel: '',
        tooltip: '',
      },
      educationAdministrationPrograms: {
        label: 'Programme für die Bildungsverwaltung',
        sublabel: '',
        tooltip: '',
      },
      researchServices: {
        label: 'Forschungsdienste',
        sublabel: '',
        tooltip: '',
      },
      armedForces: {
        label: 'Streitkräfte',
        sublabel: '',
        tooltip: '',
      },
      legislativeOffices: {
        label: 'Legislative Ämter',
        sublabel: '',
        tooltip: '',
      },
      administrationOfJustice: {
        label: 'Justizverwaltung',
        sublabel: '',
        tooltip: '',
      },
      internationalAffairs: {
        label: 'Internationale Angelegenheiten',
        sublabel: '',
        tooltip: '',
      },
      governmentAdministration: {
        label: 'Staatliche Verwaltung',
        sublabel: '',
        tooltip: '',
      },
      executiveOffices: {
        label: 'Exekutivbüros',
        sublabel: '',
        tooltip: '',
      },
      lawEnforcement: {
        label: 'Strafverfolgung',
        sublabel: '',
        tooltip: '',
      },
      publicSafety_v2: {
        label: 'Öffentliche Sicherheit',
        sublabel: '',
        tooltip: '',
      },
      publicPolicyOffices: {
        label: 'Ämter für öffentliche Ordnung',
        sublabel: '',
        tooltip: '',
      },
      marketingServices: {
        label: 'Marketing-Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      newspaperPublishing: {
        label: 'Zeitungsverlag',
        sublabel: '',
        tooltip: '',
      },
      bookAndPeriodicalPublishing: {
        label: 'Buch- und Zeitschriftenverlag',
        sublabel: '',
        tooltip: '',
      },
      printingServices: {
        label: 'Druckdienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      informationServices_v2: {
        label: 'Informationsdienste',
        sublabel: '',
        tooltip: '',
      },
      libraries: {
        label: 'Bibliotheken',
        sublabel: '',
        tooltip: '',
      },
      environmentalServices: {
        label: 'Umweltdienste',
        sublabel: '',
        tooltip: '',
      },
      freightAndPackageTransportation: {
        label: 'Fracht- und Pakettransport',
        sublabel: '',
        tooltip: '',
      },
      individualAndFamilyServices: {
        label: 'Dienstleistungen für Einzelpersonen und Familien',
        sublabel: '',
        tooltip: '',
      },
      religiousInstitutions: {
        label: 'Religiöse Einrichtungen',
        sublabel: '',
        tooltip: '',
      },
      civicAndSocialOrganizations: {
        label: 'Zivilgesellschaftliche und soziale Organisationen',
        sublabel: '',
        tooltip: '',
      },
      consumerServices: {
        label: 'Verbraucherdienste',
        sublabel: '',
        tooltip: '',
      },
      truckTransportation: {
        label: 'Lkw-Transport',
        sublabel: '',
        tooltip: '',
      },
      warehousingAndStorage: {
        label: 'Lagerhaltung und Lagerung',
        sublabel: '',
        tooltip: '',
      },
      airlinesAndAviation: {
        label: 'Fluggesellschaften und Luftfahrt',
        sublabel: '',
        tooltip: '',
      },
      maritimeTransportation: {
        label: 'Seeverkehr',
        sublabel: '',
        tooltip: '',
      },
      itServicesAndItConsulting: {
        label: 'IT-Dienstleistungen und IT-Beratung',
        sublabel: '',
        tooltip: '',
      },
      marketResearch: {
        label: 'Marktforschung',
        sublabel: '',
        tooltip: '',
      },
      publicRelationsAndCommunicationsServices: {
        label: 'Öffentlichkeitsarbeit und Kommunikationsdienste',
        sublabel: '',
        tooltip: '',
      },
      designServices: {
        label: 'Design Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      non_ProfitOrganizations: {
        label: 'Non-Profit-Organisationen',
        sublabel: '',
        tooltip: '',
      },
      fundraising: {
        label: 'Fundraising',
        sublabel: '',
        tooltip: '',
      },
      strategicManagementServices: {
        label: 'Strategische Managementdienste',
        sublabel: '',
        tooltip: '',
      },
      writingAndEditing: {
        label: 'Schreiben und Redigieren',
        sublabel: '',
        tooltip: '',
      },
      staffingAndRecruiting: {
        label: 'Personalbeschaffung und Rekrutierung',
        sublabel: '',
        tooltip: '',
      },
      businessSkillsTraining: {
        label: 'Business Skills Training',
        sublabel: '',
        tooltip: '',
      },
      ventureCapitalAndPrivateEquityPrincipals: {
        label: 'Risikokapital- und Private-Equity-Investoren',
        sublabel: '',
        tooltip: '',
      },
      politicalOrganizations: {
        label: 'Politische Organisationen',
        sublabel: '',
        tooltip: '',
      },
      translationAndLocalization: {
        label: 'Übersetzung und Lokalisierung',
        sublabel: '',
        tooltip: '',
      },
      computerGames: {
        label: 'Computerspiele',
        sublabel: '',
        tooltip: '',
      },
      eventsServices: {
        label: 'Dienstleistungen für Veranstaltungen',
        sublabel: '',
        tooltip: '',
      },
      retailArtSupplies: {
        label: 'Einzelhandel mit Kunstzubehör',
        sublabel: '',
        tooltip: '',
      },
      appliancesElectricalAndElectronicsManufacturing: {
        label: 'Herstellung von Haushaltsgeräten, Elektrik und Elektronik',
        sublabel: '',
        tooltip: '',
      },
      onlineAudioAndVideoMedia: {
        label: 'Online Audio- und Videomedien',
        sublabel: '',
        tooltip: '',
      },
      nanotechnologyResearch: {
        label: 'Nanotechnologie-Forschung',
        sublabel: '',
        tooltip: '',
      },
      musicians: {
        label: 'Musikanten',
        sublabel: '',
        tooltip: '',
      },
      transportationLogisticsSupplyChainAndStorage: {
        label: 'Transport, Logistik, Lieferkette und Lagerung',
        sublabel: '',
        tooltip: '',
      },
      plasticsManufacturing: {
        label: 'Herstellung von Kunststoffen',
        sublabel: '',
        tooltip: '',
      },
      computerAndNetworkSecurity: {
        label: 'Computer- und Netzsicherheit',
        sublabel: '',
        tooltip: '',
      },
      wirelessServices: {
        label: 'Drahtlose Dienste',
        sublabel: '',
        tooltip: '',
      },
      alternativeDisputeResolution: {
        label: 'Alternative Streitbeilegung',
        sublabel: '',
        tooltip: '',
      },
      securityAndInvestigations: {
        label: 'Sicherheit und Ermittlungen',
        sublabel: '',
        tooltip: '',
      },
      facilitiesServices: {
        label: 'Dienste für Einrichtungen',
        sublabel: '',
        tooltip: '',
      },
      outsourcingAndOffshoringConsulting: {
        label: 'Outsourcing und Offshoring Beratung',
        sublabel: '',
        tooltip: '',
      },
      wellnessAndFitnessServices: {
        label: 'Wellness- und Fitnessdienste',
        sublabel: '',
        tooltip: '',
      },
      alternativeMedicine: {
        label: 'Alternative Medizin',
        sublabel: '',
        tooltip: '',
      },
      mediaProduction: {
        label: 'Medienproduktion',
        sublabel: '',
        tooltip: '',
      },
      animationAndPost_Production: {
        label: 'Animation und Postproduktion',
        sublabel: '',
        tooltip: '',
      },
      leasingNon_ResidentialRealEstate: {
        label: 'Vermietung von Nicht-Wohnimmobilien',
        sublabel: '',
        tooltip: '',
      },
      capitalMarkets: {
        label: 'Kapitalmärkte',
        sublabel: '',
        tooltip: '',
      },
      thinkTanks: {
        label: 'Denkfabriken',
        sublabel: '',
        tooltip: '',
      },
      philanthropicFundraisingServices: {
        label: 'Philanthropische Fundraising-Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      e_LearningProviders: {
        label: 'E-Learning-Anbieter',
        sublabel: '',
        tooltip: '',
      },
      wholesale: {
        label: 'Großhandel',
        sublabel: '',
        tooltip: '',
      },
      wholesaleImportAndExport: {
        label: 'Großhandel Import und Export',
        sublabel: '',
        tooltip: '',
      },
      industrialMachineryManufacturing: {
        label: 'Industriemaschinenbau',
        sublabel: '',
        tooltip: '',
      },
      photography: {
        label: 'Fotografie',
        sublabel: '',
        tooltip: '',
      },
      humanResourcesServices: {
        label: 'Human Resources Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      retailOfficeEquipment: {
        label: 'Einzelhandel Büroausstattung',
        sublabel: '',
        tooltip: '',
      },
      mentalHealthCare: {
        label: 'Psychische Gesundheitsfürsorge',
        sublabel: '',
        tooltip: '',
      },
      graphicDesign: {
        label: 'Grafisches Design',
        sublabel: '',
        tooltip: '',
      },
      internationalTradeAndDevelopment: {
        label: 'Internationaler Handel und Entwicklung',
        sublabel: '',
        tooltip: '',
      },
      beverageManufacturing: {
        label: 'Getränkeherstellung',
        sublabel: '',
        tooltip: '',
      },
      retailLuxuryGoodsAndJewelry: {
        label: 'Einzelhandel mit Luxusgütern und Juwelen',
        sublabel: '',
        tooltip: '',
      },
      renewableEnergySemiconductorManufacturing: {
        label: 'Erneuerbare Energien Halbleiterherstellung',
        sublabel: '',
        tooltip: '',
      },
      glassCeramicsAndConcreteManufacturing: {
        label: 'Glas-, Keramik- und Betonherstellung',
        sublabel: '',
        tooltip: '',
      },
      packagingAndContainersManufacturing: {
        label: 'Herstellung von Verpackungen und Behältern',
        sublabel: '',
        tooltip: '',
      },
      automationMachineryManufacturing: {
        label: 'Automatisierungstechnik im Maschinenbau',
        sublabel: '',
        tooltip: '',
      },
      governmentRelationsServices: {
        label: 'Dienstleistungen für Regierungsbeziehungen',
        sublabel: '',
        tooltip: '',
      },
      horticulture: {
        label: 'Gartenbau',
        sublabel: '',
        tooltip: '',
      },
      farmingRanchingForestry: {
        label: 'Landwirtschaft, Viehzucht, Forstwirtschaft',
        sublabel: '',
        tooltip: '',
      },
      ranchingAndFisheries: {
        label: 'Ranching und Fischerei',
        sublabel: '',
        tooltip: '',
      },
      forestryAndLogging: {
        label: 'Forstwirtschaft und Holzeinschlag',
        sublabel: '',
        tooltip: '',
      },
      oilGasAndMining: {
        label: 'Öl, Gas und Bergbau',
        sublabel: '',
        tooltip: '',
      },
      coalMining: {
        label: 'Steinkohlebergbau',
        sublabel: '',
        tooltip: '',
      },
      metalOreMining: {
        label: 'Metall-Erzbergbau',
        sublabel: '',
        tooltip: '',
      },
      nonmetallicMineralMining: {
        label: 'Bergbau auf nichtmetallische Mineralien',
        sublabel: '',
        tooltip: '',
      },
      electricPowerTransmissionControlAndDistribution: {
        label: 'Elektrische Energieübertragung, -steuerung und -verteilung',
        sublabel: '',
        tooltip: '',
      },
      electricPowerGeneration: {
        label: 'Elektrische Energieerzeugung',
        sublabel: '',
        tooltip: '',
      },
      hydroelectricPowerGeneration: {
        label: 'Stromerzeugung aus Wasserkraft',
        sublabel: '',
        tooltip: '',
      },
      fossilFuelElectricPowerGeneration: {
        label: 'Stromerzeugung aus fossilen Brennstoffen',
        sublabel: '',
        tooltip: '',
      },
      nuclearElectricPowerGeneration: {
        label: 'Elektrizitätserzeugung aus Kernenergie',
        sublabel: '',
        tooltip: '',
      },
      solarElectricPowerGeneration: {
        label: 'Stromerzeugung durch Solarenergie',
        sublabel: '',
        tooltip: '',
      },
      environmentalQualityPrograms: {
        label: 'Programme zur Umweltqualität',
        sublabel: '',
        tooltip: '',
      },
      geothermalElectricPowerGeneration: {
        label: 'Geothermische Stromerzeugung',
        sublabel: '',
        tooltip: '',
      },
      biomassElectricPowerGeneration: {
        label: 'Stromerzeugung aus Biomasse',
        sublabel: '',
        tooltip: '',
      },
      naturalGasDistribution: {
        label: 'Erdgasverteilung',
        sublabel: '',
        tooltip: '',
      },
      waterWasteSteamAndAirConditioningServices: {
        label: 'Wasser-, Abwasser-, Dampf- und Klimatisierungsdienste',
        sublabel: '',
        tooltip: '',
      },
      waterSupplyAndIrrigationSystems: {
        label: 'Wasserversorgung und Bewässerungssysteme',
        sublabel: '',
        tooltip: '',
      },
      steamAndAir_ConditioningSupply: {
        label: 'Dampf- und Klimaversorgung',
        sublabel: '',
        tooltip: '',
      },
      buildingConstruction: {
        label: 'Bauwesen',
        sublabel: '',
        tooltip: '',
      },
      residentialBuildingConstruction: {
        label: 'Wohnhausbau',
        sublabel: '',
        tooltip: '',
      },
      nonresidentialBuildingConstruction: {
        label: 'Nichtwohnungsbau',
        sublabel: '',
        tooltip: '',
      },
      utilitySystemConstruction: {
        label: 'Konstruktion von Versorgungssystemen',
        sublabel: '',
        tooltip: '',
      },
      subdivisionOfLand: {
        label: 'Unterteilung von Grundstücken',
        sublabel: '',
        tooltip: '',
      },
      highwayStreetAndBridgeConstruction: {
        label: 'Autobahn-, Straßen- und Brückenbau',
        sublabel: '',
        tooltip: '',
      },
      specialtyTradeContractors: {
        label: 'Spezialisierte Handwerksbetriebe',
        sublabel: '',
        tooltip: '',
      },
      buildingStructureAndExteriorContractors: {
        label: 'Gebäudestruktur und Außenanlagen Bauunternehmer',
        sublabel: '',
        tooltip: '',
      },
      buildingEquipmentContractors: {
        label: 'Baugerätehersteller',
        sublabel: '',
        tooltip: '',
      },
      buildingFinishingContractors: {
        label: 'Bauausführende Unternehmen',
        sublabel: '',
        tooltip: '',
      },
      animalFeedManufacturing: {
        label: 'Futtermittelherstellung',
        sublabel: '',
        tooltip: '',
      },
      sugarAndConfectioneryProductManufacturing: {
        label: 'Herstellung von Zucker- und Süßwarenprodukten',
        sublabel: '',
        tooltip: '',
      },
      fruitAndVegetablePreservesManufacturing: {
        label: 'Herstellung von Obst- und Gemüsekonserven',
        sublabel: '',
        tooltip: '',
      },
      meatProductsManufacturing: {
        label: 'Herstellung von Fleischerzeugnissen',
        sublabel: '',
        tooltip: '',
      },
      seafoodProductManufacturing: {
        label: 'Herstellung von Fischereierzeugnissen',
        sublabel: '',
        tooltip: '',
      },
      bakedGoodsManufacturing: {
        label: 'Herstellung von Backwaren',
        sublabel: '',
        tooltip: '',
      },
      breweries: {
        label: 'Brauereien',
        sublabel: '',
        tooltip: '',
      },
      distilleries: {
        label: 'Destillerien',
        sublabel: '',
        tooltip: '',
      },
      apparelManufacturing: {
        label: 'Bekleidungsherstellung',
        sublabel: '',
        tooltip: '',
      },
      fashionAccessoriesManufacturing: {
        label: 'Herstellung von Modeaccessoires',
        sublabel: '',
        tooltip: '',
      },
      leatherProductManufacturing: {
        label: 'Herstellung von Lederprodukten',
        sublabel: '',
        tooltip: '',
      },
      footwearManufacturing: {
        label: 'Herstellung von Schuhen',
        sublabel: '',
        tooltip: '',
      },
      womensHandbagManufacturing: {
        label: 'Herstellung von Damenhandtaschen',
        sublabel: '',
        tooltip: '',
      },
      oilAndCoalProductManufacturing: {
        label: 'Herstellung von Öl- und Kohleprodukten',
        sublabel: '',
        tooltip: '',
      },
      chemicalRawMaterialsManufacturing: {
        label: 'Herstellung von chemischen Rohstoffen',
        sublabel: '',
        tooltip: '',
      },
      artificialRubberAndSyntheticFiberManufacturing: {
        label: 'Herstellung von Kunstkautschuk und synthetischen Fasern',
        sublabel: '',
        tooltip: '',
      },
      agriculturalChemicalManufacturing: {
        label: 'Landwirtschaftliche chemische Produktion',
        sublabel: '',
        tooltip: '',
      },
      paintCoatingAndAdhesiveManufacturing: {
        label: 'Herstellung von Farben, Beschichtungen und Klebstoffen',
        sublabel: '',
        tooltip: '',
      },
      soapAndCleaningProductManufacturing: {
        label: 'Seifen- und Reinigungsmittelherstellung',
        sublabel: '',
        tooltip: '',
      },
      plasticsAndRubberProductManufacturing: {
        label: 'Herstellung von Kunststoff- und Gummiprodukten',
        sublabel: '',
        tooltip: '',
      },
      rubberProductsManufacturing: {
        label: 'Herstellung von Gummiprodukten',
        sublabel: '',
        tooltip: '',
      },
      clayAndRefractoryProductsManufacturing: {
        label: 'Herstellung von Ton und feuerfesten Produkten',
        sublabel: '',
        tooltip: '',
      },
      glassProductManufacturing: {
        label: 'Herstellung von Glasprodukten',
        sublabel: '',
        tooltip: '',
      },
      woodProductManufacturing: {
        label: 'Herstellung von Holzprodukten',
        sublabel: '',
        tooltip: '',
      },
      limeAndGypsumProductsManufacturing: {
        label: 'Herstellung von Kalk- und Gipserzeugnissen',
        sublabel: '',
        tooltip: '',
      },
      abrasivesAndNonmetallicMineralsManufacturing: {
        label:
          'Herstellung von Schleifmitteln und nichtmetallischen Mineralien',
        sublabel: '',
        tooltip: '',
      },
      primaryMetalManufacturing: {
        label: 'Primärmetallverarbeitung',
        sublabel: '',
        tooltip: '',
      },
      fabricatedMetalProducts: {
        label: 'Metallerzeugnisse',
        sublabel: '',
        tooltip: '',
      },
      cutleryAndHandtoolManufacturing: {
        label: 'Herstellung von Schneidwaren und Handwerkzeugen',
        sublabel: '',
        tooltip: '',
      },
      architecturalAndStructuralMetalManufacturing: {
        label: 'Architektonische und strukturelle Metallverarbeitung',
        sublabel: '',
        tooltip: '',
      },
      boilersTanksAndShippingContainerManufacturing: {
        label: 'Herstellung von Kesseln, Tanks und Schiffscontainern',
        sublabel: '',
        tooltip: '',
      },
      constructionHardwareManufacturing: {
        label: 'Herstellung von Baubeschlägen',
        sublabel: '',
        tooltip: '',
      },
      springAndWireProductManufacturing: {
        label: 'Herstellung von Federn und Drahtprodukten',
        sublabel: '',
        tooltip: '',
      },
      turnedProductsAndFastenerManufacturing: {
        label: 'Drehprodukte und Herstellung von Verbindungselementen',
        sublabel: '',
        tooltip: '',
      },
      metalTreatments: {
        label: 'Metall-Behandlungen',
        sublabel: '',
        tooltip: '',
      },
      metalValveBallAndRollerManufacturing: {
        label: 'Herstellung von Ventilen, Kugeln und Rollen aus Metall',
        sublabel: '',
        tooltip: '',
      },
      agricultureConstructionMiningMachineryManufacturing: {
        label: 'Landwirtschaft, Bauwesen, Bergbaumaschinenbau',
        sublabel: '',
        tooltip: '',
      },
      commercialAndServiceIndustryMachineryManufacturing: {
        label: 'Handel und Dienstleistungen Maschinen- und Anlagenbau',
        sublabel: '',
        tooltip: '',
      },
      hvacAndRefrigerationEquipmentManufacturing: {
        label: 'Herstellung von HVAC- und Kältetechnikgeräten',
        sublabel: '',
        tooltip: '',
      },
      metalworkingMachineryManufacturing: {
        label: 'Herstellung von Maschinen für die Metallbearbeitung',
        sublabel: '',
        tooltip: '',
      },
      enginesAndPowerTransmissionEquipmentManufacturing: {
        label: 'Herstellung von Motoren und Kraftübertragungsanlagen',
        sublabel: '',
        tooltip: '',
      },
      communicationsEquipmentManufacturing: {
        label: 'Herstellung von Kommunikationsausrüstung',
        sublabel: '',
        tooltip: '',
      },
      audioAndVideoEquipmentManufacturing: {
        label: 'Herstellung von Audio- und Videogeräten',
        sublabel: '',
        tooltip: '',
      },
      measuringAndControlInstrumentManufacturing: {
        label: 'Herstellung von Mess- und Kontrollinstrumenten',
        sublabel: '',
        tooltip: '',
      },
      magneticAndOpticalMediaManufacturing: {
        label: 'Herstellung magnetischer und optischer Medien',
        sublabel: '',
        tooltip: '',
      },
      electricLightingEquipmentManufacturing: {
        label: 'Herstellung elektrischer Beleuchtungsanlagen',
        sublabel: '',
        tooltip: '',
      },
      householdApplianceManufacturing: {
        label: 'Herstellung von Haushaltsgeräten',
        sublabel: '',
        tooltip: '',
      },
      transportationEquipmentManufacturing: {
        label: 'Herstellung von Transportausrüstung',
        sublabel: '',
        tooltip: '',
      },
      motorVehiclePartsManufacturing: {
        label: 'Herstellung von Kraftfahrzeugteilen',
        sublabel: '',
        tooltip: '',
      },
      householdAndInstitutionalFurnitureManufacturing: {
        label:
          'Herstellung von Möbeln für Haushalte und öffentliche Einrichtungen',
        sublabel: '',
        tooltip: '',
      },
      officeFurnitureAndFixturesManufacturing: {
        label: 'Herstellung von Büromöbeln und Einrichtungsgegenständen',
        sublabel: '',
        tooltip: '',
      },
      mattressAndBlindsManufacturing: {
        label: 'Herstellung von Matratzen und Jalousien',
        sublabel: '',
        tooltip: '',
      },
      wholesaleMotorVehiclesAndParts: {
        label: 'Großhandel mit Kraftfahrzeugen und Teilen',
        sublabel: '',
        tooltip: '',
      },
      wholesaleFurnitureAndHomeFurnishings: {
        label: 'Großhandel mit Möbeln und Einrichtungsgegenständen',
        sublabel: '',
        tooltip: '',
      },
      wholesalePhotographyEquipmentAndSupplies: {
        label: 'Großhandel für Fotografie-Ausrüstung und -Zubehör',
        sublabel: '',
        tooltip: '',
      },
      wholesaleComputerEquipment: {
        label: 'Großhandel für Computerausrüstung',
        sublabel: '',
        tooltip: '',
      },
      wholesaleMetalsAndMinerals: {
        label: 'Großhandel mit Metallen und Mineralien',
        sublabel: '',
        tooltip: '',
      },
      wholesaleAppliancesElectricalAndElectronics: {
        label: 'Großhandel für Haushaltsgeräte, Elektrogeräte und Elektronik',
        sublabel: '',
        tooltip: '',
      },
      wholesaleHardwarePlumbingHeatingEquipment: {
        label: 'Großhandel für Eisenwaren, Sanitär- und Heizungsinstallationen',
        sublabel: '',
        tooltip: '',
      },
      wholesaleMachinery: {
        label: 'Großhandel mit Maschinen',
        sublabel: '',
        tooltip: '',
      },
      wholesaleRecyclableMaterials: {
        label: 'Großhandel mit wiederverwertbaren Materialien',
        sublabel: '',
        tooltip: '',
      },
      wholesaleLuxuryGoodsAndJewelry: {
        label: 'Großhandel mit Luxusgütern und Juwelen',
        sublabel: '',
        tooltip: '',
      },
      wholesalePaperProducts: {
        label: 'Großhandel mit Papierprodukten',
        sublabel: '',
        tooltip: '',
      },
      wholesaleDrugsAndSundries: {
        label: 'Großhandel mit Arzneimitteln und Kurzwaren',
        sublabel: '',
        tooltip: '',
      },
      wholesaleApparelAndSewingSupplies: {
        label: 'Großhandel für Bekleidung und Nähzubehör',
        sublabel: '',
        tooltip: '',
      },
      wholesaleFootwear: {
        label: 'Großhandel für Schuhe',
        sublabel: '',
        tooltip: '',
      },
      wholesaleFoodAndBeverage: {
        label: 'Großhandel mit Lebensmitteln und Getränken',
        sublabel: '',
        tooltip: '',
      },
      wholesaleRawFarmProducts: {
        label: 'Großhandel für landwirtschaftliche Rohprodukte',
        sublabel: '',
        tooltip: '',
      },
      wholesaleChemicalAndAlliedProducts: {
        label: 'Großhandel mit chemischen und verwandten Produkten',
        sublabel: '',
        tooltip: '',
      },
      wholesalePetroleumAndPetroleumProducts: {
        label: 'Großhandel mit Erdöl und Erdölerzeugnissen',
        sublabel: '',
        tooltip: '',
      },
      wholesaleAlcoholicBeverages: {
        label: 'Großhandel mit alkoholischen Getränken',
        sublabel: '',
        tooltip: '',
      },
      internetMarketplacePlatforms: {
        label: 'Internet-Marktplatz-Plattformen',
        sublabel: '',
        tooltip: '',
      },
      retailMotorVehicles: {
        label: 'Einzelhandel Kraftfahrzeuge',
        sublabel: '',
        tooltip: '',
      },
      retailFurnitureAndHomeFurnishings: {
        label: 'Einzelhandel mit Möbeln und Einrichtungsgegenständen',
        sublabel: '',
        tooltip: '',
      },
      retailAppliancesElectricalAndElectronicEquipment: {
        label:
          'Einzelhandel mit Haushaltsgeräten, elektrischen und elektronischen Geräten',
        sublabel: '',
        tooltip: '',
      },
      retailBuildingMaterialsAndGardenEquipment: {
        label: 'Einzelhandel mit Baumaterialien und Gartengeräten',
        sublabel: '',
        tooltip: '',
      },
      foodAndBeverageRetail: {
        label: 'Lebensmittel- und Getränkehandel',
        sublabel: '',
        tooltip: '',
      },
      retailHealthAndPersonalCareProducts: {
        label: 'Einzelhandel mit Gesundheits- und Körperpflegeprodukten',
        sublabel: '',
        tooltip: '',
      },
      retailGasoline: {
        label: 'Einzelhandel Benzin',
        sublabel: '',
        tooltip: '',
      },
      retailMusicalInstruments: {
        label: 'Einzelhandel mit Musikinstrumenten',
        sublabel: '',
        tooltip: '',
      },
      retailBooksAndPrintedNews: {
        label: 'Einzelhandel mit Büchern und gedruckten Nachrichten',
        sublabel: '',
        tooltip: '',
      },
      retailFlorists: {
        label: 'Floristen im Einzelhandel',
        sublabel: '',
        tooltip: '',
      },
      retailOfficeSuppliesAndGifts: {
        label: 'Einzelhandel mit Büroartikeln und Geschenken',
        sublabel: '',
        tooltip: '',
      },
      retailRecyclableMaterialsUsedMerchandise: {
        label: 'Einzelhandel Wiederverwertbare Materialien & Gebrauchtwaren',
        sublabel: '',
        tooltip: '',
      },
      onlineAndMailOrderRetail: {
        label: 'Online- und Versandhandel',
        sublabel: '',
        tooltip: '',
      },
      railTransportation: {
        label: 'Schienenverkehr',
        sublabel: '',
        tooltip: '',
      },
      groundPassengerTransportation: {
        label: 'Personenbeförderung am Boden',
        sublabel: '',
        tooltip: '',
      },
      urbanTransitServices: {
        label: 'Städtische Transitdienste',
        sublabel: '',
        tooltip: '',
      },
      interurbanAndRuralBusServices: {
        label: 'Überörtliche und ländliche Busdienste',
        sublabel: '',
        tooltip: '',
      },
      taxiAndLimousineServices: {
        label: 'Taxi und Limousinenservice',
        sublabel: '',
        tooltip: '',
      },
      schoolAndEmployeeBusServices: {
        label: 'Schul- und Mitarbeiterbusverkehr',
        sublabel: '',
        tooltip: '',
      },
      shuttlesAndSpecialNeedsTransportationServices: {
        label: 'Shuttles und Spezialtransportdienste',
        sublabel: '',
        tooltip: '',
      },
      pipelineTransportation: {
        label: 'Pipeline-Transport',
        sublabel: '',
        tooltip: '',
      },
      sightseeingTransportation: {
        label: 'Sightseeing Transport',
        sublabel: '',
        tooltip: '',
      },
      postalServices: {
        label: 'Postdienste',
        sublabel: '',
        tooltip: '',
      },
      technologyInformationAndMedia: {
        label: 'Technologie, Information und Medien',
        sublabel: '',
        tooltip: '',
      },
      periodicalPublishing: {
        label: 'Veröffentlichung von Zeitschriften',
        sublabel: '',
        tooltip: '',
      },
      bookPublishing: {
        label: 'Buchveröffentlichung',
        sublabel: '',
        tooltip: '',
      },
      moviesAndSoundRecording: {
        label: 'Filme und Tonaufnahmen',
        sublabel: '',
        tooltip: '',
      },
      soundRecording: {
        label: 'Tonaufnahme',
        sublabel: '',
        tooltip: '',
      },
      sheetMusicPublishing: {
        label: 'Veröffentlichung von Musiknoten',
        sublabel: '',
        tooltip: '',
      },
      radioAndTelevisionBroadcasting: {
        label: 'Radio- und Fernsehsendungen',
        sublabel: '',
        tooltip: '',
      },
      cableAndSatelliteProgramming: {
        label: 'Kabel- und Satellitenprogramme',
        sublabel: '',
        tooltip: '',
      },
      telecommunicationsCarriers: {
        label: 'Telekommunikationsanbieter',
        sublabel: '',
        tooltip: '',
      },
      satelliteTelecommunications: {
        label: 'Satellitentelekommunikation',
        sublabel: '',
        tooltip: '',
      },
      creditIntermediation: {
        label: 'Kreditvermittlung',
        sublabel: '',
        tooltip: '',
      },
      savingsInstitutions: {
        label: 'Sparanstalten',
        sublabel: '',
        tooltip: '',
      },
      loanBrokers: {
        label: 'Darlehensvermittler',
        sublabel: '',
        tooltip: '',
      },
      securitiesAndCommodityExchanges: {
        label: 'Wertpapier- und Warenbörsen',
        sublabel: '',
        tooltip: '',
      },
      investmentAdvice: {
        label: 'Investitionsberatung',
        sublabel: '',
        tooltip: '',
      },
      insuranceCarriers: {
        label: 'Versicherungsträger',
        sublabel: '',
        tooltip: '',
      },
      insuranceAgenciesAndBrokerages: {
        label: 'Versicherungsagenturen und Maklerbüros',
        sublabel: '',
        tooltip: '',
      },
      claimsAdjustingActuarialServices: {
        label:
          'Schadenregulierung, Versicherungsmathematische Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      fundsAndTrusts: {
        label: 'Fonds und Treuhandgesellschaften',
        sublabel: '',
        tooltip: '',
      },
      insuranceAndEmployeeBenefitFunds: {
        label: 'Versicherungen und Sozialversicherungen',
        sublabel: '',
        tooltip: '',
      },
      pensionFunds: {
        label: 'Pensionsfonds',
        sublabel: '',
        tooltip: '',
      },
      trustsAndEstates: {
        label: 'Trusts und Nachlässe',
        sublabel: '',
        tooltip: '',
      },
      realEstateAndEquipmentRentalServices: {
        label: 'Immobilien- und Gerätevermietungsdienste',
        sublabel: '',
        tooltip: '',
      },
      leasingResidentialRealEstate: {
        label: 'Vermietung von Wohnimmobilien',
        sublabel: '',
        tooltip: '',
      },
      leasingRealEstateAgentsAndBrokers: {
        label: 'Vermietung von Immobilienmaklern und -vermittlern',
        sublabel: '',
        tooltip: '',
      },
      equipmentRentalServices: {
        label: 'Vermietung von Ausrüstung',
        sublabel: '',
        tooltip: '',
      },
      consumerGoodsRental: {
        label: 'Konsumgüterverleih',
        sublabel: '',
        tooltip: '',
      },
      commercialAndIndustrialEquipmentRental: {
        label: 'Vermietung von gewerblicher und industrieller Ausrüstung',
        sublabel: '',
        tooltip: '',
      },
      professionalServices: {
        label: 'Professionelle Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      itSystemDesignServices: {
        label: 'IT-Systementwurfsdienste',
        sublabel: '',
        tooltip: '',
      },
      advertisingServices: {
        label: 'Werbung Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      holdingCompanies: {
        label: 'Holdinggesellschaften',
        sublabel: '',
        tooltip: '',
      },
      industryAssociations: {
        label: 'Industrie-Verbände',
        sublabel: '',
        tooltip: '',
      },
      professionalOrganizations: {
        label: 'Professionelle Organisationen',
        sublabel: '',
        tooltip: '',
      },
      administrativeAndSupportServices: {
        label: 'Verwaltungs- und Unterstützungsdienste',
        sublabel: '',
        tooltip: '',
      },
      officeAdministration: {
        label: 'Büroverwaltung',
        sublabel: '',
        tooltip: '',
      },
      executiveSearchServices: {
        label: 'Executive Search Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      temporaryHelpServices: {
        label: 'Temporäre Hilfsdienste',
        sublabel: '',
        tooltip: '',
      },
      telephoneCallCenters: {
        label: 'Telefon-Callcenter',
        sublabel: '',
        tooltip: '',
      },
      collectionAgencies: {
        label: 'Inkassobüros',
        sublabel: '',
        tooltip: '',
      },
      securityGuardsAndPatrolServices: {
        label: 'Sicherheitspersonal und Streifendienste',
        sublabel: '',
        tooltip: '',
      },
      securitySystemsServices: {
        label: 'Sicherheitssysteme Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      janitorialServices: {
        label: 'Hausmeisterdienste',
        sublabel: '',
        tooltip: '',
      },
      wasteCollection: {
        label: 'Sammlung von Abfällen',
        sublabel: '',
        tooltip: '',
      },
      wasteTreatmentAndDisposal: {
        label: 'Abfallbehandlung und -entsorgung',
        sublabel: '',
        tooltip: '',
      },
      education_v2: {
        label: 'Bildung',
        sublabel: '',
        tooltip: '',
      },
      secretarialSchools: {
        label: 'Sekretariatsschulen',
        sublabel: '',
        tooltip: '',
      },
      technicalAndVocationalTraining: {
        label: 'Technische und berufliche Ausbildung',
        sublabel: '',
        tooltip: '',
      },
      cosmetologyAndBarberSchools: {
        label: 'Kosmetologie- und Friseurschulen',
        sublabel: '',
        tooltip: '',
      },
      flightTraining: {
        label: 'Flugausbildung',
        sublabel: '',
        tooltip: '',
      },
      fineArtsSchools: {
        label: 'Schulen für bildende Künste',
        sublabel: '',
        tooltip: '',
      },
      sportsAndRecreationInstruction: {
        label: 'Unterricht in Sport und Freizeit',
        sublabel: '',
        tooltip: '',
      },
      languageSchools: {
        label: 'Sprachschulen',
        sublabel: '',
        tooltip: '',
      },
      physicians: {
        label: 'Ärzte',
        sublabel: '',
        tooltip: '',
      },
      dentists: {
        label: 'Zahnärzte',
        sublabel: '',
        tooltip: '',
      },
      chiropractors: {
        label: 'Chiropraktiker',
        sublabel: '',
        tooltip: '',
      },
      optometrists: {
        label: 'Optometristen',
        sublabel: '',
        tooltip: '',
      },
      physicalOccupationalAndSpeechTherapists: {
        label: 'Physiotherapeuten, Ergotherapeuten und Logopäden',
        sublabel: '',
        tooltip: '',
      },
      familyPlanningCenters: {
        label: 'Zentren für Familienplanung',
        sublabel: '',
        tooltip: '',
      },
      outpatientCareCenters: {
        label: 'Ambulante Pflegezentren',
        sublabel: '',
        tooltip: '',
      },
      medicalAndDiagnosticLaboratories: {
        label: 'Medizinische und diagnostische Laboratorien',
        sublabel: '',
        tooltip: '',
      },
      homeHealthCareServices: {
        label: 'Häusliche Pflegedienste',
        sublabel: '',
        tooltip: '',
      },
      ambulanceServices: {
        label: 'Ambulante Dienste',
        sublabel: '',
        tooltip: '',
      },
      hospitals: {
        label: 'Krankenhäuser',
        sublabel: '',
        tooltip: '',
      },
      nursingHomesAndResidentialCareFacilities: {
        label: 'Pflegeheime und stationäre Pflegeeinrichtungen',
        sublabel: '',
        tooltip: '',
      },
      servicesForTheElderlyAndDisabled: {
        label: 'Dienstleistungen für ältere und behinderte Menschen',
        sublabel: '',
        tooltip: '',
      },
      communityServices: {
        label: 'Gemeinschaftsdienste',
        sublabel: '',
        tooltip: '',
      },
      emergencyAndReliefServices: {
        label: 'Notfall- und Hilfsdienste',
        sublabel: '',
        tooltip: '',
      },
      vocationalRehabilitationServices: {
        label: 'Berufliche Rehabilitationsdienste',
        sublabel: '',
        tooltip: '',
      },
      childDayCareServices: {
        label: 'Kinderbetreuungsdienste',
        sublabel: '',
        tooltip: '',
      },
      performingArtsAndSpectatorSports: {
        label: 'Darstellende Künste und Zuschauersport',
        sublabel: '',
        tooltip: '',
      },
      theaterCompanies: {
        label: 'Theatergesellschaften',
        sublabel: '',
        tooltip: '',
      },
      danceCompanies: {
        label: 'Tanzkompanien',
        sublabel: '',
        tooltip: '',
      },
      circusesAndMagicShows: {
        label: 'Zirkusse und Zaubershows',
        sublabel: '',
        tooltip: '',
      },
      sportsTeamsAndClubs: {
        label: 'Sportmannschaften und Vereine',
        sublabel: '',
        tooltip: '',
      },
      racetracks: {
        label: 'Pferderennbahnen',
        sublabel: '',
        tooltip: '',
      },
      museums: {
        label: 'Museen',
        sublabel: '',
        tooltip: '',
      },
      historicalSites: {
        label: 'Historische Stätten',
        sublabel: '',
        tooltip: '',
      },
      zoosAndBotanicalGardens: {
        label: 'Zoos und Botanische Gärten',
        sublabel: '',
        tooltip: '',
      },
      amusementParksAndArcades: {
        label: 'Vergnügungsparks und Spielhallen',
        sublabel: '',
        tooltip: '',
      },
      golfCoursesAndCountryClubs: {
        label: 'Golfplätze und Country Clubs',
        sublabel: '',
        tooltip: '',
      },
      skiingFacilities: {
        label: 'Skifahren Einrichtungen',
        sublabel: '',
        tooltip: '',
      },
      accomodationServices: {
        label: 'Unterkunftsdienste',
        sublabel: '',
        tooltip: '',
      },
      hotelsAndMotels: {
        label: 'Hotels und Motels',
        sublabel: '',
        tooltip: '',
      },
      bed_And_BreakfastsHostelsHomestays: {
        label: 'Bed-and-Breakfasts, Herbergen, Gasthöfe',
        sublabel: '',
        tooltip: '',
      },
      caterers: {
        label: 'Partyservice',
        sublabel: '',
        tooltip: '',
      },
      mobileFoodServices: {
        label: 'Mobile Lebensmitteldienste',
        sublabel: '',
        tooltip: '',
      },
      barsTavernsAndNightclubs: {
        label: 'Bars, Kneipen und Nachtclubs',
        sublabel: '',
        tooltip: '',
      },
      repairAndMaintenance: {
        label: 'Reparatur und Wartung',
        sublabel: '',
        tooltip: '',
      },
      vehicleRepairAndMaintenance: {
        label: 'Reparatur und Wartung von Fahrzeugen',
        sublabel: '',
        tooltip: '',
      },
      electronicAndPrecisionEquipmentMaintenance: {
        label: 'Wartung von elektronischen und Präzisionsgeräten',
        sublabel: '',
        tooltip: '',
      },
      commercialAndIndustrialMachineryMaintenance: {
        label: 'Gewerbliche und industrielle Maschinenwartung',
        sublabel: '',
        tooltip: '',
      },
      reupholsteryAndFurnitureRepair: {
        label: 'Neupolsterung und Möbelreparatur',
        sublabel: '',
        tooltip: '',
      },
      footwearAndLeatherGoodsRepair: {
        label: 'Reparatur von Schuhen und Lederwaren',
        sublabel: '',
        tooltip: '',
      },
      personalAndLaundryServices: {
        label: 'Persönliche Dienstleistungen und Wäscherei',
        sublabel: '',
        tooltip: '',
      },
      personalCareServices: {
        label: 'Persönliche Pflegeleistungen',
        sublabel: '',
        tooltip: '',
      },
      laundryAndDrycleaningServices: {
        label: 'Wäscherei und chemische Reinigung',
        sublabel: '',
        tooltip: '',
      },
      petServices: {
        label: 'Dienstleistungen für Haustiere',
        sublabel: '',
        tooltip: '',
      },
      householdServices: {
        label: 'Haushaltsnahe Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      healthAndHumanServices: {
        label: 'Gesundheits- und Sozialwesen',
        sublabel: '',
        tooltip: '',
      },
      publicHealth: {
        label: 'Öffentliche Gesundheit',
        sublabel: '',
        tooltip: '',
      },
      publicAssistancePrograms: {
        label: 'Öffentliche Hilfsprogramme',
        sublabel: '',
        tooltip: '',
      },
      airWaterAndWasteProgramManagement: {
        label: 'Programmverwaltung Luft, Wasser und Abfall',
        sublabel: '',
        tooltip: '',
      },
      conservationPrograms: {
        label: 'Programme zur Erhaltung der Natur',
        sublabel: '',
        tooltip: '',
      },
      housingAndCommunityDevelopment: {
        label: 'Wohnungswesen und Gemeindeentwicklung',
        sublabel: '',
        tooltip: '',
      },
      communityDevelopmentAndUrbanPlanning: {
        label: 'Gemeindeentwicklung und Stadtplanung',
        sublabel: '',
        tooltip: '',
      },
      economicPrograms: {
        label: 'Wirtschaftliche Programme',
        sublabel: '',
        tooltip: '',
      },
      militaryAndInternationalAffairs: {
        label: 'Militärische und internationale Angelegenheiten',
        sublabel: '',
        tooltip: '',
      },
      operationsConsulting: {
        label: 'Betriebsberatung',
        sublabel: '',
        tooltip: '',
      },
      dataInfrastructureAndAnalytics: {
        label: 'Dateninfrastruktur und Analytik',
        sublabel: '',
        tooltip: '',
      },
      electricalEquipmentManufacturing: {
        label: 'Herstellung elektrischer Ausrüstungen',
        sublabel: '',
        tooltip: '',
      },
      windElectricPowerGeneration: {
        label: 'Stromerzeugung aus Windkraft',
        sublabel: '',
        tooltip: '',
      },
      wineries: {
        label: 'Weinkellereien',
        sublabel: '',
        tooltip: '',
      },
      landscapingServices: {
        label: 'Landschaftsgärtnerische Dienstleistungen',
        sublabel: '',
        tooltip: '',
      },
      courtsOfLaw: {
        label: 'Gerichtshöfe',
        sublabel: '',
        tooltip: '',
      },
      correctionalInstitutions: {
        label: 'Justizvollzugsanstalten',
        sublabel: '',
        tooltip: '',
      },
      fireProtection: {
        label: 'Feuerschutz',
        sublabel: '',
        tooltip: '',
      },
      housingPrograms: {
        label: 'Gehäuse-Programme',
        sublabel: '',
        tooltip: '',
      },
      transportationPrograms: {
        label: 'Transport-Programme',
        sublabel: '',
        tooltip: '',
      },
      utilitiesAdministration: {
        label: 'Verwaltung der Versorgungsunternehmen',
        sublabel: '',
        tooltip: '',
      },
      spaceResearchAndTechnology: {
        label: 'Weltraumforschung und -technologie',
        sublabel: '',
        tooltip: '',
      },
      oilExtraction: {
        label: 'Ölgewinnung',
        sublabel: '',
        tooltip: '',
      },
      naturalGasExtraction: {
        label: 'Gewinnung von Erdgas',
        sublabel: '',
        tooltip: '',
      },
      embeddedSoftwareProducts: {
        label: 'Eingebettete Software-Produkte',
        sublabel: '',
        tooltip: '',
      },
      mobileComputingSoftwareProducts: {
        label: 'Mobile Computing Software-Produkte',
        sublabel: '',
        tooltip: '',
      },
      desktopComputingSoftwareProducts: {
        label: 'Desktop-Computing-Software-Produkte',
        sublabel: '',
        tooltip: '',
      },
      itSystemCustomSoftwareDevelopment: {
        label: 'IT-System Entwicklung kundenspezifischer Software',
        sublabel: '',
        tooltip: '',
      },
      itSystemOperationsAndMaintenance: {
        label: 'Betrieb und Wartung von IT-Systemen',
        sublabel: '',
        tooltip: '',
      },
      itSystemInstallationAndDisposal: {
        label: 'IT-System-Installation und -Entsorgung',
        sublabel: '',
        tooltip: '',
      },
      itSystemTrainingAndSupport: {
        label: 'IT-Systemschulung und -Unterstützung',
        sublabel: '',
        tooltip: '',
      },
      itSystemDataServices: {
        label: 'IT-System Datendienste',
        sublabel: '',
        tooltip: '',
      },
      itSystemTestingAndEvaluation: {
        label: 'IT-Systemprüfung und -bewertung',
        sublabel: '',
        tooltip: '',
      },
      internetNews: {
        label: 'Internet-Nachrichten',
        sublabel: '',
        tooltip: '',
      },
      blogs: {
        label: 'Blogs',
        sublabel: '',
        tooltip: '',
      },
      interiorDesign: {
        label: 'Innenarchitektur',
        sublabel: '',
        tooltip: '',
      },
      socialNetworkingPlatforms: {
        label: 'Soziale Vernetzungsplattformen',
        sublabel: '',
        tooltip: '',
      },
      businessIntelligencePlatforms: {
        label: 'Business Intelligence-Plattformen',
        sublabel: '',
        tooltip: '',
      },
      businessContent: {
        label: 'Geschäftlicher Inhalt',
        sublabel: '',
        tooltip: '',
      },
      dataSecuritySoftwareProducts: {
        label: 'Softwareprodukte für Datensicherheit',
        sublabel: '',
        tooltip: '',
      },
      mobileGamingApps: {
        label: 'Mobile Spiele-Apps',
        sublabel: '',
        tooltip: '',
      },
      internetPublishing: {
        label: 'Internet-Veröffentlichung',
        sublabel: '',
        tooltip: '',
      },
      mediaAndTelecommunications: {
        label: 'Medien und Telekommunikation',
        sublabel: '',
        tooltip: '',
      },
      blockchainServices: {
        label: 'Blockchain-Dienste',
        sublabel: '',
        tooltip: '',
      },
      retailArtDealers: {
        label: 'Einzelhandels-Kunsthändler',
        sublabel: '',
        tooltip: '',
      },
      yes_v2: {
        label: 'Ja',
        sublabel: '',
        tooltip: '',
      },
      no_v2: {
        label: 'Nein',
        sublabel: '',
        tooltip: '',
      },
      iDontKnow: {
        label: 'Ich weiß es nicht.',
        sublabel: '',
        tooltip: '',
      },
    },
  },
  impactGoal: {
    listView: {
      headers: {
        venture: { name: 'Unternehmen' },
        groupNames: 'Gruppen',
        impactGoal: {
          targetProgress: 'Zielfortschritt',
          impacts: 'Impacts',
          sdgs: 'Zugehörige SDGs',
          submitDate: 'Einreichungsdatum',
        },
        geographies: 'Geographien',
        comment: 'Kommentare des Portfoliomanagers',
        action: 'Aktionen',
      },
      sortBy: 'Sortiert nach {column}',
      addComment: '+Kommentar hinzufügen',
      exportPDF: 'Exportieren Sie den Wirkungsbericht als PDF',
      unFreezeGoalUndTarget: 'Wirkungsziel und Zielwerte freigeben',
      freezeGoalUndTarget: 'Wirkungsziel und Zielwerte einfrieren',
    },
    navigation: {
      introduction: { label: 'Einleitung' },
      euTaxonomy: { label: 'EU-Taxonomie' },
      eligibilityCheck: {
        label: 'Überprüfung der Taxonomie-Fähigkeit und -Konformität',
      },
      financialMetrics: { label: 'Finanz-KPIs' },
      impactDefinition: { label: 'Meine Auswirkungen' },
      impactNumber: { label: 'Wirkung' },
      problemDescription: { label: 'Problembeschreibung' },
      internationalFrameworks: { label: 'Internationale Frameworks' },
      impactSolutions: { label: 'Lösungen' },
      impactSolutionNumber: { label: 'Lösung' },
      ghgSavingPotential: { label: 'Treibhausgas-Einsparpotenzial' },
      importantNotes: { label: 'Wichtige Hinweise' },
      impactProfile: { label: 'Wirkungsprofil' },
      fairComparison: { label: 'Fairer Vergleich' },
      emissionSources: { label: 'Emissionsquellen' },
      growthFactor: { label: 'Vorhersage' },
      result: { label: 'Ergebnis' },
      impactMeasurement: { label: 'Wirkungsmessung' },
      impactTargets: { label: 'Wirkungsziele' },
      nextSteps: { label: 'Nächste Schritte' },
    },
    overview: {
      selfAssessmentTooltip: 'Selbsteinschätzung',
      guidanceTitle: 'Weitere Informationen',
      guidanceText:
        'Weitere Informationen zur Methodik des Tools finden Sie <a href="https://de.impactnexus.io/the-impact-goal-and-ghg-impact-module">hier</a>',
      editButton: 'Wirkungsziele bearbeiten',
      title: 'Überblick über die Nachhaltigkeitswirkungen',
      progressSubtitle: 'Fortschritt hinsichtlich Ihrer Wirkungsziele',
      impactsSubtitle: 'Wirkungen',
      correspondingImpactSolutions: 'Entsprechende Lösungen:',
      climateImpactModule: {
        title: 'Klimaauswirkungen unserer Lösungen',
        belowAverage:
          'Dieses Unternehmen liegt unter dem durchschnittlichen Treibhausgas-Reduktionspotenzial. Ein Unternehmen gilt als Unternehmen mit hohem Klimapotenzial, wenn seine Lösungen dazu beitragen, in den nächsten 5 Jahren durchschnittlich mehr als 50.000 Tonnen CO2e pro Jahr zu reduzieren. Dies ist eine Experteneinschätzung, die auf Fallstudien des Borderstep Instituts für Innovation und Nachhaltigkeit beruht.',
        aboveAverage:
          'Dieses Unternehmen liegt über dem durchschnittlichen Treibhausgas-Reduktionspotenzial. Ein Unternehmen gilt als Unternehmen mit hohem Klimapotenzial, wenn seine Lösungen dazu beitragen, in den nächsten 5 Jahren durchschnittlich mehr als 50.000 Tonnen CO2e pro Jahr zu reduzieren. Dies ist eine Experteneinschätzung, die auf Fallstudien des Borderstep Instituts für Innovation und Nachhaltigkeit beruht.',
        highImpact: 'Wir sind ein Unternehmen mit hohem Klimapotenzial!',
        reductionTextHcp: `Wir sind ein Unternehmen mit hohem Klimapotenzial, da unsere Lösungen eine geschätzte durchschnittliche jährliche Reduzierung von
        {averageAnnualReduction}
        Tonnen CO2e bewirken.`,
        reductionText: `Unsere Lösungen führen zu einer geschätzten durchschnittlichen jährlichen Reduzierung von
        {averageAnnualReduction}
        Tonnen CO2e.`,
        representedByMoney: `Unser jährlicher wirtschaftlicher Wert der geplanten Treibhausgasreduzierung beträgt {economicValue} €.`,
        representedByMoneyTooltip: `Der jährliche wirtschaftliche Wert errechnet sich aus der Multiplikation der durchschnittlichen jährlichen Reduktion mit dem durchschnittlichen CO2-Preis im EU-Emissionshandelssystem ab 2023 (83,24 €/Tonne CO2e).`,
        protectionPledge: `Unsere Lösung {label} sieht vor, von {startYear} bis {endYear} {amount} Tonnen CO2e einzusparen, indem wir die Treibhausgasemissionen um {percentageReduction} % pro {header} ({reduction} kg CO2e) reduzieren.`,
        chartTitle:
          'Jährlich geplante Treibhausgasreduktionen (in Tonnen CO2e)',
        chartYLabel: 'tonnen CO2e',
      },
      ourSolutions: {
        title: 'Unsere Lösungen',
        solution: 'Lösung',
        PRODUCT_APPLICATION: 'Produktnutzung',
        CIRCULAR_ECONOMY: 'Kreislaufwirtschaft',
        EXTRACTION_AND_MANUFACTURING: 'Rohstoffgewinnung und Produktion',
        LOGISTICS: 'Transport und Logistik',
        CUSTOMER_EMPOWERMENT: 'Kundenbefähigung',
        HYBRID: 'Hybrid',
        PRODUCT: 'Produkt',
        SERVICE: 'Dienstleistung',
        plannedSavings:
          'Geplante Einsparungen von {savingsAmount} Tonnen CO2e von {startYear} bis {endYear}',
      },
      ourImpacts: {
        title: 'Unsere Auswirkungen',
        solutionsText: 'Lösungen, die zu dieser Wirkung beitragen: ',
      },
      ourMeasurement: {
        title: 'Unsere Messungen und KPIs',
        forecastFor: "Zielfortschritt für '{metricName}' ({impactName})",
      },
    },
    introButton:
      'Klicken Sie auf "Weiter", um die Nachhaltigkeitswirkungen im Detail zu beschreiben.',
    intro: {
      title: 'Willkommen beim GHG & Impact Estimator',
      description: `<p>Mit diesem Modul können Sie Ihre wichtigsten <strong>Auswirkungen</strong> und <strong>Lösungen</strong> definieren und das <strong>THG-Reduktionspotenzial</strong> Ihres Unternehmens gemäß Best Practices abschätzen im Wirkungsmanagement und der Wirkungsmessung. In diesem Modul werden Sie:</p>
       <ul>
           <li>Definieren Sie die Auswirkungen Ihres Unternehmens auf Menschen und Umwelt und richten Sie diese Auswirkungen an internationalen Rahmenbedingungen aus</li>
           <li>Definieren Sie Ihre Lösungen (Ihre Produkte oder Dienstleistungen), die Ihnen dabei helfen, Ihre Wirkung zu erzielen</li>
           <li>Bestimmen Sie das THG-Reduktionspotenzial Ihrer Lösungen, indem Sie sie mit einem herkömmlichen, weniger klimafreundlichen Produkt oder einer Dienstleistung vergleichen (optional)</li>
           <li>Legen Sie KPIs und quantitative Ziele fest, um Ihre Lösung zu messen und zu verfolgen</li>
       </ul>
       <p>Wenn Sie Ihre Treibhausgasreduzierung sofort quantifizieren möchten, wählen Sie <strong>THG-Reduzierung jetzt quantifizieren</strong> und wir erstellen Ihnen automatisch eine Auswirkung und Lösung, die Sie später bearbeiten können.</p>`,
      QuantifyGhgReductionNowButton: 'THG-Reduktion jetzt quantifizieren',
    },
    // TODO: translate euTaxonomy
    euTaxonomy: {
      intro: `
## What is the EU Taxonomy?

The EU Taxonomy is a classification system that helps companies and investors identify
"environmentally sustainable” economic activities to make sustainable investment decisions.
Environmentally sustainable economic activities are described as those which "make a
substantial contribution to at least one of the EU's climate and environmental objectives,
while at the same time not significantly harming any of these objectives and meeting minimum safeguards.”

The EU Taxonomy has six environmental objectives:
- Climate change mitigation.
- Climate change adaptation.
- The sustainable use and protection of water and marine resources.
- The transition to a circular economy.
- Pollution prevention and control.
- The protection and restoration of biodiversity and ecosystems.

### Eligibility:
To qualify as a sustainable activity, an economic activity must make a significant contribution to at least one of the six environmental objectives mentioned above.

### Alignment:
An activity becomes aligned with the taxonomy when it satisfies the following three criteria:
- Substantial contribution: The activity must make a significant contribution to one of the six economic activities outlined in the Technical Screening Criteria (TSC), which are in line with the taxonomy objectives.
- Do-no-significant-harm (DNSH): The activity should not cause significant harm to the other environmental objectives beyond the one it substantially contributes to.
- Minimum social safeguards (MSS) compliance: The company must adhere to the minimum social safeguards described in the Taxonomy Regulation.

For more details  see https://ec.europa.eu/sustainable-finance-taxonomy/home
      `,
      checkIntro: `
In this section, both EU taxonomy eligibility and alignment will be verified. By providing the relevant links to the websites about your activities, the **eligibility check** identifies business activities that could potentially contribute to the sustainable objectives of the EU Taxonomy.

Whether this is actually the case will be examined in detail for each business activity individually as part of the **EU Taxonomy alignment check**.

In addition to the automatic suggestions, you can select EU Taxonomy eligible business activities manually.
`,
      acitivityQuestion: {
        title: 'Identify business activities',
        subtitle:
          'Please provide us with 1 to 3 links to websites that best describe your business activities, e.g., a link to the product description on your company website or a blog article that explains what you are working on. Based on this information, the NEXUS automatically checks if you conduct activities that are EU Taxonomy eligible.',
      },
      explanation: {
        title: 'Details on the EU taxonomy eligibility check',
        backgroundTitle: 'Background of the eligibility and alignment check',
        background: `
*   The **EU Taxonomy** offers a list of sectors and business activites that could potentially contribute to the impact objectives of the EU. Currently, there are **six environmental objectives**, but only two are elaborated in detail - climate change mitigation and climate change adaption.
*   In the **eligibility check**, you find out if the activities of your company are covered by the EU Taxonomy, which currently means that they have the potential to contribute significantly to climate change mitigation or climate change adaption.
*   The **alignment check**. This serves to determine if your business activites really contribute substantially to climate change mitigation or climate change adaption, while doing no significant harm to the six environmental objectives. If your company respects the minimum safeguards defined by the EU is checked in the disclosure section of the ESG questionnaire.
  `,
        implementationTitle: 'Conducting the eligibility and alignment check',
        implementation: `
*   First, we like you to provide us with 1 to 3 links to websites that best describe your business activities, e.g. a link to the product description on your company website or a blog article that explains what you are working on. Based on this information, the NEXUS automatically checks if you conduct activities that are EU Taxonomy eligible. A full list of these activities can be found [here](https://ec.europa.eu/sustainable-finance-taxonomy/activities).
*   In the dropdown, you can search, select and remove the EU eligible taxonomies manually. In case the NEXUS identified any activities that might apply to you, you can add those too.
*   Go to the next page when you have identified all activities that belong to your business.
  `,
      },
      alignmentQuestionnaire: {
        noQuestionnaireId:
          'Something went wrong. Please exit the questionnaire and try again.',
        noneOfTheActivities:
          'None of your selected business activities contribute to this impact.',
        leaveButton: 'Exit questionnaire',
      },
      financial: {
        intro: `
Thank you for completing the EU Taxonomy eligibility and alignment check! You can now view your activity or activities that are aligned according to the requirements set out in the EU Taxonomy.

As part of the SFDR periodic disclosure, Taxonomy-aligned activities need to be expressed as share of some KPIs, such as turnover, capital expenditure (CapEx), and operational expenditure (OpEx). Hence, this information first needs to be collected for your aligned activities at the company level. Then, these KPIs allow for calculations at the fund level.

- **Turnover**: Total sales from products/services minus sales rebates, value-added tax, and other related taxes.
- **Capital expenditure (CapEx)**: Refers to additions to tangible and intangible assets in the financial year, which include property, plant, equipment, intangible assets, investment property, agriculture, and leases.
- **Operational expenditure (OpEx)**: Refers to direct expenditures needed for the daily upkeep of assets such as property, plant, and equipment. This includes activities like research and development, building renovations, short-term lease, maintenance, and repairs.`,
      },
    },
    outro: {
      afterSubmit: {
        title:
          'Herzlichen Glückwunsch, Sie haben Ihre Auswirkungen und Lösungen definiert!',
        description: `
- Besuchen Sie den Tab "Impact", um die Zusammenfassung Ihrer Wirkungsziele zu sehen.
- Aktualisieren Sie regelmäßig die Fortschritte bei den festgelegten quantitativen Zielen auf dem Tab "KPI"
- Erstellen Sie Ihren eigenen **Verbesserungsfahrplan** und definieren Sie drei Maßnahmen auf dem **Aufgaben-Tab**, um Ihre Wirkungsziele zu erreichen`,
        feedback: {
          title: 'Bevor Sie zu den Ergebnissen kommen, ein kurzes Feedback',
          description: 'Bitte helfen Sie uns, die Anwendung zu verbessern',
          rating: {
            title:
              'Wie zufrieden waren Sie mit dem Modul (Struktur, Design und Inhalt)?',
            descriptions: {
              0: 'Keine Bewertung',
              1: 'Schrecklich',
              2: 'Schlecht',
              3: 'Durchschnittlich',
              4: 'Gut',
              5: 'Ausgezeichnet',
            },
          },
          comment: {
            title:
              'Wir freuen uns über Feedback! Bitte teilen Sie uns Ihre Verbesserungsvorschläge mit.',
            label: 'Bitte teilen Sie Ihr Feedback hier mit',
          },
        },
        toImpactTab: 'Zur Übersicht der Wirkungen',
        toTaskTab: 'Zum Verbesserungsfahrplan',
        toKPITab: 'Zum KPI-Tracking',
      },
    },
    impacts: {
      defineImpacts: {
        impactDefinition: {
          title: 'Meine Auswirkungen',
          exampleTitle: 'Beispielhafte Auswirkungen:',
          description: `Auswirkungen sind die strukturellen und/oder systemischen Auswirkungen der Aktivitäten eines Unternehmens auf die Menschen (vom Einzelnen bis zur breiteren Gesellschaft) und/oder die natürliche Umwelt.`,
          exampleImpacts: [
            {
              outcome: 'Reduzierte CO2-Emissionen',
              stakeholder: 'Menschen und die natürliche Umwelt',
            },
            {
              outcome: 'Verbesserter Zugang zu sauberem Wasser',
              stakeholder: 'Menschen',
            },
            {
              outcome: 'Verbesserter Bildungszugang',
              stakeholder: 'Menschen',
            },
          ],
        },
        defineImpact: {
          title: 'Definieren Sie Ihre Wirkung(en)',
          // subtitle:
          // 'Konzentrieren Sie sich auf die 1-3 wichtigsten Wirkungen für Ihr Unternehmen. Sie können eine benutzerdefinierte Wirkung erstellen oder aus der Dropdown-Liste der Vorlagen auswählen.',
          subtitle: `Konzentrieren Sie sich auf die 1-3 wichtigsten Wirkungen für Ihr Unternehmen. Sie können eine benutzerdefinierte Wirkung erstellen oder aus der Dropdown-Liste der Vorlagen auswählen.


Falls Ihre Aktivitäten zu klimabezogenen Themen beitragen, können Sie aus den von uns bereitgestellten Vorlagen wählen (Reduzierte CO2-Emissionen, Anpassung an den Klimawandel). Alternativ haben Sie die Möglichkeit, eine eigene benutzerdefinierte Impact-Vorlage zu erstellen. Bitte folgen Sie diesen Schritten:

1. Fügen Sie alle Ihre Wirkungen ein, indem Sie die vordefinierten und/oder benutzerdefinierten Wirkungsvorlagen verwenden.
2. Machen Sie alle notwendigen Angaben zu Ihrer ersten Wirkung bevor Sie die nächste anlegen.
3. Füllen Sie den Fragebogen für jede Wirkung aus.`,
          deleteImpactButton: 'Wirkungen löschen',
          deleteImpactConfirmation:
            'Wollen Sie diese Wirkung wirklich löschen? Sie können diese Aktion nicht rückgängig machen.',
          addImpactButton: 'Wirkungen hinzufügen',
          impactTypes: [
            'Reduzierte CO2-Emissionen',
            'Anpassung an den Klimawandel',
            'Benutzerdefinierte Wirkungen',
          ],
          outcomeStakeholderDialog: {
            title:
              'Beschreiben Sie die Wirkung, die Ihre Lösungen erzielen helfen',
            introParagraph:
              '<p>In diesem Abschnitt definieren Sie Ihre Wirkung, die die Wirkung Ihrer Lösungen auf Menschen oder die natürliche Umwelt beschreibt. Sobald die Auswirkung definiert ist, können Sie zum Bildschirm <strong>Meine Auswirkungen</strong> zurückkehren und Ihre Lösung(en) hinzufügen, die zur Erreichung dieser Auswirkung beitragen. Wenn Sie über mehrere Lösungen verfügen, die unterschiedliche Vorteile bieten, müssen Sie möglicherweise mehrere Auswirkungen definieren.</p>',
            ixMethodologyTitle:
              'Welche Methodik verwendet ImpactNexus für Auswirkungen?',
            ixMethodology: `<p>Die von ImpactNexus verwendete Definition von Auswirkungen basiert auf internationalen Methoden zum Wirkungsmanagement, einschließlich der Impact Management Platform und dem Social Impact Navigator / IOOI Framework von PHINEO.</p>
             <p>Auswirkungen sind die strukturellen und/oder systemischen Auswirkungen der Aktivitäten eines Unternehmens auf die Menschen (vom Einzelnen bis zur breiteren Gesellschaft) und/oder die natürliche Umwelt. Wenn Sie in der ImpactNexus-App eine Auswirkung hinzufügen, definieren Sie Folgendes:</p>
             <ul>
                 <li>Ergebnisse – Die spezifischen Vorteile, Werte oder Veränderungen auf der Ebene der Menschen (Zielgruppen und Stakeholder) und/oder der natürlichen Umgebung, die dazu beitragen, die gewünschte Wirkung zu erzielen. Das Ergebnis wird als Titel für Ihre Wirkung verwendet und in Ihren Wirkungsberichten angezeigt.</li>
                 <li>Betroffene Partei – Entweder Menschen, die natürliche Umgebung oder beides</li>
                 <li>Betroffene Länder oder Regionen – Die Standorte, die am stärksten von den Ergebnissen und Auswirkungen betroffen sind</li>
             </ul>
             <p>Wenn Sie eine Wirkungsvorlage wie „Reduzierte CO2-Emissionen“ verwenden, können einige Felder möglicherweise nicht bearbeitet werden.</p>`,
            sourceAssignmentsInput: {
              title: 'Beweise für die Relevanz des Problems',
              subtitle:
                'Falls vorhanden, fügen Sie bitte externe Belege hinzu, die verdeutlichen, **warum dieses Problem für die Menschen und/oder das globale Ökosystem relevant ist** (insbesondere quantitative Belege)".',
            },
            outcome: {
              title:
                'Welche Vorteile, Werte oder Veränderungen ergeben sich für den Menschen und/oder die natürliche Umwelt?',
              subtitle: `p>Dies ist das <strong>beabsichtigte Ergebnis</strong>, das von Ihrer Lösung (Produkt oder Dienstleistung) für die Menschen und/oder die natürliche Umwelt erwartet wird und das zur Erzielung der Wirkung beiträgt.</p>
               <p>Zum Beispiel:</p>
               <ul>
                   <li>Reduzierte CO2-Emissionen</li>
                   <li>Reduzierte Flussverschmutzung</li>
                   <li>Erhöhte Zahl gebildeter junger Erwachsener</li>
                   <li>Gesteigerte Selbstwirksamkeit für Familien</li>
               </ul>`,
            },
            stakeholder: {
              title: 'Wer oder was ist von dem Ergebnis betroffen?',
              PEOPLE: 'Menschen',
              NATURAL_ENVIRONMENT: 'Natürliche Umwelt',
              PEOPLE_AND_NATURAL_ENVIRONMENT:
                'Menschen und die natürliche Umwelt',
              subtitle: `<ul>
               <li>Wenn das Ergebnis hauptsächlich Einzelpersonen, Gruppen oder die Gesellschaft als Ganzes betrifft, wählen Sie <strong>Menschen</strong></li>
               <li>Wenn sich das Ergebnis hauptsächlich auf die Umwelt auswirkt, etwa Land, Wasser, Atmosphäre oder Lebewesen, die auf die Umwelt angewiesen sind, wählen Sie <strong>Natürliche Umgebung</strong></li>
               <li>Wenn das Ergebnis sowohl Menschen als auch die natürliche Umwelt betrifft, wählen Sie <strong>Menschen und natürliche Umwelt</strong>. Beispielsweise hat die Reduzierung der Emissionen positive Auswirkungen auf die Umwelt, aber auch auf die Gemeinden, die den Emissionen ausgesetzt sind.</li>
           </ul>`,
              placeholder: 'Wer ist von diesem Ergebnis betroffen?',
            },
            affectedRegions: {
              title: 'Betroffene Länder und Regionen',
              subtitle:
                'Welche Länder oder Regionen sind am stärksten von dem Ergebnis betroffen?   Wählen Sie aus der Liste unten. Sie haben die Möglichkeit, Regionen oder Länder auszuwählen.',
            },
          },
        },
        classifyImpact: {
          title: 'Internationale Frameworks',
          sdgInput: {
            title: 'Verwandte SDGs auswählen',
            tooltip:
              'Die SDGs (Sustainable Development Goals) sind eine Sammlung von 17 globalen Zielen, die von den Vereinten Nationen im Jahr 2015 festgelegt wurden. Diese Ziele, die bis 2030 erreicht werden sollen, decken ein breites Spektrum von Nachhaltigkeitsthemen ab, darunter Armut, Hunger, Gesundheit, Bildung, Klimawandel, Gleichstellung der Geschlechter, sauberes Wasser und sanitäre Einrichtungen sowie soziale Gerechtigkeit. Die SDGs zielen darauf ab, globale Partnerschaften zu stärken, um Frieden und Wohlstand für die Menschen und das globale Ökosystem zu fördern.',
            subtitle: `Bitte wählen Sie die Ziele für eine nachhaltige Entwicklung (SDGs) aus, die mit den Wirkungen verbunden sind. Klicken Sie [hier](https://sdgs.un.org/goals) für weitere Informationen zu den SDGs.`,
            description: {
              prefix: `* Die SDGs sind eine Reihe von 17 Zielen mit insgesamt 169 Vorgaben für die Länder der Welt.`,
              suffix: `* Unternehmen können zur Erreichung der SDG beitragen, wenn sie einen positiven Beitrag zu mindestens einem der unten aufgeführten Zielen leisten: \n`,
            },
            confirm:
              'Bestätigen Sie den Beitrag zu den Nachhaltigkeitszielen (SDGs)',
            sdgs: {
              1: {
                id: 1,
                title: '*Armut in all ihren Formen überall beenden',
                short: 'Keine Armut',
                colorInfo: {
                  hex: '#e5243b',
                  rgb: [229, 36, 59],
                },
                keywords: {
                  tags: ['Armut'],
                  descriptions: [
                    'extreme Armut',
                    'elementarer Lebensstandard',
                    'Sozialschutz',
                  ],
                  groups: ['ärmste', 'gefährdete'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_1.png',
                targets: [
                  {
                    id: 1,
                    sdgId: 1,
                    externalId: 1,
                    label:
                      'Bis 2030: *Ausmerzung der extremen Armut für alle Menschen überall*, die derzeit mit weniger als 1,25 Dollar pro Tag auskommen müssen.',
                  },
                  {
                    id: 2,
                    sdgId: 1,
                    externalId: 2,
                    label:
                      'Bis 2030 **den Anteil der Männer, Frauen und Kinder aller Altersgruppen, die in *Armut* in all ihren Dimensionen gemäß den nationalen Definitionen leben, mindestens um die Hälfte reduzieren**.',
                  },
                  {
                    id: 3,
                    sdgId: 1,
                    externalId: 3,
                    label:
                      'Umsetzung nationaler *angemessener Sozialschutzsysteme und -maßnahmen* für alle, einschließlich der Untergrenzen, und bis 2030 Erreichung einer wesentlichen Abdeckung der Armen und Schwachen',
                  },
                  {
                    id: 4,
                    sdgId: 1,
                    externalId: 4,
                    label:
                      'Bis 2030 sicherstellen, dass alle Männer und Frauen, insbesondere die Armen und Schwachen, *gleiche Rechte auf wirtschaftliche Ressourcen* haben sowie Zugang zu grundlegenden Dienstleistungen, Eigentum und Kontrolle über Land und andere Formen von Eigentum, Erbschaft, natürliche Ressourcen, geeignete neue Technologien und Finanzdienstleistungen, einschließlich Mikrofinanzierung',
                  },
                  {
                    id: 5,
                    sdgId: 1,
                    externalId: 5,
                    label:
                      'bis 2030 *die Widerstandsfähigkeit der Armen und der Menschen in prekären Situationen erhöhen und *ihre Exposition und Anfälligkeit gegenüber klimabedingten Extremereignissen und anderen wirtschaftlichen, sozialen und ökologischen Schocks und Katastrophen verringern',
                  },
                  {
                    id: 6,
                    sdgId: 1,
                    externalId: 'a',
                    label:
                      'Gewährleistung einer *erhebliche  Mobilisierung von Ressourcen* aus verschiedenen Quellen, einschließlich durch verbesserte Entwicklungszusammenarbeit, um den Entwicklungsländern und insbesondere den am wenigsten entwickelten Ländern, angemessene und vorhersehbare Mittel für die Durchführung von Programmen und Maßnahmen zur Beseitigung der Armut in all ihren Dimensionen zur Verfügung zu stellen',
                  },
                  {
                    id: 7,
                    sdgId: 1,
                    externalId: 'b',
                    label:
                      'Schaffung solider politischer Rahmenbedingungen auf nationaler, regionaler und internationaler Ebene auf der Grundlage armutsorientierter und geschlechtersensibler Entwicklungsstrategien, um beschleunigte *Investitionen in Maßnahmen zur Beseitigung der Armut* zu unterstützen',
                  },
                ],
              },
              2: {
                id: 2,
                title:
                  '*Beendigung des Hungers, Erreichung von Ernährungssicherheit und verbesserter Ernährung sowie Förderung einer nachhaltigen Landwirtschaft',
                short: 'Null Hunger',
                colorInfo: {
                  hex: '#e5b735',
                  rgb: [229, 183, 53],
                },
                keywords: {
                  tags: [
                    'Hunger',
                    'Ernährungssicherheit',
                    'Ernährung',
                    'nachhaltige Landwirtschaft',
                  ],
                  descriptions: [
                    'Unterernährung',
                    'Lebensmittelproduktion',
                    'landwirtschaftliche Produktivität',
                    'landwirtschaftliche Investitionen',
                    'Lebensmittelmärkte',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_2.png',
                targets: [
                  {
                    id: 8,
                    sdgId: 2,
                    externalId: 1,
                    label:
                      'bis 2030 *den Hunger beenden* und allen Menschen, insbesondere den Armen und Menschen in prekären Situationen, einschließlich Kleinkindern, ganzjährig Zugang zu sicheren, nahrhaften und ausreichenden Nahrungsmitteln gewähren',
                  },
                  {
                    id: 9,
                    sdgId: 2,
                    externalId: 2,
                    label:
                      'Bis 2030: *Beendigung aller Formen der Unterernährung*, einschließlich der Erreichung der international vereinbarten Ziele für die Bekämpfung der Unterernährung und Auszehrung bei Kindern unter 5 Jahren bis 2025, und Berücksichtigung der Ernährungsbedürfnisse von heranwachsenden Mädchen, schwangeren und stillenden Frauen und älteren Menschen',
                  },
                  {
                    id: 10,
                    sdgId: 2,
                    externalId: 3,
                    label:
                      'bis 2030 *Verdoppelung der landwirtschaftlichen Produktivität und der Einkommen* von Kleinproduzenten von Nahrungsmitteln, insbesondere von Frauen, indigenen Völkern, bäuerlichen Familienbetrieben, Viehzüchtern und Fischern, u. a. durch sicheren und gleichberechtigten Zugang zu Land, anderen produktiven Ressourcen und Betriebsmitteln, Wissen, Finanzdienstleistungen, Märkten und Möglichkeiten der Wertschöpfung und außerlandwirtschaftlicher Beschäftigung',
                  },
                  {
                    id: 11,
                    sdgId: 2,
                    externalId: 4,
                    label:
                      'bis 2030* nachhaltige Lebensmittelproduktionssysteme* zu gewährleisten und widerstandsfähige landwirtschaftliche Praktiken einzuführen, die die Produktivität und Produktion steigern, zur Erhaltung der Ökosysteme beitragen, die Anpassungsfähigkeit an den Klimawandel, extreme Wetterbedingungen, Dürre, Überschwemmungen und andere Katastrophen stärken und die Bodenqualität schrittweise verbessern',
                  },
                  {
                    id: 12,
                    sdgId: 2,
                    externalId: 5,
                    label:
                      'bis 2020 *die genetische Vielfalt von Saatgut, Kulturpflanzen*, Nutz- und Haustieren und den mit ihnen verwandten wildlebenden Arten zu erhalten, u. a. durch solide verwaltete und diversifizierte Saatgut- und Pflanzenbanken auf nationaler, regionaler und internationaler Ebene, und den Zugang zu den Vorteilen, die sich aus der *Nutzung genetischer Ressourcen* und des damit verbundenen traditionellen Wissens ergeben, sowie deren faire und gerechte Aufteilung zu fördern, wie international vereinbart',
                  },
                  {
                    id: 13,
                    sdgId: 2,
                    externalId: 'a',
                    label:
                      'Erhöhung der Investitionen, auch durch eine verstärkte internationale Zusammenarbeit, in die ländliche Infrastruktur, die landwirtschaftliche Forschung und Beratung, die Technologieentwicklung und die Genbanken für Pflanzen und Tiere, um die landwirtschaftliche Produktionskapazität in den Entwicklungsländern, insbesondere in den am wenigsten entwickelten Ländern, zu erhöhen.',
                  },
                  {
                    id: 14,
                    sdgId: 2,
                    externalId: 'b',
                    label:
                      'Handelsbeschränkungen und -verzerrungen auf den Weltagrarmärkten* zu korrigieren und* zu verhindern, u.a. durch die parallele Abschaffung aller Formen von Agrarexportsubventionen und aller Exportmaßnahmen mit gleicher Wirkung, im Einklang mit dem Mandat der Doha-Entwicklungsrunde',
                  },
                  {
                    id: 15,
                    sdgId: 2,
                    externalId: 'c',
                    label:
                      'Verabschiedung von Maßnahmen zur *Gewährleistung des ordnungsgemäßen Funktionierens der Lebensmittelmärkte* und ihrer Derivate und zur Erleichterung des rechtzeitigen Zugangs zu Marktinformationen, auch über Lebensmittelreserven, um die extreme Preisvolatilität bei Lebensmitteln zu begrenzen',
                  },
                ],
              },
              3: {
                id: 3,
                title:
                  '*Gesundes Leben und Wohlbefinden für alle Menschen in jedem Alter fördern*',
                short: 'Gute Gesundheit und Wohlbefinden',
                colorInfo: {
                  hex: '#4c9f38',
                  rgb: [76, 159, 56],
                },
                keywords: {
                  tags: ['Gesundheit', 'Wohlbefinden'],
                  descriptions: [
                    'Reproduktionsgesundheit',
                    'Gesundheit von Müttern',
                    'Kindergesundheit',
                    'Epidemien',
                    'übertragbare Krankheiten',
                    'nicht übertragbare Krankheiten',
                    'umweltbedingte Krankheiten',
                    'allgemeine Gesundheitsversorgung',
                    'Arzneimittel',
                    'Impfstoffe',
                  ],
                  groups: ['Frauen', 'Kinder'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_3.png',
                targets: [
                  {
                    id: 16,
                    sdgId: 3,
                    externalId: 1,
                    label:
                      'Bis 2030 **die weltweite Müttersterblichkeitsrate* auf weniger als 70 pro 100.000 Lebendgeburten senken',
                  },
                  {
                    id: 17,
                    sdgId: 3,
                    externalId: 2,
                    label:
                      'Bis 2030: *Beendigung der vermeidbaren Todesfälle bei Neugeborenen und Kindern* unter 5 Jahren, wobei alle Länder eine *Senkung* der Neugeborenensterblichkeit auf mindestens 12 pro 1.000 Lebendgeburten und der Sterblichkeit bei Kindern unter 5 Jahren auf mindestens 25 pro 1.000 Lebendgeburten anstreben',
                  },
                  {
                    id: 18,
                    sdgId: 3,
                    externalId: 3,
                    label:
                      'Bis 2030: * Beendigung der Epidemien von AIDS, Tuberkulose, Malaria* und vernachlässigten Tropenkrankheiten sowie Bekämpfung von Hepatitis, durch Wasser übertragenen Krankheiten und anderen übertragbaren Krankheiten',
                  },
                  {
                    id: 19,
                    sdgId: 3,
                    externalId: 4,
                    label:
                      'Bis 2030 **die *frühzeitige Sterblichkeit* durch nicht übertragbare Krankheiten durch Prävention und Behandlung um ein Drittel senken und die psychische Gesundheit und das Wohlbefinden fördern',
                  },
                  {
                    id: 20,
                    sdgId: 3,
                    externalId: 5,
                    label:
                      'Stärkung der *Prävention und Behandlung von Drogenmissbrauch*, einschließlich des Missbrauchs von Betäubungsmitteln und schädlichem Alkoholkonsum',
                  },
                  {
                    id: 21,
                    sdgId: 3,
                    externalId: 6,
                    label:
                      'Bis 2020: *Halbierung* der Zahl der weltweiten *Todesfälle und Verletzungen durch Straßenverkehrsunfälle*',
                  },
                  {
                    id: 22,
                    sdgId: 3,
                    externalId: 7,
                    label:
                      'Bis 2030 * Sicherstellung des allgemeinen Zugangs zu Dienstleistungen im Bereich der sexuellen und reproduktiven Gesundheit*, einschließlich Familienplanung, Information und Aufklärung, sowie Einbeziehung der reproduktiven Gesundheit in nationale Strategien und Programme',
                  },
                  {
                    id: 23,
                    sdgId: 3,
                    externalId: 8,
                    label:
                      '*Verwirklichung einer allgemeinen Gesundheitsversorgung*, einschließlich der Absicherung gegen finanzielle Risiken, des Zugangs zu hochwertigen grundlegenden Gesundheitsdiensten und des Zugangs zu sicheren, wirksamen, hochwertigen und erschwinglichen grundlegenden Arzneimitteln und Impfstoffen für alle',
                  },
                  {
                    id: 24,
                    sdgId: 3,
                    externalId: 9,
                    label:
                      'Bis 2030 die Zahl der *Todesfälle und Krankheiten* aufgrund von gefährlichen Chemikalien und der *Verschmutzung und Verunreinigung* von Luft, Wasser und Boden erheblich *reduzieren*.',
                  },
                  {
                    id: 25,
                    sdgId: 3,
                    externalId: 'a',
                    label:
                      'Stärkung der *Umsetzung des *Rahmenübereinkommens der Weltgesundheitsorganisation* zur Eindämmung des Tabakkonsums* in allen Ländern, soweit angemessen',
                  },
                  {
                    id: 26,
                    sdgId: 3,
                    externalId: 'b',
                    label:
                      '*Unterstützung der Forschung und Entwicklung von Impfstoffen und Arzneimitteln* für übertragbare und nicht übertragbare Krankheiten, von denen in erster Linie die Entwicklungsländer betroffen sind, Zugang zu erschwinglichen grundlegenden Arzneimitteln und Impfstoffen im Einklang mit der Erklärung von Doha über das TRIPS-Übereinkommen und die öffentliche Gesundheit, in der das Recht der Entwicklungsländer bekräftigt wird, die Bestimmungen des Übereinkommens über handelsbezogene Aspekte der Rechte des geistigen Eigentums in Bezug auf Flexibilitäten zum Schutz der öffentlichen Gesundheit in vollem Umfang zu nutzen und insbesondere den Zugang zu Arzneimitteln für alle zu gewährleisten',
                  },
                  {
                    id: 27,
                    sdgId: 3,
                    externalId: 'c',
                    label:
                      'die Finanzierung des Gesundheitswesens und die Einstellung, Entwicklung, Ausbildung und Bindung des Gesundheitspersonals in den Entwicklungsländern, insbesondere in den am wenigsten entwickelten Ländern und den kleinen Inselstaaten, erheblich *erhöhen',
                  },
                  {
                    id: 28,
                    sdgId: 3,
                    externalId: 'd',
                    label:
                      'Stärkung der Kapazitäten aller Länder, insbesondere der Entwicklungsländer, für Frühwarnung, *Risikominderung und Management von nationalen und globalen Gesundheitsrisiken*',
                  },
                ],
              },
              4: {
                id: 4,
                title:
                  '*Gewährleistung einer inklusiven und gerechten, hochwertigen Bildung und Förderung von Möglichkeiten des lebenslangen Lernens für alle.',
                short: 'Qualität der Bildung',
                colorInfo: {
                  hex: '#c5192d',
                  rgb: [197, 25, 45],
                },
                keywords: {
                  tags: ['Erziehung'],
                  descriptions: [
                    'Fähigkeiten',
                    'technische Bildung',
                    'Berufsbildung',
                    'Ausbildung',
                    'Hochschulbildung',
                    'Wissen',
                    'Werte',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_4.png',
                targets: [
                  {
                    id: 29,
                    sdgId: 4,
                    externalId: 1,
                    label:
                      'Bis 2030 sicherstellen, dass alle Mädchen und Jungen eine kostenlose, gerechte und hochwertige *Primar- und Sekundarschulbildung* abschließen, die zu relevanten und effektiven Lernergebnissen führt',
                  },
                  {
                    id: 30,
                    sdgId: 4,
                    externalId: 2,
                    label:
                      'Bis 2030 sicherstellen, dass alle Mädchen und Jungen *Zugang* zu qualitativ hochwertiger frühkindlicher Entwicklung, *Betreuung und Vorschulerziehung* haben, so dass sie auf die Grundschulbildung vorbereitet sind',
                  },
                  {
                    id: 31,
                    sdgId: 4,
                    externalId: 3,
                    label:
                      'Bis 2030 Gewährleistung des gleichberechtigten Zugangs aller Frauen und Männer zu erschwinglicher und hochwertiger technischer, *beruflicher und tertiärer Bildung*, einschließlich Hochschulbildung',
                  },
                  {
                    id: 32,
                    sdgId: 4,
                    externalId: 4,
                    label:
                      'bis 2030 die Zahl der Jugendlichen und Erwachsenen, die über die für Beschäftigung, menschenwürdige Arbeit und Unternehmertum erforderlichen Qualifikationen*, einschließlich technischer und beruflicher Qualifikationen, verfügen, erheblich* erhöhen',
                  },
                  {
                    id: 33,
                    sdgId: 4,
                    externalId: 5,
                    label:
                      'Bis 2030: *Beseitigung der geschlechtsspezifischen Ungleichheiten in der Bildung* und Gewährleistung des gleichberechtigten Zugangs zu allen Ebenen der allgemeinen und beruflichen Bildung für schutzbedürftige Personen, einschließlich Menschen mit Behinderungen, indigene Völker und Kinder in prekären Situationen',
                  },
                  {
                    id: 34,
                    sdgId: 4,
                    externalId: 6,
                    label:
                      'Bis 2030 sicherstellen, dass alle Jugendlichen und ein erheblicher Anteil der Erwachsenen, sowohl Männer als auch Frauen, *Lese-, Schreib- und Rechenkenntnisse* erlangen',
                  },
                  {
                    id: 35,
                    sdgId: 4,
                    externalId: 7,
                    label:
                      'bis 2030 sicherstellen, dass alle Lernenden *die Kenntnisse und Fähigkeiten* erwerben, die zur Förderung der nachhaltigen Entwicklung erforderlich sind, unter anderem durch Bildung für nachhaltige Entwicklung und nachhaltige Lebensstile, Menschenrechte, Gleichstellung der Geschlechter, Förderung einer Kultur des Friedens und der Gewaltlosigkeit, Weltbürgertum und Wertschätzung der kulturellen Vielfalt und des Beitrags der Kultur zur nachhaltigen Entwicklung',
                  },
                  {
                    id: 36,
                    sdgId: 4,
                    externalId: 'a',
                    label:
                      '*Bau und Modernisierung von Bildungseinrichtungen*, die kinder-, behinderten- und geschlechtsspezifisch sind und ein sicheres, gewaltfreies, integratives und effektives Lernumfeld für alle bieten',
                  },
                  {
                    id: 37,
                    sdgId: 4,
                    externalId: 'b',
                    label:
                      'bis 2020 die Zahl der Stipendien, die Entwicklungsländern, insbesondere den am wenigsten entwickelten Ländern, den kleinen Inselstaaten unter den Entwicklungsländern und den afrikanischen Ländern, für die Aufnahme eines Studiums der Hochschulbildung, einschließlich der Berufsausbildung und der Programme in den Bereichen Informations- und Kommunikationstechnologie, Technik, Ingenieurwesen und Naturwissenschaften, in den Industrieländern und anderen Entwicklungsländern zur Verfügung stehen, weltweit erheblich * ausweiten',
                  },
                  {
                    id: 38,
                    sdgId: 4,
                    externalId: 'c',
                    label:
                      'bis 2030 das Angebot an qualifizierten Lehrkräften* deutlich erhöhen, auch durch internationale Zusammenarbeit bei der Lehrerausbildung in Entwicklungsländern, insbesondere in den am wenigsten entwickelten Ländern und kleinen Inselstaaten unter den Entwicklungsländern',
                  },
                ],
              },
              5: {
                id: 5,
                title:
                  '*Gleichstellung der Geschlechter und Stärkung der Rolle aller Frauen und Mädchen*',
                short: 'Gleichberechtigung der Geschlechter',
                colorInfo: {
                  hex: '#ff3a21',
                  rgb: [255, 58, 33],
                },
                keywords: {
                  tags: ['Geschlecht', 'Frauen', 'Mädchen'],
                  descriptions: [
                    'Ermächtigung von Frauen',
                    'Ermächtigung von Mädchen',
                    'Diskriminierung von Frauen',
                    'Gewalt gegen Frauen',
                    'sexuelle Gesundheit',
                    'Reproduktionsgesundheit',
                    'reproduktive Rechte',
                    'unbezahlte Arbeit',
                    'Zugang zu produktiven Ressourcen',
                    'gleiche Beteiligung',
                    'politisches Leben',
                    'Wirtschaftsleben',
                    'öffentliches Leben',
                  ],
                  groups: ['Frauen', 'Mädchen'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_5.png',
                targets: [
                  {
                    id: 39,
                    sdgId: 5,
                    externalId: 1,
                    label:
                      'Beendigung aller Formen der *Diskriminierung von Frauen und Mädchen* überall',
                  },
                  {
                    id: 40,
                    sdgId: 5,
                    externalId: 2,
                    label:
                      '*Beseitigung aller Formen von Gewalt* gegen alle Frauen und Mädchen im öffentlichen und privaten Bereich, einschließlich Menschenhandel sowie sexueller und anderer Formen der Ausbeutung',
                  },
                  {
                    id: 41,
                    sdgId: 5,
                    externalId: 3,
                    label:
                      'Abschaffung aller schädlichen Praktiken wie Kinder-, Früh- und *Zwangsheirat* sowie Genitalverstümmelung bei Frauen',
                  },
                  {
                    id: 42,
                    sdgId: 5,
                    externalId: 4,
                    label:
                      'Anerkennung und Wertschätzung von *unbezahlter Pflege- und Hausarbeit* durch die Bereitstellung von öffentlichen Dienstleistungen, Infrastrukturen und Sozialschutzmaßnahmen sowie die Förderung von geteilter Verantwortung im Haushalt und in der Familie, soweit dies auf nationaler Ebene angemessen ist',
                  },
                  {
                    id: 43,
                    sdgId: 5,
                    externalId: 5,
                    label:
                      'Gewährleistung der uneingeschränkten und effektiven Teilhabe von Frauen und *gleiche Chancen auf Führungspositionen* auf allen Entscheidungsebenen im politischen, wirtschaftlichen und öffentlichen Leben',
                  },
                  {
                    id: 44,
                    sdgId: 5,
                    externalId: 6,
                    label:
                      'Gewährleistung des *universellen Zugangs zu sexueller und reproduktiver Gesundheit und reproduktiven Rechten* gemäß dem Aktionsprogramm der Internationalen Konferenz über Bevölkerung und Entwicklung und der Aktionsplattform von Peking sowie den Ergebnisdokumenten ihrer Überprüfungskonferenzen',
                  },
                  {
                    id: 45,
                    sdgId: 5,
                    externalId: 'a',
                    label:
                      'Reformen durchführen, um Frauen *gleiche Rechte auf wirtschaftliche Ressourcen* sowie Zugang zu Eigentum und Kontrolle über Land und andere Formen von Eigentum, Finanzdienstleistungen, Erbschaften und natürliche Ressourcen im Einklang mit den nationalen Gesetzen zu gewähren',
                  },
                  {
                    id: 46,
                    sdgId: 5,
                    externalId: 'b',
                    label:
                      'Verstärkter Einsatz von Grundlagentechnologien, insbesondere der Informations- und Kommunikationstechnologie, zur *Förderung der Selbstbestimmung von Frauen*',
                  },
                  {
                    id: 47,
                    sdgId: 5,
                    externalId: 'c',
                    label:
                      'Verabschiedung und Stärkung solider politischer Maßnahmen und durchsetzbarer Rechtsvorschriften zur *Förderung der Gleichstellung der Geschlechter* und der Stärkung der Rolle der Frauen und Mädchen auf allen Ebenen',
                  },
                ],
              },
              6: {
                id: 6,
                title:
                  '*Verfügbarkeit und nachhaltige Bewirtschaftung von Wasser und sanitären Einrichtungen für alle sicherstellen',
                short: 'Sauberes Wasser und sanitäre Einrichtungen',
                colorInfo: {
                  hex: '#26bde2',
                  rgb: [38, 189, 226],
                },
                keywords: {
                  tags: ['Wasser', 'Abwasser'],
                  descriptions: [
                    'trinkwasser',
                    'Hygiene',
                    'Wasserressourcen',
                    'Wasserwirtschaft',
                    'Sanitärmanagement',
                  ],
                  groups: [
                    'internationale Zusammenarbeit',
                    'lokale Gemeinschaften',
                  ],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_6.png',
                targets: [
                  {
                    id: 48,
                    sdgId: 6,
                    externalId: 1,
                    label:
                      'Bis 2030 allgemeinen und gerechten *Zugang zu sicherem und erschwinglichem Trinkwasser für alle* erreichen',
                  },
                  {
                    id: 49,
                    sdgId: 6,
                    externalId: 2,
                    label:
                      'bis 2030 Zugang zu angemessener und gerechter **Sanitärversorgung und Hygiene** für alle und Beendigung der offenen Defäkation, unter besonderer Berücksichtigung der Bedürfnisse von Frauen und Mädchen und Menschen in gefährdeten Situationen',
                  },
                  {
                    id: 50,
                    sdgId: 6,
                    externalId: 3,
                    label:
                      'Bis 2030 *Verbesserung der Wasserqualität* durch Verringerung der Verschmutzung, Beseitigung der Verklappung und Minimierung der Freisetzung gefährlicher Chemikalien und Materialien, Halbierung des Anteils der unbehandelten Abwässer und erhebliche Steigerung des Recyclings und der sicheren Wiederverwendung weltweit',
                  },
                  {
                    id: 51,
                    sdgId: 6,
                    externalId: 4,
                    label:
                      'bis 2030 die Effizienz der Wassernutzung in allen Sektoren erheblich steigern und eine nachhaltige Entnahme und Versorgung mit Süßwasser sicherstellen, um die Wasserknappheit zu bekämpfen und die Zahl der Menschen, die unter Wasserknappheit leiden, erheblich zu verringern',
                  },
                  {
                    id: 52,
                    sdgId: 6,
                    externalId: 5,
                    label:
                      'Bis 2030: Umsetzung einer integrierten *Wasserressourcenbewirtschaftung* auf allen Ebenen, gegebenenfalls auch durch grenzüberschreitende Zusammenarbeit',
                  },
                  {
                    id: 53,
                    sdgId: 6,
                    externalId: 6,
                    label:
                      'Bis 2020: Schutz und *Wiederherstellung wasserbezogener Ökosysteme*, einschließlich Bergen, Wäldern, Feuchtgebieten, Flüssen, Grundwasserleitern und Seen',
                  },
                  {
                    id: 54,
                    sdgId: 6,
                    externalId: 'a',
                    label:
                      'bis 2030 Ausbau der internationalen Zusammenarbeit und *Unterstützung der Entwicklungsländer* beim Aufbau von Kapazitäten für *Wasser- und Abwasseraktivitäten* und -programme, einschließlich Wassergewinnung, Entsalzung, Wassereffizienz, Abwasserbehandlung, Recycling und Wiederverwendungstechnologien',
                  },
                  {
                    id: 55,
                    sdgId: 6,
                    externalId: 'b',
                    label:
                      'Unterstützung und Stärkung der Beteiligung lokaler Gemeinschaften an der Verbesserung der *Wasser- und Abwasserbewirtschaftung*',
                  },
                ],
              },
              7: {
                id: 7,
                title:
                  '*Zugang zu erschwinglicher, zuverlässiger, nachhaltiger und moderner Energie für alle sicherstellen*',
                short: 'Erschwingliche und saubere Energie',
                colorInfo: {
                  hex: '#fcc30b',
                  rgb: [252, 195, 11],
                },
                keywords: {
                  tags: ['Energie'],
                  descriptions: ['Energiezugang', 'erneuerbare Energie'],
                  groups: ['internationale Zusammenarbeit'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_7.png',
                targets: [
                  {
                    id: 56,
                    sdgId: 7,
                    externalId: 1,
                    label:
                      'Bis 2030: Gewährleistung des allgemeinen Zugangs zu *erschwinglichen, zuverlässigen und modernen Energiedienstleistungen*.',
                  },
                  {
                    id: 57,
                    sdgId: 7,
                    externalId: 2,
                    label:
                      'Bis 2030 den *Anteil der erneuerbaren Energien* am globalen Energiemix deutlich erhöhen',
                  },
                  {
                    id: 58,
                    sdgId: 7,
                    externalId: 3,
                    label:
                      'Bis 2030 Verdoppelung der weltweiten Rate der *Verbesserung der Energieeffizienz*',
                  },
                  {
                    id: 59,
                    sdgId: 7,
                    externalId: 'a',
                    label:
                      'bis 2030 die internationale Zusammenarbeit ausbauen, um den *Zugang zu Forschung und Technologie im Bereich der sauberen Energie* zu erleichtern, einschließlich erneuerbarer Energien, Energieeffizienz und fortschrittlicher und sauberer Technologie für fossile Brennstoffe, und Investitionen in Energieinfrastruktur und saubere Energietechnologie fördern',
                  },
                  {
                    id: 60,
                    sdgId: 7,
                    externalId: 'b',
                    label:
                      'Bis 2030 Ausbau der Infrastruktur und Verbesserung der Technologie für die *Versorgung mit modernen und nachhaltigen Energiedienstleistungen* für alle in den Entwicklungsländern, insbesondere in den am wenigsten entwickelten Ländern, den kleinen Inselstaaten und den eingeschlossenen Entwicklungsländern, im Einklang mit ihren jeweiligen Förderprogrammen',
                  },
                ],
              },
              8: {
                id: 8,
                title:
                  '*Förderung von dauerhaftem, integrativem und nachhaltigem Wirtschaftswachstum, *produktiver Vollbeschäftigung und menschenwürdiger Arbeit* für alle*',
                short: 'Menschenwürdige Arbeitsplätze und Wirtschaftswachstum',
                colorInfo: {
                  hex: '#a21942',
                  rgb: [162, 25, 66],
                },
                keywords: {
                  tags: [
                    'Wirtschaftswachstum',
                    'Beschäftigung',
                    'menschenwürdige Arbeit',
                  ],
                  descriptions: [
                    'Zwangsarbeit',
                    'Menschenschmuggel',
                    'Kinderarbeit',
                  ],
                  groups: ['Kinder'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_8.png',
                targets: [
                  {
                    id: 61,
                    sdgId: 8,
                    externalId: 1,
                    label:
                      'Aufrechterhaltung eines *Wirtschaftswachstums pro Kopf* im Einklang mit den nationalen Gegebenheiten und insbesondere ein jährliches Wachstum des Bruttoinlandsprodukts von mindestens 7 % in den am wenigsten entwickelten Ländern',
                  },
                  {
                    id: 62,
                    sdgId: 8,
                    externalId: 2,
                    label:
                      'Erzielung *höherer wirtschaftlicher Produktivität* durch Diversifizierung, technologische Modernisierung und Innovation, u.a. durch Konzentration auf Sektoren mit hoher Wertschöpfung und hoher Arbeitsintensität',
                  },
                  {
                    id: 63,
                    sdgId: 8,
                    externalId: 3,
                    label:
                      'Förderung einer entwicklungsorientierten Politik, die produktive Tätigkeiten, die Schaffung von *anständigen Arbeitsplätzen, Unternehmertum, Kreativität und Innovation* unterstützt und die Formalisierung und das Wachstum von Kleinst-, Klein- und Mittelbetrieben fördert, auch durch den Zugang zu Finanzdienstleistungen',
                  },
                  {
                    id: 64,
                    sdgId: 8,
                    externalId: 4,
                    label:
                      'schrittweise Verbesserung der globalen Ressourceneffizienz in Verbrauch und Produktion bis 2030 und Bemühen um eine *Entkopplung von Wirtschaftswachstum und Umweltzerstörung* gemäß dem 10-Jahres-Rahmenprogramm für *nachhaltigen Verbrauch und nachhaltige Produktion*, wobei die Industrieländer die Führung übernehmen',
                  },
                  {
                    id: 65,
                    sdgId: 8,
                    externalId: 5,
                    label:
                      'bis 2030 Vollbeschäftigung und *produktive Beschäftigung und menschenwürdige Arbeit* für alle Frauen und Männer, auch für junge Menschen und Menschen mit Behinderungen, sowie gleiches Entgelt für gleichwertige Arbeit erreichen',
                  },
                  {
                    id: 66,
                    sdgId: 8,
                    externalId: 6,
                    label:
                      'Bis 2020 den Anteil der Jugendlichen, die nicht in Beschäftigung, allgemeiner oder beruflicher Bildung sind, erheblich * reduzieren',
                  },
                  {
                    id: 67,
                    sdgId: 8,
                    externalId: 7,
                    label:
                      'sofortige und wirksame Maßnahmen zu ergreifen, um *Zwangsarbeit abzuschaffen, moderne Sklaverei und Menschenhandel zu beenden* und das Verbot und die Abschaffung der schlimmsten Formen von Kinderarbeit, einschließlich der Rekrutierung und des Einsatzes von Kindersoldaten, sicherzustellen und bis 2025 Kinderarbeit in all ihren Formen zu beenden',
                  },
                  {
                    id: 68,
                    sdgId: 8,
                    externalId: 8,
                    label:
                      '*Schutz der Arbeitnehmerrechte und Förderung eines sicheren Arbeitsumfelds* für alle Arbeitnehmer, einschließlich Wanderarbeitnehmern, insbesondere Migrantinnen, und Arbeitnehmern in prekären Beschäftigungsverhältnissen',
                  },
                  {
                    id: 69,
                    sdgId: 8,
                    externalId: 9,
                    label:
                      'Bis 2030: Entwicklung und Umsetzung von Maßnahmen zur Förderung eines *nachhaltigen Tourismus*, der Arbeitsplätze schafft und die lokale Kultur und Produkte fördert',
                  },
                  {
                    id: 70,
                    sdgId: 8,
                    externalId: 10,
                    label:
                      'Stärkung der Kapazitäten der inländischen Finanzinstitute zur Förderung und Ausweitung des *Zugangs zu Bank-, Versicherungs- und Finanzdienstleistungen für alle*.',
                  },
                  {
                    id: 71,
                    sdgId: 8,
                    externalId: 'a',
                    label:
                      '*Aufstockung der Handelshilfe* für Entwicklungsländer, insbesondere für die am wenigsten entwickelten Länder, u. a. durch den verstärkten integrierten Rahmen für handelsbezogene technische Hilfe für die am wenigsten entwickelten Länder',
                  },
                  {
                    id: 72,
                    sdgId: 8,
                    externalId: 'b',
                    label:
                      'Bis 2020 Entwicklung und Umsetzung einer globalen Strategie für *Jugendbeschäftigung* und Umsetzung des Globalen Beschäftigungspakts der Internationalen Arbeitsorganisation',
                  },
                ],
              },
              9: {
                id: 9,
                title:
                  '*Aufbau einer widerstandsfähigen Infrastruktur, Förderung einer integrativen und *nachhaltigen Industrialisierung* und Förderung von Innovationen',
                short: 'Industrie, Innovation und Infrastruktur',
                colorInfo: {
                  hex: '#fd6925',
                  rgb: [253, 105, 37],
                },
                keywords: {
                  tags: ['Infrastruktur', 'Industrialisierung', 'Innovation'],
                  descriptions: [
                    'Forschung',
                    'Technologie',
                    'technische Unterstützung',
                    'ICT',
                    'Informations- und Kommunikationstechnologie',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_9.png',
                targets: [
                  {
                    id: 73,
                    sdgId: 9,
                    externalId: 1,
                    label:
                      'Entwicklung einer qualitativ hochwertigen, zuverlässigen, *nachhaltigen und widerstandsfähigen Infrastruktur*, einschließlich regionaler und grenzüberschreitender Infrastruktur, zur Förderung der wirtschaftlichen Entwicklung und des menschlichen Wohlergehens, mit Schwerpunkt auf einem erschwinglichen und gerechten Zugang für alle',
                  },
                  {
                    id: 74,
                    sdgId: 9,
                    externalId: 2,
                    label:
                      'eine integrative und *nachhaltige Industrialisierung* zu fördern und bis 2030 den Anteil der Industrie an der Beschäftigung und am Bruttoinlandsprodukt im Einklang mit den nationalen Gegebenheiten deutlich zu erhöhen und ihren Anteil in den am wenigsten entwickelten Ländern zu verdoppeln',
                  },
                  {
                    id: 75,
                    sdgId: 9,
                    externalId: 3,
                    label:
                      'Verbesserung des *Zugangs von kleinen Industrie- und anderen Unternehmen*, insbesondere in Entwicklungsländern, zu Finanzdienstleistungen, einschließlich erschwinglicher Kredite, und ihrer Integration in Wertschöpfungsketten und Märkte',
                  },
                  {
                    id: 76,
                    sdgId: 9,
                    externalId: 4,
                    label:
                      'Bis 2030 Modernisierung der Infrastruktur und Umrüstung der Industrie, um sie nachhaltig zu machen, mit erhöhter *Ressourceneffizienz* und verstärkter *Einführung sauberer und umweltverträglicher Technologien und Industrieprozesse*, wobei alle Länder entsprechend ihren jeweiligen Möglichkeiten Maßnahmen ergreifen',
                  },
                  {
                    id: 77,
                    sdgId: 9,
                    externalId: 5,
                    label:
                      '*Verbesserung der wissenschaftlichen Forschung*, Verbesserung der technologischen Fähigkeiten der Industriesektoren in allen Ländern, insbesondere in den Entwicklungsländern, einschließlich der Förderung der Innovation bis 2030 und einer deutlichen Erhöhung der Zahl der Beschäftigten in Forschung und Entwicklung pro 1 Million Einwohner sowie der öffentlichen und privaten Ausgaben für Forschung und Entwicklung',
                  },
                  {
                    id: 78,
                    sdgId: 9,
                    externalId: 'a',
                    label:
                      'Erleichterung einer nachhaltigen und widerstandsfähigen *Infrastrukturentwicklung in Entwicklungsländern* durch verstärkte finanzielle, technologische und technische Unterstützung für afrikanische Länder, die am wenigsten entwickelten Länder, Binnenentwicklungsländer und kleine Inselentwicklungsstaaten',
                  },
                  {
                    id: 79,
                    sdgId: 9,
                    externalId: 'b',
                    label:
                      'Unterstützung der einheimischen Technologieentwicklung, *Forschung und Innovation* in den Entwicklungsländern, u.a. durch die Gewährleistung eines günstigen politischen Umfelds für u.a. die industrielle Diversifizierung und den Mehrwert von Rohstoffen',
                  },
                  {
                    id: 80,
                    sdgId: 9,
                    externalId: 'c',
                    label:
                      'den *Zugang zur Informations- und Kommunikationstechnologie* deutlich zu verbessern und in den am wenigsten entwickelten Ländern bis 2020 einen universellen und erschwinglichen Internetzugang zu schaffen',
                  },
                ],
              },
              10: {
                id: 10,
                title:
                  '*Verringerung der Ungleichheit innerhalb und zwischen den Ländern*',
                short: 'Ungleichheiten abbauen',
                colorInfo: {
                  hex: '#dd1367',
                  rgb: [221, 19, 103],
                },
                keywords: {
                  tags: ['Ungleichheit'],
                  descriptions: [
                    'Einkommen',
                    'Sex',
                    'Alter',
                    'Behinderung',
                    'Rennen',
                    'Klasse',
                    'Ethnizität',
                    'Religion',
                    'Migration',
                    'Entwicklungshilfe',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_10.png',
                targets: [
                  {
                    id: 81,
                    sdgId: 10,
                    externalId: 1,
                    label:
                      'Bis 2030 schrittweise Erzielung und *Nachhaltigkeit des Einkommenswachstums* der untersten 40 % der Bevölkerung mit einer über dem nationalen Durchschnitt liegenden Rate',
                  },
                  {
                    id: 82,
                    sdgId: 10,
                    externalId: 2,
                    label:
                      'bis 2030 die soziale, wirtschaftliche und politische Eingliederung* aller Menschen ungeachtet ihres Alters, ihres Geschlechts, ihrer Behinderung, ihrer Rasse, ihrer ethnischen Zugehörigkeit, ihrer Herkunft, ihrer Religion oder ihres wirtschaftlichen oder sonstigen Status zu ermöglichen und zu fördern',
                  },
                  {
                    id: 83,
                    sdgId: 10,
                    externalId: 3,
                    label:
                      'Gewährleistung von *Chancengleichheit und Abbau von Ergebnisungleichheiten*, u. a. durch die Beseitigung diskriminierender Gesetze, Politiken und Praktiken und die Förderung geeigneter Rechtsvorschriften, Politiken und Maßnahmen in diesem Bereich',
                  },
                  {
                    id: 84,
                    sdgId: 10,
                    externalId: 4,
                    label:
                      'Politische Maßnahmen ergreifen, insbesondere in den Bereichen Steuern, Löhne und Sozialschutz, und schrittweise *eine größere Gleichheit* erreichen',
                  },
                  {
                    id: 85,
                    sdgId: 10,
                    externalId: 5,
                    label:
                      'Verbesserung der Regulierung und *Überwachung der globalen Finanzmärkte* und -institutionen sowie Stärkung der Umsetzung dieser Vorschriften',
                  },
                  {
                    id: 86,
                    sdgId: 10,
                    externalId: 6,
                    label:
                      'Gewährleistung einer *stärkeren Vertretung und Mitsprache der Entwicklungsländer* bei der Entscheidungsfindung in den globalen internationalen Wirtschafts- und Finanzinstitutionen, um wirksamere, glaubwürdigere, rechenschaftspflichtige und legitimere Institutionen zu schaffen',
                  },
                  {
                    id: 87,
                    sdgId: 10,
                    externalId: 7,
                    label:
                      'Erleichterung einer geordneten, sicheren, regelmäßigen und *verantwortungsvollen Migration* und Mobilität von Menschen, unter anderem durch die Umsetzung einer geplanten und gut gesteuerten Migrationspolitik',
                  },
                  {
                    id: 88,
                    sdgId: 10,
                    externalId: 'a',
                    label:
                      'Umsetzung des Grundsatzes der besonderen und differenzierten Behandlung der Entwicklungsländer, insbesondere der am wenigsten entwickelten Länder, im Einklang mit den Vereinbarungen der Welthandelsorganisation',
                  },
                  {
                    id: 89,
                    sdgId: 10,
                    externalId: 'b',
                    label:
                      'Förderung der öffentlichen Entwicklungshilfe und der Finanzströme, einschließlich * ausländischer Direktinvestitionen, in die Staaten, in denen der Bedarf am größten ist*, insbesondere in die am wenigsten entwickelten Länder, die afrikanischen Länder, die kleinen Inselstaaten und die Binnenentwicklungsländer, im Einklang mit ihren nationalen Plänen und Programmen',
                  },
                  {
                    id: 90,
                    sdgId: 10,
                    externalId: 'c',
                    label:
                      'bis 2030 die *Transaktionskosten für Überweisungen von Migranten* auf weniger als 3 % zu senken und Überweisungskorridore mit Kosten von mehr als 5 % zu beseitigen',
                  },
                ],
              },
              11: {
                id: 11,
                title:
                  '*Städte und menschliche Siedlungen integrativ, sicher, widerstandsfähig und nachhaltig machen*.',
                short: 'Nachhaltige Städte und Gemeinden',
                colorInfo: {
                  hex: '#fd9d24',
                  rgb: [253, 157, 36],
                },
                keywords: {
                  tags: ['Städte', 'menschliche Siedlungen'],
                  descriptions: [
                    'gemeinschaftlicher Zusammenhalt',
                    'persönliche Sicherheit',
                    'Innovation',
                    'Beschäftigung',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_11.png',
                targets: [
                  {
                    id: 91,
                    sdgId: 11,
                    externalId: 1,
                    label:
                      'Bis 2030 Zugang für alle zu angemessenen, *sicheren und erschwinglichen Wohnungen* und grundlegenden Dienstleistungen gewährleisten und Slums sanieren',
                  },
                  {
                    id: 92,
                    sdgId: 11,
                    externalId: 2,
                    label:
                      'bis 2030 Zugang zu sicheren, erschwinglichen, zugänglichen und *nachhaltigen Verkehrssystemen für alle*, Verbesserung der Straßenverkehrssicherheit, insbesondere durch den Ausbau des öffentlichen Verkehrs, unter besonderer Berücksichtigung der Bedürfnisse von Menschen in prekären Situationen, Frauen, Kindern, Menschen mit Behinderungen und älteren Menschen',
                  },
                  {
                    id: 93,
                    sdgId: 11,
                    externalId: 3,
                    label:
                      'Bis 2030 Stärkung einer inklusiven und *nachhaltigen Urbanisierung* und der Kapazitäten für eine partizipative, integrierte und nachhaltige Siedlungsplanung und -verwaltung in allen Ländern',
                  },
                  {
                    id: 94,
                    sdgId: 11,
                    externalId: 4,
                    label:
                      'Verstärkte Anstrengungen zum Schutz und zur *Sicherung des Kultur- und Naturerbes der Welt*',
                  },
                  {
                    id: 95,
                    sdgId: 11,
                    externalId: 5,
                    label:
                      'bis zum Jahr 2030 die Zahl der Todesopfer* und der betroffenen Menschen deutlich zu senken und die direkten wirtschaftlichen Verluste im Verhältnis zum globalen Bruttoinlandsprodukt, die durch Katastrophen, einschließlich wasserbedingter Katastrophen, verursacht werden, erheblich zu verringern, wobei der Schwerpunkt auf dem Schutz der Armen und der Menschen in gefährdeten Situationen liegt',
                  },
                  {
                    id: 96,
                    sdgId: 11,
                    externalId: 6,
                    label:
                      'Bis 2030 Verringerung der nachteiligen *Pro-Kopf-Umweltauswirkungen von Städten*, u. a. durch besondere Berücksichtigung der Luftqualität und der Bewirtschaftung von Siedlungsabfällen und anderen Abfällen',
                  },
                  {
                    id: 97,
                    sdgId: 11,
                    externalId: 7,
                    label:
                      'bis 2030 den allgemeinen Zugang zu sicheren, inklusiven und zugänglichen *Grünflächen und öffentlichen Räumen*, insbesondere für Frauen und Kinder, ältere Menschen und Menschen mit Behinderungen, gewährleisten',
                  },
                  {
                    id: 98,
                    sdgId: 11,
                    externalId: 'a',
                    label:
                      'Förderung positiver wirtschaftlicher, sozialer und ökologischer Verbindungen zwischen städtischen, stadtnahen und ländlichen Gebieten durch Stärkung der *nationalen und regionalen Entwicklungsplanung*.',
                  },
                  {
                    id: 99,
                    sdgId: 11,
                    externalId: 'b',
                    label:
                      'bis 2020 die Zahl der Städte und Siedlungen, die *integrierte Strategien und Pläne für Inklusion*, Ressourceneffizienz, Abschwächung des Klimawandels und Anpassung an den Klimawandel sowie Widerstandsfähigkeit gegenüber Katastrophen annehmen und umsetzen, erheblich erhöhen und im Einklang mit dem Sendai-Rahmen für die Verringerung des Katastrophenrisikos 2015-2030 ein ganzheitliches Katastrophenrisikomanagement auf allen Ebenen entwickeln und umsetzen',
                  },
                  {
                    id: 100,
                    sdgId: 11,
                    externalId: 'c',
                    label:
                      '*Unterstützung der am wenigsten entwickelten Länder*, auch durch finanzielle und technische Hilfe, beim Bau nachhaltiger und widerstandsfähiger Gebäude unter Verwendung lokaler Materialien',
                  },
                ],
              },
              12: {
                id: 12,
                title:
                  '*Sicherstellung *nachhaltiger Konsum- und Produktionsmuster*',
                short: 'Verantwortungsbewusster Konsum und Produktion',
                colorInfo: {
                  hex: '#c9992d',
                  rgb: [201, 153, 45],
                },
                keywords: {
                  tags: ['Verbrauch', 'Produktion'],
                  descriptions: ['Giftige Stoffe', 'Umwelt'],
                  groups: ['internationale Vereinbarungen'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_12.png',
                targets: [
                  {
                    id: 101,
                    sdgId: 12,
                    externalId: 1,
                    label:
                      'Umsetzung des 10-Jahres-Rahmenprogramms für *nachhaltigen Verbrauch und nachhaltige Produktion*, wobei alle Länder Maßnahmen ergreifen, wobei die Industrieländer die Führung übernehmen und die Entwicklung und die Fähigkeiten der Entwicklungsländer berücksichtigt werden',
                  },
                  {
                    id: 102,
                    sdgId: 12,
                    externalId: 2,
                    label:
                      'Bis 2030: nachhaltige Bewirtschaftung und *effiziente Nutzung der natürlichen Ressourcen*.',
                  },
                  {
                    id: 103,
                    sdgId: 12,
                    externalId: 3,
                    label:
                      'Bis 2030 *Halbierung* der weltweiten *Lebensmittelverschwendung* pro Kopf im Einzelhandel und beim Verbraucher und *Verringerung* der Lebensmittelverluste entlang der Produktions- und Lieferketten, einschließlich der Nachernteverluste',
                  },
                  {
                    id: 104,
                    sdgId: 12,
                    externalId: 4,
                    label:
                      'bis 2020 eine *umweltverträgliche Bewirtschaftung von Chemikalien* und allen Abfällen während ihres gesamten Lebenszyklus in Übereinstimmung mit den vereinbarten internationalen Rahmenregelungen erreichen und ihre Freisetzung in Luft, Wasser und Boden erheblich *verringern*, um ihre schädlichen Wirkungen auf die menschliche Gesundheit und die Umwelt zu minimieren',
                  },
                  {
                    id: 105,
                    sdgId: 12,
                    externalId: 5,
                    label:
                      'Bis 2030 deutliche *Reduzierung des Abfallaufkommens* durch Vermeidung, Verringerung, Recycling und Wiederverwendung',
                  },
                  {
                    id: 106,
                    sdgId: 12,
                    externalId: 6,
                    label:
                      'Ermutigung von Unternehmen, insbesondere von großen und transnationalen Unternehmen, zur Einführung *nachhaltiger Praktiken* und zur Integration von Nachhaltigkeitsinformationen in ihren Berichtszyklus',
                  },
                  {
                    id: 107,
                    sdgId: 12,
                    externalId: 7,
                    label:
                      '*Förderung nachhaltiger öffentlicher Beschaffungspraktiken* im Einklang mit nationalen Politiken und Prioritäten',
                  },
                  {
                    id: 108,
                    sdgId: 12,
                    externalId: 8,
                    label:
                      'Bis 2030 sicherstellen, dass die Menschen überall über die relevanten Informationen und das Bewusstsein für eine *nachhaltige Entwicklung und Lebensweise* im Einklang mit der Natur verfügen',
                  },
                  {
                    id: 109,
                    sdgId: 12,
                    externalId: 'a',
                    label:
                      'Unterstützung der Entwicklungsländer beim Ausbau ihrer wissenschaftlichen und technologischen Kapazitäten, um zu *nachhaltigeren Verbrauchs- und Produktionsmustern* zu gelangen',
                  },
                  {
                    id: 110,
                    sdgId: 12,
                    externalId: 'b',
                    label:
                      'Entwicklung und Umsetzung von Instrumenten zur *Überwachung der Wirkungen einer nachhaltigen Entwicklung* für einen nachhaltigen Tourismus, der Arbeitsplätze schafft und die lokale Kultur und Produkte fördert',
                  },
                  {
                    id: 111,
                    sdgId: 12,
                    externalId: 'c',
                    label:
                      'Rationalisierung *ineffizienter Subventionen für fossile Brennstoffe*, die einen verschwenderischen Verbrauch begünstigen, durch Beseitigung von Marktverzerrungen im Einklang mit den nationalen Gegebenheiten, einschließlich der Umstrukturierung der Besteuerung und der schrittweisen Abschaffung dieser schädlichen Subventionen, wo sie bestehen, um ihre Umweltauswirkungen widerzuspiegeln, wobei die spezifischen Bedürfnisse und Bedingungen der Entwicklungsländer in vollem Umfang berücksichtigt und die möglichen negativen Wirkungen auf ihre Entwicklung in einer Weise minimiert werden, die die Armen und die betroffenen Gemeinschaften schützt',
                  },
                ],
              },
              13: {
                id: 13,
                title:
                  '*Ergreifen Sie dringend Maßnahmen zur Bekämpfung des Klimawandels und seiner Wirkungen*.',
                short: 'Klima-Aktion',
                colorInfo: {
                  hex: '#3f7e44',
                  rgb: [63, 126, 68],
                },
                keywords: {
                  tags: ['Klimawandel'],
                  descriptions: ['Resilienz', 'Naturkatastrophen'],
                  groups: ['ärmste', 'gefährdete'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_13.png',
                targets: [
                  {
                    id: 112,
                    sdgId: 13,
                    externalId: 1,
                    label:
                      'Stärkung der Widerstandsfähigkeit und *Anpassungsfähigkeit gegenüber klimabedingten Gefahren* und Naturkatastrophen in allen Ländern',
                  },
                  {
                    id: 113,
                    sdgId: 13,
                    externalId: 2,
                    label:
                      '*Integration von Maßnahmen zum Klimawandel* in nationale Politiken, Strategien und Planung',
                  },
                  {
                    id: 114,
                    sdgId: 13,
                    externalId: 3,
                    label:
                      'Verbesserung der Bildung, der Sensibilisierung und der personellen und institutionellen Kapazitäten für *Klimaschutz, Anpassung*, Verringerung der Wirkungen und Frühwarnung',
                  },
                  {
                    id: 115,
                    sdgId: 13,
                    externalId: 'a',
                    label:
                      'Umsetzung der von den Industrieländern im Rahmen des Rahmenübereinkommens der Vereinten Nationen über Klimaänderungen eingegangenen Verpflichtung, bis 2020 gemeinsam 100 Mrd. USD jährlich aus allen Quellen zu mobilisieren, um die Bedürfnisse der Entwicklungsländer im Zusammenhang mit sinnvollen Maßnahmen zur Eindämmung des Klimawandels und Transparenz bei der Umsetzung zu erfüllen und den Grünen Klimafonds durch seine Kapitalisierung so bald wie möglich voll funktionsfähig zu machen',
                  },
                  {
                    id: 116,
                    sdgId: 13,
                    externalId: 'b',
                    label:
                      'Förderung von Mechanismen zum *Aufbau von Kapazitäten für eine wirksame Planung* und Bewältigung des Klimawandels in den am wenigsten entwickelten Ländern und kleinen Inselstaaten unter besonderer Berücksichtigung von Frauen, Jugendlichen und lokalen und marginalisierten Gemeinschaften * in Anerkennung der Tatsache, dass das Rahmenübereinkommen der Vereinten Nationen über Klimaänderungen das wichtigste internationale, zwischenstaatliche Forum für die Aushandlung der globalen Reaktion auf den Klimawandel ist.',
                  },
                ],
              },
              14: {
                id: 14,
                title:
                  '*Schutz und nachhaltige Nutzung der Ozeane, Meere und Meeresressourcen für eine nachhaltige Entwicklung*',
                short: 'Leben unter Wasser',
                colorInfo: {
                  hex: '#0a97d9',
                  rgb: [10, 151, 217],
                },
                keywords: {
                  tags: ['Ozeane', 'Meere', 'Meeresressourcen'],
                  descriptions: [
                    'Meeresökosysteme',
                    'Küstenökosysteme',
                    'Meeresverschmutzung',
                  ],
                  groups: [
                    'kleine Inselentwicklungsstaaten',
                    'SIDS',
                    'am wenigsten entwickelte Länder',
                    'LDCs',
                  ],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_14.png',
                targets: [
                  {
                    id: 117,
                    sdgId: 14,
                    externalId: 1,
                    label:
                      'Bis 2025 Verhinderung und deutliche *Reduzierung der Meeresverschmutzung* aller Art, insbesondere durch landgestützte Aktivitäten, einschließlich Meeresmüll und Nährstoffverschmutzung',
                  },
                  {
                    id: 118,
                    sdgId: 14,
                    externalId: 2,
                    label:
                      'Bis 2020 nachhaltige Bewirtschaftung und *Schutz der Meeres- und Küstenökosysteme* zur Vermeidung erheblicher negativer Wirkungen, auch durch Stärkung ihrer Widerstandsfähigkeit, sowie Maßnahmen zu ihrer Wiederherstellung, um gesunde und produktive Ozeane zu erreichen',
                  },
                  {
                    id: 119,
                    sdgId: 14,
                    externalId: 3,
                    label:
                      'Minimierung und *Bewältigung der Wirkungen der Ozeanversauerung*, auch durch verstärkte wissenschaftliche Zusammenarbeit auf allen Ebenen',
                  },
                  {
                    id: 120,
                    sdgId: 14,
                    externalId: 4,
                    label:
                      'bis 2020 wirksame *Regulierung des Fischfangs und Beendigung der Überfischung*, der illegalen, nicht gemeldeten und unregulierten Fischerei und der destruktiven Fischereipraktiken sowie Umsetzung wissenschaftlich fundierter Bewirtschaftungspläne, um die Fischbestände so schnell wie möglich wieder auf ein Niveau zu bringen, das den biologischen Merkmalen entsprechend einen höchstmöglichen Dauerertrag ermöglicht',
                  },
                  {
                    id: 121,
                    sdgId: 14,
                    externalId: 5,
                    label:
                      'bis 2020 * mindestens 10 % der Küsten- und Meeresgebiete* im Einklang mit nationalem und internationalem Recht und auf der Grundlage der besten verfügbaren wissenschaftlichen Informationen erhalten',
                  },
                  {
                    id: 122,
                    sdgId: 14,
                    externalId: 6,
                    label:
                      'Bis 2020 Verbot bestimmter Formen von Fischereisubventionen, die zu Überkapazitäten und Überfischung beitragen, Abschaffung von Subventionen, die zur illegalen, nicht gemeldeten und unregulierten Fischerei beitragen, und Verzicht auf die Einführung neuer Subventionen dieser Art, in der Erkenntnis, dass eine angemessene und wirksame besondere und differenzierte Behandlung der Entwicklungsländer und der am wenigsten entwickelten Länder integraler Bestandteil der Verhandlungen der Welthandelsorganisation über Fischereisubventionen sein sollte',
                  },
                  {
                    id: 123,
                    sdgId: 14,
                    externalId: 7,
                    label:
                      'Bis 2030 * Steigerung des wirtschaftlichen Nutzens für die kleinen Inselentwicklungsländer* und die am wenigsten entwickelten Länder aus der nachhaltigen Nutzung der Meeresressourcen, u. a. durch nachhaltige Bewirtschaftung von Fischerei, Aquakultur und Tourismus',
                  },
                  {
                    id: 124,
                    sdgId: 14,
                    externalId: 'a',
                    label:
                      'Erweiterung der wissenschaftlichen Kenntnisse, *Aufbau von Forschungskapazitäten und Transfer von Meerestechnologie* unter Berücksichtigung der Kriterien und Leitlinien der Zwischenstaatlichen Ozeanographischen Kommission für den Transfer von Meerestechnologie, um die Gesundheit der Meere zu verbessern und den Beitrag der biologischen Vielfalt der Meere zur Entwicklung der Entwicklungsländer, insbesondere der kleinen Inselstaaten und der am wenigsten entwickelten Länder, zu erhöhen',
                  },
                  {
                    id: 125,
                    sdgId: 14,
                    externalId: 'b',
                    label:
                      '*Zugang für kleine handwerkliche Fischer zu den Meeresressourcen und Märkten* schaffen',
                  },
                  {
                    id: 126,
                    sdgId: 14,
                    externalId: 'c',
                    label:
                      'Verbesserung der Erhaltung und *nachhaltigen Nutzung der Ozeane und ihrer Ressourcen* durch die Umsetzung des internationalen Rechts, wie es im UNCLOS zum Ausdruck kommt, das den rechtlichen Rahmen für die Erhaltung und *nachhaltige Nutzung der Ozeane und ihrer Ressourcen* bildet, wie in Absatz 158 von The Future We Want in Erinnerung gerufen wird',
                  },
                ],
              },
              15: {
                id: 15,
                title:
                  '*Schutz, Wiederherstellung und Förderung der nachhaltigen Nutzung terrestrischer Ökosysteme, nachhaltige Bewirtschaftung der Wälder, *Bekämpfung der Wüstenbildung*, Eindämmung und Umkehrung der Bodenverschlechterung und Eindämmung des Verlusts der biologischen Vielfalt*',
                short: 'Leben an Land',
                colorInfo: {
                  hex: '#56c02b',
                  rgb: [86, 192, 43],
                },
                keywords: {
                  tags: [
                    'terrestrische Ökosysteme',
                    'Wälder',
                    'Wüstenbildung',
                    'Land',
                    'biologische Vielfalt',
                  ],
                  descriptions: [
                    'degradierte Flächen',
                    'Waldbewirtschaftung',
                    'natürliche Lebensräume',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_15.png',
                targets: [
                  {
                    id: 127,
                    sdgId: 15,
                    externalId: 1,
                    label:
                      'Bis 2020 Gewährleistung der Erhaltung, Wiederherstellung und *nachhaltigen Nutzung von Land- und Binnensüßwasserökosystemen* und ihrer Leistungen, insbesondere von Wäldern, Feuchtgebieten, Gebirgen und Trockengebieten, im Einklang mit den Verpflichtungen aus internationalen Übereinkommen',
                  },
                  {
                    id: 128,
                    sdgId: 15,
                    externalId: 2,
                    label:
                      'bis 2020 die Umsetzung einer *nachhaltigen Bewirtschaftung aller Arten von Wäldern* fördern, die Entwaldung stoppen, geschädigte Wälder wiederherstellen und die Aufforstung und Wiederaufforstung weltweit erheblich steigern',
                  },
                  {
                    id: 129,
                    sdgId: 15,
                    externalId: 3,
                    label:
                      'Bis 2030: *Bekämpfung der Wüstenbildung*, Wiederherstellung degradierter Flächen und Böden, einschließlich der von Wüstenbildung, Dürre und Überschwemmungen betroffenen Flächen, und Streben nach einer Welt ohne Bodendegradation',
                  },
                  {
                    id: 130,
                    sdgId: 15,
                    externalId: 4,
                    label:
                      'bis 2030 die *Erhaltung der Bergökosysteme*, einschließlich ihrer biologischen Vielfalt, sicherstellen, um ihre Fähigkeit zu verbessern, die für eine nachhaltige Entwicklung wesentlichen Vorteile zu bieten',
                  },
                  {
                    id: 131,
                    sdgId: 15,
                    externalId: 5,
                    label:
                      'Ergreifung dringender und signifikanter Maßnahmen, um *die Verschlechterung natürlicher Lebensräume zu verringern, den Verlust der biologischen Vielfalt zu stoppen* und bis 2020 das Aussterben bedrohter Arten zu schützen und zu verhindern',
                  },
                  {
                    id: 132,
                    sdgId: 15,
                    externalId: 6,
                    label:
                      'Förderung einer fairen und gerechten Aufteilung der Vorteile, die sich aus der *Nutzung genetischer Ressourcen* ergeben, und Förderung eines angemessenen Zugangs zu solchen Ressourcen, wie international vereinbart',
                  },
                  {
                    id: 133,
                    sdgId: 15,
                    externalId: 7,
                    label:
                      'Dringende Maßnahmen ergreifen, um *die Wilderei und den Handel mit geschützten Tier- und Pflanzenarten* zu beenden und sowohl die Nachfrage als auch das Angebot an illegalen Wildtierprodukten zu bekämpfen',
                  },
                  {
                    id: 134,
                    sdgId: 15,
                    externalId: 8,
                    label:
                      'Bis 2020: Einführung von Maßnahmen zur Verhinderung der Einschleppung und zur deutlichen *Reduzierung der Wirkungen invasiver gebietsfremder Arten auf Land- und Wasserökosysteme* sowie Kontrolle oder Ausrottung der prioritären Arten',
                  },
                  {
                    id: 135,
                    sdgId: 15,
                    externalId: 9,
                    label:
                      'Bis 2020: *Integration von Ökosystem- und Biodiversitätswerten* in nationale und lokale Planung, Entwicklungsprozesse, Armutsbekämpfungsstrategien und -rechnungen',
                  },
                  {
                    id: 136,
                    sdgId: 15,
                    externalId: 'a',
                    label:
                      'Mobilisierung und erhebliche Aufstockung der finanziellen Mittel aus allen Quellen zur *Erhaltung und nachhaltigen Nutzung der biologischen Vielfalt und der Ökosysteme*.',
                  },
                  {
                    id: 137,
                    sdgId: 15,
                    externalId: 'b',
                    label:
                      'Mobilisierung erheblicher Mittel aus allen Quellen und auf allen Ebenen zur *Finanzierung einer nachhaltigen Waldbewirtschaftung* und Schaffung angemessener Anreize für die Entwicklungsländer, um eine solche Bewirtschaftung voranzutreiben, auch für die Erhaltung und Wiederaufforstung',
                  },
                  {
                    id: 138,
                    sdgId: 15,
                    externalId: 'c',
                    label:
                      'Verstärkte globale Unterstützung für die Bekämpfung der Wilderei und des illegalen Handels mit geschützten Arten, u. a. durch die Stärkung der Fähigkeit lokaler Gemeinschaften, *nachhaltige Lebensunterhaltsmöglichkeiten* zu schaffen',
                  },
                ],
              },
              16: {
                id: 16,
                title:
                  '*Förderung friedlicher und integrativer Gesellschaften für eine nachhaltige Entwicklung, Zugang zur Justiz für alle und Aufbau wirksamer, rechenschaftspflichtiger und integrativer Institutionen auf allen Ebenen.',
                short: 'Frieden und Gerechtigkeit - Starke Institutionen',
                colorInfo: {
                  hex: '#00689d',
                  rgb: [0, 104, 157],
                },
                keywords: {
                  tags: [
                    'friedliche Gesellschaften',
                    'integrative Gesellschaften',
                    'Justiz',
                    'Einrichtungen',
                  ],
                  descriptions: [
                    'menschenrechte',
                    'Rechtsstaatlichkeit',
                    'Gewalt',
                    'bewaffneter Konflikt',
                    'schwache Institutionen',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_16.png',
                targets: [
                  {
                    id: 139,
                    sdgId: 16,
                    externalId: 1,
                    label:
                      'Signifikante *Reduzierung aller Formen von Gewalt und der damit verbundenen Todesfälle* überall',
                  },
                  {
                    id: 140,
                    sdgId: 16,
                    externalId: 2,
                    label:
                      '*Beendigung von Missbrauch, Ausbeutung, Menschenhandel* und allen Formen von Gewalt gegen Kinder und Folter von Kindern',
                  },
                  {
                    id: 141,
                    sdgId: 16,
                    externalId: 3,
                    label:
                      '*Förderung der Rechtsstaatlichkeit* auf nationaler und internationaler Ebene und Gewährleistung eines gleichberechtigten Zugangs zur Justiz für alle',
                  },
                  {
                    id: 142,
                    sdgId: 16,
                    externalId: 4,
                    label:
                      'Bis 2030 deutliche *Reduzierung der illegalen Finanz- und Waffenströme*, verstärkte Einziehung und Rückgabe gestohlener Vermögenswerte und Bekämpfung aller Formen der organisierten Kriminalität',
                  },
                  {
                    id: 143,
                    sdgId: 16,
                    externalId: 5,
                    label:
                      'Wesentliche *Verringerung von Korruption und Bestechung* in all ihren Formen',
                  },
                  {
                    id: 144,
                    sdgId: 16,
                    externalId: 6,
                    label:
                      'Aufbau *wirksamer, rechenschaftspflichtiger und transparenter Institutionen* auf allen Ebenen',
                  },
                  {
                    id: 145,
                    sdgId: 16,
                    externalId: 7,
                    label:
                      'Gewährleistung einer reaktionsfähigen, integrativen, partizipativen und *repräsentativen Entscheidungsfindung* auf allen Ebenen',
                  },
                  {
                    id: 146,
                    sdgId: 16,
                    externalId: 8,
                    label:
                      'Ausweitung und Stärkung der *Beteiligung der Entwicklungsländer an den Institutionen der Global Governance*',
                  },
                  {
                    id: 147,
                    sdgId: 16,
                    externalId: 9,
                    label:
                      'Bis 2030: *Gesetzliche Identität für alle*, einschließlich Geburtsregistrierung',
                  },
                  {
                    id: 148,
                    sdgId: 16,
                    externalId: 10,
                    label:
                      'Gewährleistung des *Zugangs der Öffentlichkeit zu Informationen und Schutz der Grundfreiheiten* im Einklang mit den nationalen Rechtsvorschriften und internationalen Übereinkommen',
                  },
                  {
                    id: 149,
                    sdgId: 16,
                    externalId: 'a',
                    label:
                      '*Stärkung der einschlägigen nationalen Einrichtungen*, auch durch internationale Zusammenarbeit, zum Aufbau von Kapazitäten auf allen Ebenen, insbesondere in Entwicklungsländern, zur Verhütung von Gewalt und zur Bekämpfung von Terrorismus und Kriminalität',
                  },
                  {
                    id: 150,
                    sdgId: 16,
                    externalId: 'b',
                    label:
                      '*Förderung und Durchsetzung nicht diskriminierender Gesetze und Politiken* für eine nachhaltige Entwicklung',
                  },
                ],
              },
              17: {
                id: 17,
                title:
                  '*Stärkung der Mittel zur Umsetzung und Neubelebung der globalen Partnerschaft für nachhaltige Entwicklung*',
                short: 'Partnerschaften für die Ziele',
                colorInfo: {
                  hex: '#19486a',
                  rgb: [25, 72, 106],
                },
                keywords: {
                  tags: ['Mittel zur Umsetzung', 'Partnerschaften'],
                  descriptions: ['Ressourcenmobilisierung'],
                  groups: ['Entwicklungsländer', 'LDCs'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_17.png',
                targets: [
                  {
                    id: 151,
                    sdgId: 17,
                    externalId: 1,
                    label:
                      '*Stärkung der Mobilisierung inländischer Ressourcen*, auch durch internationale Unterstützung für Entwicklungsländer, zur Verbesserung der inländischen Kapazitäten für die Erhebung von Steuern und anderen Einnahmen',
                  },
                  {
                    id: 152,
                    sdgId: 17,
                    externalId: 2,
                    label:
                      'die Industrieländer, ihre offiziellen *Entwicklungshilfeverpflichtungen* in vollem Umfang zu erfüllen, einschließlich der Verpflichtung vieler Industrieländer, das Ziel von 0,7 Prozent der ODA/BNE für die Entwicklungsländer und von 0,15 bis 0,20 Prozent der ODA/BNE für die am wenigsten entwickelten Länder zu erreichen; die Geber von ODA werden ermutigt, das Ziel zu erwägen, mindestens 0,20 Prozent der ODA/BNE für die am wenigsten entwickelten Länder bereitzustellen',
                  },
                  {
                    id: 153,
                    sdgId: 17,
                    externalId: 3,
                    label:
                      'Mobilisierung zusätzlicher *Finanzmittel für Entwicklungsländer* aus verschiedenen Quellen',
                  },
                  {
                    id: 154,
                    sdgId: 17,
                    externalId: 4,
                    label:
                      '*Unterstützung der Entwicklungsländer bei der Erreichung einer langfristigen Schuldentragfähigkeit* durch eine koordinierte Politik, die darauf abzielt, die Schuldenfinanzierung, den Schuldenerlass und gegebenenfalls die Umschuldung zu fördern, und die Auslandsverschuldung hoch verschuldeter armer Länder anzugehen, um die Schuldenlast zu *verringern*',
                  },
                  {
                    id: 155,
                    sdgId: 17,
                    externalId: 5,
                    label:
                      'Annahme und Umsetzung von *Investitionsförderungsregelungen für die am wenigsten entwickelten Länder*.',
                  },
                  {
                    id: 156,
                    sdgId: 17,
                    externalId: 6,
                    label:
                      'Verbesserung der Nord-Süd-, Süd-Süd- und Dreieckszusammenarbeit *auf regionaler und internationaler Ebene* in den Bereichen Wissenschaft, Technologie und Innovation sowie des Zugangs zu diesen Bereichen und Verbesserung des Wissensaustauschs zu einvernehmlich festgelegten Bedingungen, u. a. durch eine bessere Koordinierung der bestehenden Mechanismen, insbesondere auf Ebene der Vereinten Nationen, und durch einen globalen Mechanismus zur Erleichterung der Technologie',
                  },
                  {
                    id: 157,
                    sdgId: 17,
                    externalId: 7,
                    label:
                      '*Förderung der Entwicklung, des Transfers, der Verbreitung und des Einsatzes umweltfreundlicher Technologien* in Entwicklungsländern zu günstigen Bedingungen, einschließlich Konzessions- und Vorzugsbedingungen, wie gegenseitig vereinbart',
                  },
                  {
                    id: 158,
                    sdgId: 17,
                    externalId: 8,
                    label:
                      'die Technologiebank und den Mechanismus zum Aufbau von Kapazitäten in den Bereichen Wissenschaft, Technologie und *Innovation für die am wenigsten entwickelten Länder* bis 2017 voll einsatzfähig zu machen und die Nutzung von Grundlagentechnologien, insbesondere der Informations- und Kommunikationstechnologie, zu verbessern',
                  },
                  {
                    id: 159,
                    sdgId: 17,
                    externalId: 9,
                    label:
                      'Verstärkung der internationalen Unterstützung für die Umsetzung eines wirksamen und *gezielten Kapazitätsaufbaus in den Entwicklungsländern*, um die nationalen Pläne zur Umsetzung aller Ziele für nachhaltige Entwicklung zu unterstützen, auch durch Nord-Süd-, Süd-Süd- und Dreieckskooperation',
                  },
                  {
                    id: 160,
                    sdgId: 17,
                    externalId: 10,
                    label:
                      'Förderung eines universellen, regelbasierten, offenen, *nichtdiskriminierenden und gerechten multilateralen Handelssystems* im Rahmen der Welthandelsorganisation, auch durch den Abschluss der Verhandlungen im Rahmen der Doha-Entwicklungsagenda',
                  },
                  {
                    id: 161,
                    sdgId: 17,
                    externalId: 11,
                    label:
                      'Deutliche *Steigerung der Ausfuhren der Entwicklungsländer*, insbesondere mit dem Ziel, den Anteil der am wenigsten entwickelten Länder an den weltweiten Ausfuhren bis 2020 zu verdoppeln',
                  },
                  {
                    id: 162,
                    sdgId: 17,
                    externalId: 12,
                    label:
                      'Rechtzeitige Umsetzung des *zoll- und kontingentfreien Marktzugangs auf dauerhafter Basis für alle am wenigsten entwickelten Länder* im Einklang mit den Beschlüssen der Welthandelsorganisation, u.a. durch Gewährleistung transparenter und einfacher Präferenzursprungsregeln für Einfuhren aus den am wenigsten entwickelten Ländern, die zur Erleichterung des Marktzugangs beitragen',
                  },
                  {
                    id: 163,
                    sdgId: 17,
                    externalId: 13,
                    label:
                      'Verbesserung der *globalen makroökonomischen Stabilität*, auch durch politische Koordinierung und Kohärenz',
                  },
                  {
                    id: 164,
                    sdgId: 17,
                    externalId: 14,
                    label:
                      'Verbesserung der *Politikkohärenz für nachhaltige Entwicklung*',
                  },
                  {
                    id: 165,
                    sdgId: 17,
                    externalId: 15,
                    label:
                      '*Respektierung des politischen Handlungsspielraums und der Führungsrolle jedes Landes* bei der Festlegung und Umsetzung politischer Maßnahmen zur Beseitigung der Armut und für eine nachhaltige Entwicklung Partnerschaften mit mehreren Interessengruppen',
                  },
                  {
                    id: 166,
                    sdgId: 17,
                    externalId: 16,
                    label:
                      '*Verstärkung der globalen Partnerschaft für nachhaltige Entwicklung*, ergänzt durch Multi-Stakeholder-Partnerschaften, die Wissen, Fachwissen, Technologie und finanzielle Ressourcen mobilisieren und gemeinsam nutzen, um die Verwirklichung der Ziele für nachhaltige Entwicklung in allen Ländern, insbesondere in den Entwicklungsländern, zu unterstützen',
                  },
                  {
                    id: 167,
                    sdgId: 17,
                    externalId: 17,
                    label:
                      'Ermutigung und Förderung *wirksamer öffentlicher, öffentlich-privater und zivilgesellschaftlicher Partnerschaften*, aufbauend auf den Erfahrungen und Finanzierungsstrategien von Partnerschaften Daten, Überwachung und Rechenschaftspflicht',
                  },
                  {
                    id: 168,
                    sdgId: 17,
                    externalId: 18,
                    label:
                      'bis 2020 die *Unterstützung für den Aufbau von Kapazitäten in den Entwicklungsländern*, einschließlich der am wenigsten entwickelten Länder und der kleinen Inselstaaten unter den Entwicklungsländern, verstärken, um die Verfügbarkeit hochwertiger, aktueller und zuverlässiger Daten, die nach Einkommen, Geschlecht, Alter, Rasse, ethnischer Zugehörigkeit, Migrationsstatus, Behinderung, geografischer Lage und anderen im nationalen Kontext relevanten Merkmalen aufgeschlüsselt sind, erheblich zu verbessern',
                  },
                  {
                    id: 169,
                    sdgId: 17,
                    externalId: 19,
                    label:
                      'bis 2030 auf bestehenden Initiativen aufbauen, um *Messungen der Fortschritte bei der nachhaltigen Entwicklung* zu entwickeln, die das Bruttoinlandsprodukt ergänzen, und den Aufbau statistischer Kapazitäten in den Entwicklungsländern unterstützen',
                  },
                ],
              },
            },
          },
        },
        irisImpactCategories: {
          title: 'Wählen Sie die entsprechenden IRIS+ Wirkungskategorien',
          subtitle: `Bitte wählen Sie die IRIS+-Wirkungskategorien aus, die mit den Wirkungszielen verknüpft sind. Klicken Sie [hier](https://iris.thegiin.org/) für weitere Informationen über IRIS.`,
          categories: [
            { id: 1, label: 'Landwirtschaft' },
            { id: 2, label: 'Luft' },
            { id: 3, label: 'Biodiversität & Ökosysteme' },
            { id: 4, label: 'Klima' },
            { id: 5, label: 'Vielfalt & Inklusion' },
            { id: 6, label: 'Bildung' },
            { id: 7, label: 'Beschäftigung' },
            { id: 8, label: 'Energie' },
            { id: 9, label: 'Finanzdienstleistungen' },
            { id: 10, label: 'Gesundheit' },
            { id: 11, label: 'Immobilien' },
            { id: 12, label: 'Land' },
            { id: 13, label: 'Ozeane & Küstenzonen' },
            { id: 14, label: 'Verschmutzung' },
            { id: 15, label: 'Abfall' },
            { id: 16, label: 'Wasser' },
            { id: 17, label: 'Überkategorie' },
            { id: 18, label: 'Infrastruktur' },
          ],
        },
        impactTargets: {
          header: 'Festlegen von Wirkungszielen',
          title: 'Bitte legen Sie Ziele für Ihre Impact-KPIs fest.',
          subtitle:
            'Wir empfehlen Ihnen, sich Ziele zu setzen und den Fortschritt regelmäßig zu verfolgen, um die Chance zu erhöhen, die Wirkungen zu erreichen. Für jeden KPI können Sie die "Ist"- und "Soll"-Werte für jedes Jahr hinzufügen. Anhand dieser Daten können wir Ihren Fortschritt bei der Erreichung der Wirkungsziele verfolgen.',
        },
      },
    },
    impactSolutions: {
      title: 'Lösungen',
      description:
        'Welche Lösungen bieten Sie an, um diese Wirkung (Impact) zu erzielen?',
      cardTitle: 'Lösung',
      confirmDeleteTitle: 'Lösung löschen',
      confirmDeleteText:
        'Möchten Sie diese Lösung wirklich löschen? Sie können diese Aktion nicht rückgängig machen.',
      deleteCancelButton: 'Abbrechen',
      deleteButton: 'Löschen',
      addSolutionButton: 'Lösung hinzufügen',
      impactSolution: {
        title: 'Beschreiben Sie die Lösung, die eine positive Wirkung erzeugt',
        text: 'In diesem Schritt beschreiben Sie Ihre Lösung. Dies ist Ihr innovatives Produkt oder Ihre innovative Dienstleistung, die zur Verwirklichung Ihrer zuvor definierten Wirkung führen wird.',
        howIxDefinesSolutionsTitle: 'Wie definiert ImpactNexus Lösungen?',
        howIxDefinesSoutionsText: `<p>Lösungen sind die Ergebnisse (Produkte oder Dienstleistungen) Ihres Unternehmens, die erforderlich sind, um die Ergebnisse und Auswirkungen zu erzielen. Wenn Sie eine Lösung hinzufügen, definieren Sie Folgendes:</p>
         <ul>
             <li>Name – Dies wird als Titel der Lösung in Ihren Wirkungsberichten verwendet</li>
             <li>Wie die Lösung funktioniert – Eine kurze Beschreibung Ihrer Lösung, damit die Leute verstehen, worum es sich handelt</li>
             <li>Kunde oder Zielgruppe – Wer wird Ihre Lösung nutzen</li>
             <li>THG-Reduktionspotenzial (optional) – Ein Vergleich mit einem herkömmlichen Produkt oder einer herkömmlichen Dienstleistung, um zu ermitteln, wie viel CO2e Ihre Lösung einsparen könnte</li>
         </ul>`,
        solutionKindTitle: 'Um welche Art von Lösung handelt es sich?',
        solutionKindOptions: [
          { label: 'Produkt', value: 'PRODUCT' },
          { label: 'Dienstleistung', value: 'SERVICE' },
          { label: 'Hybrid', value: 'HYBRID' },
        ],
        solutionConceptTitle: 'Wie heißt Ihre Lösung?',
        solutionConceptSubtitle: `<p>Zum Beispiel:</p>
           <ul>
               <li>Reparierbares modulares Smartphone</li>
               <li>Software zur Beratung von Geschäftsreisen</li>
               <li>Kleine Windkraftanlagen</li>
           </ul>`,
        solutionTargetGroupTitle:
          'Wer ist der Kunde bzw. die Zielgruppe Ihrer Lösung?',
        solutionTargetGroupSubtitle: `<p>Ihre Zielgruppe ist die Bevölkerung oder Personengruppe, die Ihre Lösung unterstützen soll oder die Ihre Lösung nutzen wird. Dabei kann es sich um Einzelpersonen, Gemeinschaften oder Unternehmen handeln, aber auch um eine Umwelteinheit wie etwa Ökosysteme. Versuchen Sie, so genau wie möglich zu sein.</p>
         <p>Zum Beispiel:</p>
         <ul>
             <li>Endverbraucher</li>
             <li>Hausbesitzer</li>
             <li>Ländliche Gemeinden</li>
             <li>Betonwerke</li>
             <li>Kleine und mittlere Unternehmen</li>
         </ul>`,
        solutionTargetGroupPlaceHolder: '',
        solutionErrors: {
          requiredError: 'Text ist erforderlich!',
          lengthError: 'Der Text muss weniger als 100 Zeichen enthalten',
        },
        solutionDescription: 'Wie funktioniert die Lösung?',
        solutionDescriptionSubtitle: `<p>Beschreiben Sie in ein bis zwei Sätzen den Ansatz Ihrer Lösung. Dies kann die unternommenen Schritte, verwendeten Technologien oder implementierten Strategien umfassen, um zu erklären, wie Ihre Lösung zu den gewünschten Auswirkungen führt. Diese Beschreibung erscheint in Ihrem Wirkungsbericht und hilft den Leuten zu verstehen, wie Ihre Lösung funktioniert.</p>
         <p>Zum Beispiel:</p>
         <ul>
             <li>Der modulare Aufbau unseres Smartphones ermöglicht es Benutzern, Komponenten wie den Akku oder die Kamera ohne Spezialwerkzeuge oder professionelle Hilfe einfach zu entfernen und auszutauschen, was schnelle Reparaturen und Upgrades ermöglicht.</li>
             <li>Unsere Software stellt Unternehmen Tools und Empfehlungen zur Buchung umweltfreundlicher Geschäftsreisen zur Verfügung.</li>
             <li>Unsere kleinen Windkraftanlagen können problemlos an verschiedenen Standorten installiert werden, auch in dicht besiedelten Stadt- oder Industriegebieten, wodurch die Stromerzeugung aus erneuerbaren Energiequellen leichter zugänglich wird.</li>
         </ul>`,
        solutionEvidence: {
          title: 'Beweise für die Wirkung',
          subtitle:
            'Falls verfügbar, fügen Sie bitte externe Beweise hinzu, die verdeutlichen, dass diese Lösung funktioniert und zur Lösung des angesprochenen Problems beitragen kann.“ Dies können Artikel, wissenschaftliche Literatur oder andere Quellen sein, die die Lösung belegen.',
        },
        solutionDescriptionPlaceHolder: '',
        useGhgSavingPotentialToolTitle:
          'Möchten Sie Ihr THG-Reduktionspotenzial dieser Lösung quantifizieren?',
        useGhgSavingPotentialToolip:
          'Die Berechnung Ihrer Treibhausgasemissionseinsparungen hilft dabei, fundierte Entscheidungen über Maßnahmen zu treffen, die Ihren CO2-Fußabdruck effektiv reduzieren können. Dieser Prozess ermöglicht die Festlegung klarer, messbarer Emissionsreduktionsziele und ermöglicht die Verfolgung des Fortschritts im Laufe der Zeit. Es hilft auch bei der Priorisierung von Ressourcen, indem es die effektivsten und kosteneffizientesten Strategien zur Emissionsreduzierung ermittelt. Das Bewusstsein, das durch das Verständnis möglicher Emissionseinsparungen entsteht, kann proaktive Maßnahmen zur Reduzierung der CO2-Emissionen fördern. Darüber hinaus dient diese Berechnung für politische Entscheidungsträger als entscheidendes Instrument für die Entwicklung und Bewertung von Maßnahmen zur Reduzierung von Treibhausgasemissionen und stellt sicher, dass diese auf soliden Fakten basieren. Schließlich ist die Quantifizierung der Treibhausgasemissionseinsparungen für Unternehmen und Organisationen eine wertvolle Ressource für die Nachhaltigkeitsberichterstattung und trägt zu mehr Transparenz und Rechenschaftspflicht in Bezug auf die Umweltauswirkungen bei',
        ghgSavingPotentialTool: {
          welcome: {
            title:
              'Quantifizieren Sie das THG-Reduktionspotenzial Ihrer Produkte und Dienstleistungen',
            contents: `<p><strong>Um Ihre THG-Reduktion zu quantifizieren, werden Sie:</strong></p>
      <ul>
      <li>Das <strong>Wirkungsprofil</strong> Ihrer Lösung identifizieren. Dieses Profil ermöglicht es uns, Ihnen spezifische Hilfestellung zur Berechnung der Emissionsreduktionen Ihrer Lösung zu geben.</li>
      <li>Ein <strong>Vergleichsszenario und eine funktionelle Einheit</strong> erstellen, welche Ihre Lösung mit einem etablierten Produkt oder einer Dienstleistung vergleicht, die dieselbe Funktion erfüllt.</li>
      <li>Die relevanten <strong>THG-Emissionsquellen</strong> sowohl für Ihre Lösung als auch für das Vergleichsszenario hinzufügen.</li>
      <li>Eine <strong>Prognose</strong> festlegen, um Ihr THG-Einsparpotenzial für die nächsten 5 Jahre zu berechnen und zu bestimmen, ob Ihre Lösung zu wiederkehrenden THG-Reduktionen führt.</li>
      </ul>
      <p> </p>
      <p><strong>Was Sie benötigen, um zu starten:</strong></p>
      <ul>
      <li>Stellen Sie sicher, dass Sie <strong>gute Kenntnisse über Ihre Lösung</strong> haben. Es ist hilfreich zu wissen, wie das Produkt oder die Dienstleistung funktioniert, wie Sie es herstellen oder anbieten und welche Rohstoffe, Prozesse oder Energieverbräuche erforderlich sind.</li>
      </ul>
      <p> </p>
      <p><strong>Wichtige Hinweise:</strong></p>
      <ul>
      <li>Dieses Tool <strong>führt keine Berechnung des CO2-Fußabdrucks durch</strong>, sondern quantifiziert die <strong>potenzielle THG-Emissionsreduktionen</strong> Ihrer Lösung.</li>
      <li>Dieses Tool führt keine exakte Berechnung durch, sondern ergibt eine <strong>hochwertige Schätzung</strong> des THG-Reduktionspotenzials.</li>
      <li>Bitte stellen Sie sicher, dass Sie <strong>realistische und faire Annahmen</strong> treffen und diese immer <strong>transparent</strong> darlegen.</li>
      </ul>`,
            benefitsQuantifyingGhg:
              'Welche Vorteile bietet die Quantifizierung meines THG-Reduktionspotenzials?',
            benefitsQuantifyingGhgContent: `<p>Wenn Sie Ihr THG-Reduktionspotenzial quantifizieren, wird Ihr fertiger Impact-Bericht:</p>
                                      <ul>
                                          <li>Die gesamte jährliche THG-Reduktion anzeigen, die Ihr Unternehmen in den nächsten 5 Jahren anstrebt.</li>
                                          <li>Anzeigen, ob Sie ein Unternehmen mit hohem Klimapotenzial sind (durchschnittliche Einsparung von 50.000+ Tonnen CO2e pro Jahr).</li>
                                          <li>Die Reduktion der THG-Emissionen pro funktionelle Einheit und den Prozentsatz der Reduktion, den jede Lösung gegenüber ihrem Vergleichsszenario erreicht, aufzeigen.</li>
                                      </ul>
                                      <br /> <p>Diese Ergänzungen können helfen, die Vorteile, die die Lösungen Ihres Unternehmens gegenüber traditionellen Produkten und Dienstleistungen bieten, sowohl Ihren Investoren als auch den Endverbrauchern zu vermitteln.</p>`,
            // <a target="_blank" rel="noopener noreferrer" href="https://impactnexus.io/"><u><strong>Ein Beispiel eines Impact-Berichts mit quantifizierter THG-Reduktion anzeigen</strong></u></a>`,
            endText: `Wenn Sie die THG Ihrer Lösung jetzt nicht quantifizieren möchten, können Sie zu Meine Impacts zurückkehren.`,
          },
          impactProfile: {
            title: 'Wirkungsprofil',
            body: `<p>Das Wirkungsprofil beschreibt, wie Ihre Lösung eine Reduktion von THG-Emissionen bewirkt. Wir nutzen das Wirkungsprofil, um Ihnen während des THG-Berechnungsprozesses genauere Hilfestellung zu geben.</p>
             <p>Wenn Ihre Lösung auf mehrere Arten THG-Emissionen reduziert, wählen Sie das Profil, bei dem Sie das größte Potenzial erwarten. Ihre Auswahl hat keinen Einfluss auf bestimmte Berechnungen und Sie können sie später ändern.</p>`,
            optionsHeader: `<p>Wählen Sie das am besten geeignete Wirkungsprofil für Ihre Lösung. <span style="color: rgb(184, 49, 47);">*</span></p>`,
            introductionImpactProfile: 'Einführung in die Wirkungsprofile',
            introductionImpactProfileContent: `<p>Um das THG-Reduktionspotenzial zu berechnen, liegt der Fokus auf der Wirkung von Unternehmen, mit ihren Produkten und Dienstleistungen zum Klimaschutz beizutragen. Produkte und Dienstleistungen, die zum Klimaschutz beitragen, werden hier "Lösungen" genannt. Grundsätzlich bedeutet Klimaschutz entweder die Reduzierung von Treibhausgasemissionen oder die Entfernung von Treibhausgasen (THG) aus der Atmosphäre mithilfe von Treibhausgassenken. Die verschiedenen Arten, wie die Lösung eines Unternehmens zum Klimaschutz beitragen kann, lassen sich in Wirkungsprofile kategorisieren.</p>
                                  <p>Die Wirkungsprofile beschreiben, wie die Lösung eines Unternehmens zur Reduzierung von Treibhausgasemissionen beiträgt. Mit anderen Worten, sie zeigen, wie eine Lösung zur Verdrängung emissionsintensiver Lösungen oder Aktivitäten führen kann.</p>
                                  Aufgrund der Vielfalt von Geschäftsmodellen und Lösungen ist es nicht immer möglich, die Lösung eindeutig nur einem Wirkungsprofil zuzuordnen - Lösungen haben oft verschiedene Hebel und können daher mehrere Wirkungsprofile abdecken. Nutzer sollten das Wirkungsprofil mit dem höchsten Potenzial wählen.`,
            impactProfiles: [
              {
                id: 'CIRCULAR_ECONOMY',
                label: 'Kreislaufwirtschaft',
                value: 'CIRCULAR_ECONOMY',
                sublabel:
                  'Meine Lösung ermöglicht die Reduktion von THG-Emissionen durch die Etablierung einer Kreislaufwirtschaft.',
                tooltip:
                  '<p>Eine Lösung mit dem Wirkungsprofil "Kreislaufwirtschaft" ermöglicht die Reduktion von THG-Emissionen durch Schaffung eines geschlossenen Kreislaufs, Optimierung der Ressourcennutzung und Minimierung von Abfall. Somit führt die Lösung zur Vermeidung von emissionsintensiven Aktivitäten, wie mehrfachen Neukäufen, neuer Produktproduktion oder einer großen Abfallmenge.<br><br><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt ein Mehrwegpfandsystem für Trockenlebensmittelverpackungen. Das Unternehmen ist verantwortlich für die Verteilung und Aufarbeitung (Reinigung) der Mehrwegbehälter. Bestehende Pfandsysteme werden genutzt. Das Unternehmen stellt die Mehrwegbehälter nicht selbst her, sondern übernimmt die logistischen Prozesse und ermöglicht so die Mehrfachnutzung in einer Vielzahl von Anwendungen.<br><br><strong>Beispiel 2:</strong> Ein Unternehmen entwirft ein Smartphone mit modularen Komponenten, was es den Nutzern erleichtert, ihre Telefone zu reparieren und aufzurüsten. Die demontierbaren Teile sind austauschbar und Ersatzteile sind leicht verfügbar. Wenn der Akku nicht mehr genug Kapazität hat oder die Kamera defekt ist, kann der Benutzer sie entfernen und die neuen Komponenten einbauen. Entsorgung und Produktion von intakten oder neuen Smartphones werden vermieden.</p>',
              },
              {
                id: 'EXTRACTION_AND_MANUFACTURING',
                label: 'Rohstoffgewinnung und Produktion',
                value: 'EXTRACTION_AND_MANUFACTURING',
                sublabel:
                  'Meine Lösung reduziert THG-Emissionen während ihrer Produktion, Bereitstellung oder Rohstoffnutzung.',
                tooltip:
                  '<p>Eine Lösung mit dem Wirkungsprodil "Rohstoffgewinnung und Produktion" reduziert THG-Emissionen während ihrer Produktion oder bei der Bereitstellung von Rohstoffen. Dieser Impact wird durch die Reduktion des Rohstoffeinsatzes oder  verbundener Aktivitäten (Effizienz) und/oder durch die Vermeidung des Einsatzes von emissionsintensiven Rohstoffen oder Aktivitäten (Substitution) erreicht. Die Lösung ersetzt direkt ein emissionsintensiveres Konkurrenzprodukt oder eine Dienstleistung, die mit herkömmlichen Methoden oder Rohstoffen bereitgestellt wird.<br><br><strong>Beispiel 1:</strong> Ein Unternehmen optimiert die Zementproduktion durch Verwendung eines alternativen Rohmaterials, um die prozessbedingten CO2-Emissionen zu reduzieren.<br><br><strong>Beispiel 2:</strong> Ein Unternehmen stellt Kleidung aus ausrangiertem Textilabfall her, wie zum Beispiel Baumwollbettwäsche. Dieser Ansatz reduziert direkt THG-Emissionen im Herstellungsprozess, indem die Neuproduktion von Textilfasern vermieden wird.</p>',
              },
              {
                id: 'LOGISTICS',
                label: 'Transport und Logistik',
                value: 'LOGISTICS',
                sublabel:
                  'Meine Lösung reduziert THG-Emissionen durch die Optimierung von Transportwegen und/oder den Einsatz klimafreundlicher Transportmittel.',
                tooltip:
                  '<p>Eine Lösung mit dem Wirkungsprofil "Transport und Logistik" reduziert THG-Emissionen durch die Optimierung von Transportwegen und/oder durch den Einsatz klimafreundlicher Transportmittel. Die Lösung ersetzt direkt eine emissionsintensivere Konkurrenztransportoption.<br><br><strong>Beispiel 1:</strong>Ein Unternehmen nutzt eine digitale Plattform, die Lebensmitteleinzelhändler mit lokalen Bauernhöfen vernetzt, um  das Angebot an saisonalen und regionalen Produkten mit kurzen Transportwegen zu erhöhen.<br><br><strong>Beispiel 2:</strong> Ein Unternehmen bietet klimafreundliche Produktlieferungen für die letzte Meile per Fahrrad an. Der Transporterverkehr in Städten wird reduziert und durch Fahrräder ersetzt, welche die Pakete zum Endverbraucher transportieren. Darüber hinaus können die Fahrräder aufgrund effizienterer Routen, die für große Transporter nicht befahrbar sind, schneller sein. Für größere Lieferungen werden Lastenfahrräder verwendet.</p>',
              },
              {
                id: 'PRODUCT_APPLICATION',
                label: 'Produktnutzung',
                value: 'PRODUCT_APPLICATION',
                sublabel:
                  'Meine Lösung ermöglicht es ihren Nutzern, bei jeder Nutzung oder durch den Betrieb Treibhausgasemissionen zu reduzieren.',
                tooltip:
                  '<p>Eine Lösung mit dem Wirkungsprofil "Produktnutzung" ist ein Produkt, mit dem die Nutzer bei jeder Nutzung oder durch den Betrieb Treibhausgasemissionen reduzieren können. Die Lösung ermöglicht es den Nutzern, emissionsintensive Aktivitäten zu ersetzen, während das Produkt in Gebrauch ist. Somit ist der Impact entweder wiederkehrend oder dauerhaft und erstreckt sich über einen längeren Zeitraum.<br><br><strong>Beispiel 1:</strong>Ein Unternehmen entwickelt kleine Windturbinen, die einfach an verschiedenen Standorten installiert werden können. Die kleinere Größe ermöglicht die Produktion von erneuerbarem Strom mit Windturbinen in dicht besiedelten städtischen oder industriellen Gebieten. So ermöglichen sie THG-Reduktionen durch eine einfachere Produktion von erneuerbarem Strom.<br><br><strong>Beispiel 2:</strong>Ein Unternehmen entwickelt eine neue Reihe von Waschmaschinen, die mit fortschrittlichen Waschalgorithmen ausgestattet sind, die den Waschprozess für maximale Effizienz und Leistung optimieren. Diese Algorithmen steuern Faktoren wie Waschzyklusdauer, Temperatur und Rührintensität, um eine gründliche Reinigung bei minimalem Energieverbrauch zu gewährleisten und die Umweltauswirkungen zu reduzieren.</p>',
              },
              {
                id: 'CUSTOMER_EMPOWERMENT',
                label: 'Kundenbefähigung',
                value: 'CUSTOMER_EMPOWERMENT',
                sublabel:
                  'Meine Lösung befähigt meine Kunden mit beratenden, informativen oder unterstützenden Dienstleistungen, THG-Emissionen zu reduzieren.',
                tooltip:
                  '<p>Eine Lösung mit dem Wirkungsprofil "Kundenbefähigung" ermöglicht es seinen Nutzern, THG-Emissionen mit beratenden, informativen oder unterstützenden Dienstleistungen zu reduzieren. Der Impact wird erreicht, indem den Nutzern geholfen wird, Klimaschutzmaßnahmen leichter umzusetzen oder klimafreundlichere Entscheidungen zu treffen.<br><br><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt eine Software, die es Unternehmen ermöglicht, ihre geschäftlichen Mitarbeiterreisen klimafreundlicher zu gestalten. THG-Emissionen können vermieden werden, indem die Software genutzt wird und entsprechende klimafreundliche Ratschläge umgesetzt werden.<br><br><strong>Beispiel 2:</strong> Ein Unternehmen bietet eine Webplattform zur Überwachung industrieller Systeme an, um kritische Betriebszustände zu erkennen. Dies ermöglicht es Kunden, vorbeugende Wartungsarbeiten an Blockheizkraftwerken, Solaranlagen, Luftkompressoren usw. durchzuführen und die Energieeffizienz und Rentabilität zu verbessern. THG-Emissionen können aufgrund des optimierten Betriebs der Systeme reduziert werden.</p>',
              },
            ],
          },
          fairComparison: {
            title: 'Vergleichsszenario und funktionelle Einheit',
            comparativeScenarioAndFunctionalUnitDesc:
              'In diesem Schritt erstellen Sie ein Vergleichsszenario und eine funktionelle Einheit, sodass Sie Ihre Lösung fair mit einem derzeit etablierten Produkt oder einer Dienstleistung vergleichen können.',
            functionOfYourImpactSolutionTitle: 'Funktion Ihrer Lösung',
            functionOfYourImpactSolutionQuestion:
              'Welche Funktion erfüllt Ihre Lösung?',
            functionOfYourImpactSolutionWarning:
              '<p><em>Achtung: Hierbei liegt der Fokus auf der Funktion Ihrer Lösung für Ihre Kunden, nicht auf dem übergeordneten Ziel der Klimaschutzmaßnahmen/THG-Reduktion.</em></p>',
            comparativeScenarioTitle: 'Vergleichsszenario',
            comparativeScenarioText:
              'Beschreiben Sie das Vergleichsszenario, das dieselbe Funktion erfüllt. Dieses Szenario sollte die etablierte Situation ohne Ihre Lösung widerspiegeln. Wenn es mehrere etablierte Lösungen gibt, wählen Sie diejenige mit der besten Umweltleistung, um Greenwashing zu vermeiden.',
            comparativeScenarioQuestion:
              '<p>Wie wird die Funktion derzeit ohne Ihre Lösung bereitgestellt?</p>',
            functionalUnitTitle: 'Funktionelle Einheit',
            functionalUnitText:
              'Für den Vergleich benötigen Sie eine Messgröße, die oft als "funktionelle Einheit" bezeichnet wird. Diese Einheit sollte etwas sein, das Sie messen und basierend auf Ihren Geschäftsprognosen vorhersagen können. Im nächsten Schritt werden wir die Treibhausgasemissionen mit dieser Messgröße verbinden.',
            functionalUnitQuestion:
              'Welche funktionelle Einheit soll verwendet werden?',
            describeComparisonTitle:
              'Beschreiben Sie den fairen Vergleich, den Sie machen, indem Sie diesen Satz vervollständigen:',
            describeComparisonSubtitle:
              'Ich vergleiche [meine Lösung] mit [Vergleichsszenario] bezogen auf [funktionelle Einheit] ',
            guidanceForProfile: 'Anleitung für Ihr Wirkungsprofil',
            fairComparisonsPerImpactProfile: {
              CIRCULAR_ECONOMY: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt ein Mehrwegpfandsystem für Trockenlebensmittelverpackungen. Die Hauptfunktion ist <strong>den Kunden die Möglichkeit zu bieten, verpackte Trockenlebensmittel zu kaufen</strong>.<br> <br><strong>Beispiel 2:</strong> Ein Unternehmen entwirft ein Smartphone mit modularen Komponenten, was es den Nutzern erleichtert, ihre Telefone zu reparieren und aufzurüsten. Seine Hauptfunktion ist <strong>mobile Kommunikation, Internetverbindung, Organisation, Navigation und andere Smartphone-Funktionen</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>Für Ihr Wirkungsprofil ist es nützlich, Ihre Lösung mit einem derzeit etablierten Produkt oder Prozess zu vergleichen, das dieselbe Funktion erfüllt, aber nur einmal verwendet wird oder nicht reparierbar oder recycelbar usw. ist. Denken Sie darüber nach, was Ihre Kunden ohne Ihre Lösung verwenden oder tun würden, um denselben Nutzen oder dieselbe Funktion zu erhalten.<br> <br><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt ein Mehrwegpfandsystem für Trockenlebensmittelverpackungen. Ein möglicher Vergleich ist eine <strong>konventionelle Einwegverpackung</strong>, da diese dieselbe Funktion erfüllt. <br> <br><strong>Beispiel 2:</strong> Ein Unternehmen entwirft ein Smartphone mit modularen Komponenten, was es den Nutzern erleichtert, ihre Telefone zu reparieren und aufzurüsten. Ein möglicher Vergleich ist ein <strong>konventionelles Smartphone mit verklebten und verschweißten Komponenten</strong>, die nicht demontierbar und reparierbar sind.</p>',
                guidanceFunctionalUnit:
                  '<p>Bei Ihrem Wirkungsprofil ist in der Regel eine verkaufte oder verwendete Menge des relevanten Produkts oder der Aktivität, die Teil der Kreislaufwirtschaft wird, eine gute funktionelle Einheit. Die funktionelle Einheit kann eine für das Vergleichsszenario typische Menge umfassen. Faktoren wie die Häufigkeit der Wiederverwendung oder die Lebensdauerverlängerung Ihrer Lösung werden in den nächsten Schritten bei der Berechnung der Emissionen berücksichtigt.<br><strong><br>Beispiel 1:</strong> Ein Unternehmen entwickelt ein Mehrwegpfandsystem für Trockenlebensmittelverpackungen. Eine gute funktionelle Einheit wäre eine <strong>500g Packung getrockneter Lebensmittel</strong>.<br><strong><br>Beispiel 2:</strong> Ein Unternehmen entwirft ein Smartphone mit modularen Komponenten, was es den Nutzern erleichtert, ihre Telefone zu reparieren und aufzurüsten. Eine gute funktionelle Einheit wäre eine <strong>drei Jahre Nutzungsdauer eines Smartphones</strong>.</p>',
              },
              EXTRACTION_AND_MANUFACTURING: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Beispiel 1:</strong> Ein Unternehmen optimiert die Zementproduktion durch Verwendung eines alternativen Rohmaterials. Die Funktion wäre die <strong>Produktion von Zement</strong>.<br> <br><strong>Beispiel 2:</strong> Ein Unternehmen stellt Kleidung aus ausrangiertem Textilabfall her, hauptsächlich aus Baumwollbettwäsche. Die Funktion dieses Produkts ist es, <strong>Kleidung für den Alltag zu bieten</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>Für Ihr Wirkungsprofil ist es nützlich, Ihre Lösung mit einem derzeit etablierten Produkt zu vergleichen, das dieselbe Funktion erfüllt und durch Ihre Innovation verdrängt würde.<br> <br><strong>Beispiel 1:</strong> Ein Unternehmen optimiert die Zementproduktion durch Verwendung eines alternativen Rohmaterials. Ein möglicher Vergleich wäre eine <strong>konventionelle Zementproduktion</strong>.<br> <br><strong>Beispiel 2:</strong> Ein Unternehmen stellt Kleidung aus ausrangiertem Textilabfall her, hauptsächlich aus Baumwollbettwäsche. Ein nützlicher Vergleich wäre eine <strong>konventionell hergestellte Kleidung</strong> mit derselben Funktion (z. B. T-Shirts).</p>',
                guidanceFunctionalUnit:
                  '<p>Bei Ihrem Wirkungsprofil ist in der Regel eine Menge an verwendeten Rohstoffen oder produzierten Gütern eine gute Wahl.<br><strong><br>Beispiel 1:</strong> Ein Unternehmen optimiert die Zementproduktion durch Verwendung eines alternativen Rohmaterials. Eine gute funktionelle Einheit wäre <strong>eine Tonne hergestellten Zements.<br><br>Beispiel 2:</strong> Ein Unternehmen stellt Kleidung aus ausrangiertem Textilabfall her, hauptsächlich aus Baumwollbettwäsche. Eine gute funktionelle Einheit wäre <strong>1 T-Shirt</strong> oder ein anderes Kleidungsstück.<br>Typische andere funktionelle Einheiten sind: MWh erzeugter Energie, Anzahl verkaufter Rucksäcke, Produktion von 160.000 kWh thermischer Energie.</p>',
              },
              LOGISTICS: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Beispiel 1:</strong> Ein Unternehmen nutzt eine digitale Plattform, die Lebensmitteleinzelhändler mit lokalen Bauernhöfen vernetzt, um das Angebot an saisonalen und regionalen Produkten mit E-Transportern und kurzen Transportwegen zu erhöhen. Die Funktion ist die <strong>Bereitstellung von Lebensmitteln für einen Supermarkt</strong>.<br> <br><strong>Beispiel 2:</strong> Ein Unternehmen bietet Fahrradlieferungen von Produkten für die letzte Meile an. Die Hauptfunktion ist die <strong>Zustellung eines Pakets an den Endverbraucher in der Stadt</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>Für Ihr Wirkungsprofil ist es nützlich, Ihre Lösung mit einem derzeit etablierten Produkt oder Dienstleistung zu vergleichen, das dieselbe Funktion erfüllt und durch Ihre Lösung verdrängt würde.<br> <br><strong>Beispiel 1: </strong>Ein Unternehmen nutzt eine digitale Plattform, die Lebensmitteleinzelhändler mit lokalen Bauernhöfen vernetzt, um das Angebot an saisonalen und regionalen Produkten mit E-Transportern und kurzen Transportwegen zu erhöhen. Ein mögliches Vergleichsszenario wäre das <strong>aktuelle Versorgungssystem von Supermärkten mit Lebensmitteln aus entfernten Standorten</strong>.<br> <br><strong>Beispiel 2:</strong> Ein Unternehmen bietet Fahrradlieferungen von Produkten für die letzte Meile an. Ein mögliches Vergleichsszenario wäre <strong>die Lieferung von Produkten mit konventionellen Transportern</strong>.</p>',
                guidanceFunctionalUnit:
                  '<p>Bei Ihrem Wirkungsprofil ist in der Regel eine Menge an transportierten Gütern oder Passagieren über eine bestimmte Entfernung oder eine zurückgelegte Entfernung eine gute Wahl.<br><strong><br>Beispiel 1: </strong>Ein Unternehmen nutzt eine digitale Plattform, die Lebensmitteleinzelhändler mit lokalen Bauernhöfen vernetzt, um das Angebot an saisonalen und regionalen Produkten mit E-Transportern und kurzen Transportwegen zu erhöhen. Eine gute funktionelle Einheit wäre die <strong>Lieferung von 1 Tonne Lebensmitteln an einen Supermarkt</strong>.<br><strong><br>Beispiel 2:</strong> Ein Unternehmen bietet Fahrradlieferungen von Produkten für die letzte Meile an. Eine gute funktionelle Einheit wäre <strong>die Lieferung von 100 Produkten (Gesamtgewicht von 3 Tonnen) über 25 km</strong>.<br>Typische andere funktionelle Einheiten sind: eine gelieferte Mahlzeit, Transport einer Person über 100 km, Transport von Waren über 100 km.</p>',
              },
              PRODUCT_APPLICATION: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt kleine Windturbinen, die in dicht besiedelten städtischen oder industriellen Gebieten installiert werden können. Die Funktion ist die <strong>Produktion und Bereitstellung von Strom in dicht besiedelten städtischen und industriellen Gebieten</strong>.<br> <br><strong>Beispiel 2: </strong>Ein Unternehmen entwickelt eine neue Reihe von Waschmaschinen, die mit fortschrittlichen Waschalgorithmen ausgestattet sind, die den Waschprozess für maximale Effizienz und Leistung optimieren. Die Funktion ist <strong>das Waschen von Wäsche</strong>.</p>',
                guidanceComparativeScenario:
                  '<p><p>Im Falle Ihres Wirkungsprofils denken Sie darüber nach, was Ihr Kunde ohne Ihre Lösung tun oder verwenden würde, um dieselbe Funktion zu erhalten und welche Aktivitäten durch Ihre Lösung verdrängt würden.<br> <br><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt kleine Windturbinen, die in dicht besiedelten städtischen oder industriellen Gebieten installiert werden können. Ein mögliches Vergleichsszenario wäre die <strong>Stromversorgung aus dem Netz</strong>. <br> <br><strong>Beispiel 2:</strong> Ein Unternehmen entwickelt eine neue Reihe von Waschmaschinen, die mit fortschrittlichen Waschalgorithmen ausgestattet sind, die den Waschprozess für maximale Effizienz und Leistung optimieren. Ein mögliches Vergleichsszenario wäre eine <strong>konventionelle Waschmaschine</strong>.</p>',
                guidanceFunctionalUnit:
                  '<p>Bei Ihrem Wirkungsprofil macht es in der Regel Sinn, eine funktionelle Einheit zu verwenden, die widerspiegelt, was mit Ihrer Lösung erreicht werden kann.<br><br><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt kleine Windturbinen, die in dicht besiedelten städtischen oder industriellen Gebieten installiert werden können. Eine gute funktionelle Einheit wäre die Menge an Energie, die eine durchschnittliche kleine Windturbine (10 kW) in einem Jahr produziert: <strong>20.000 kWh Strom</strong>.<br><strong><br>Beispiel 2:</strong> Ein Unternehmen entwickelt eine neue Reihe von Waschmaschinen, die mit fortschrittlichen Waschalgorithmen ausgestattet sind, die den Waschprozess für maximale Effizienz und Leistung optimieren. Eine gute funktionelle Einheit wäre die Anzahl der Waschzyklen einer Waschmaschine in einem Jahr: <strong>150 Waschzyklen</strong>.<br><br>Typische andere funktionelle Einheiten sind: 1MW installierte Solarmodulkapazität, Verarbeitung von 100 kg eines Materials, Produktion von 160.000 kWh Nutzwärme.</p>',
              },

              CUSTOMER_EMPOWERMENT: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Beispiel 1: </strong>Ein Unternehmen entwickelt eine Software, die es Unternehmen ermöglicht, ihre geschäftlichen Mitarbeiterreisen klimafreundlicher zu gestalten, indem sie Mitarbeiter einbeziehen. Die gemeinsame Funktion ist die <strong>Buchung von Geschäftsreisen</strong>.<br> <br><strong>Beispiel 2:</strong> Ein Unternehmen bietet eine Webplattform zur Überwachung industrieller Systeme an, um kritische Betriebszustände zu erkennen und zu verbessern. Die Funktion dieses Produkts ist <strong>die Überwachung und das Betreiben technischer Geräte</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>Im Falle Ihres Wirkungsprofils denken Sie darüber nach, was Ihr Kunde ohne Ihre beratende, informative oder unterstützende Lösung tun würde, um dieselbe Funktion zu erhalten und welche Aktivitäten mit Hilfe Ihrer Lösung verdrängt würden. Es ist möglich, dass Ihr Kunde kein anderes Produkt oder keine andere Dienstleistung verwenden würde.<br> <br><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt eine Software, die es Unternehmen ermöglicht, ihre geschäftlichen Mitarbeiterreisen klimafreundlicher zu gestalten, indem sie Mitarbeiter einbeziehen. Ein möglicher Vergleich wäre eine <strong>konventionelle Software, die Optionen für Geschäftsreisen ohne den Fokus auf umweltfreundliche und mitarbeiterbezogene Optionen anzeigt</strong>. <br> <br><strong>Beispiel 2:</strong> Ein Unternehmen bietet eine Webplattform zur Überwachung industrieller Systeme an, um kritische Betriebszustände zu erkennen und zu verbessern. Ein nützlicher Vergleich wäre a) <strong>eine konventionelle Überwachungssoftware, die weniger effizient ist</strong> oder b) <strong>das Betreiben technischer Geräte ohne Überwachungssystem</strong>.</p>',
                guidanceFunctionalUnit:
                  '<p>Für Ihr Wirkungsprofil ist oft ein durchschnittlicher Kunde oder eine spezifische Aktivität eine gute funktionelle Einheit. Da diese sehr unterschiedlich sein können, verwenden Sie einen durchschnittlichen Kunden / eine Aktivität, die einen großen Teil Ihres Geschäfts repräsentieren kann. Wenn möglich, versuchen Sie, eine spezifischere Einheit zu wählen.<br><strong><br>Beispiel 1:</strong> Ein Unternehmen entwickelt eine Software, die es Unternehmen ermöglicht, ihre geschäftlichen Mitarbeiterreisen klimafreundlicher zu gestalten, indem sie Mitarbeiter einbeziehen. Eine gute funktionelle Einheit wäre <strong>1 durchschnittliche Geschäftsreise (1 Person, 200 km einfache Strecke / pro Fahrt)</strong>.<br><strong><br>Beispiel 2:</strong> Ein Unternehmen bietet eine Webplattform zur Überwachung industrieller Systeme an, um kritische Betriebszustände zu erkennen und zu verbessern. Eine gute funktionelle Einheit wäre <strong>1 durchschnittlicher Kunde</strong>.</p>',
              },
            },
          },
          emissionSources: {
            guidanceForProfile: 'Hilfestellung für Ihr Wirkungsprofil',

            limitations: {
              showGuidanceForLimitationsLabel:
                'Hilfestellung für die Limitierungen',
              title:
                'Beschreiben Sie die Limitierungen, die Sie gemacht haben, in Stichpunkten',
              subtitle:
                'Welche Emissionsquellen haben Sie weggelassen und warum?',
              inputGuidance:
                'Verwenden Sie Sternchen (*) als Aufzählungszeichen.',
              placeholder: `* Wir haben die THG-Emissionen aus der Produktion der Batterien nicht einbezogen.
* Dies liegt daran, dass wir Second-Life-Batterien aus Elektrofahrzeugen verwenden.
* ...`,
              examples: `Erklären Sie die Limitierungen Ihrer Daten, zum Beispiel:
                          <ul>
                              <li>Transport, Produktion & Komponenten sind gleich wie im Vergleichsszenario.</li>
                              <li>End-of-Life-Emissionen werden aufgrund der Datenverfügbarkeit nicht berücksichtigt.</li>
                              <li>Rohstoffe werden nicht berücksichtigt, da die Auswirkungen auf die Treibhausgasemissionen in diesem Kontext wahrscheinlich nicht besonders relevant sind.</li>
                              <li>Transportemissionen werden nicht berücksichtigt, da sie als sehr gering eingeschätzt werden.</li>
                          </ul>`,
            },
            emissionSourcesTableHeaders: [
              {
                text: '',
                value: 'actions',
              },
              {
                text: 'Ich vergleiche ...',
                value: 'sourceOfEmission',
              },
              {
                text: 'Emission des Vergleichsszenarios',
                value: 'baselineConsumption',
              },
              {
                text: 'Emission Ihrer Lösung',
                value: 'solutionConsumption',
              },
              {
                text: 'Warum tritt der Unterschied auf?',
                value: 'howSolutionWorks',
              },
            ],
            addEmissionSourceButton: 'Emissionsquelle hinzufügen',
            scenarioOptions: [
              {
                id: 'A',
                label: 'Berechnen Sie genaue Emissionen mit Emissionsquellen',
                sublabel:
                  'Spezifizieren Sie bis zu drei der relevantesten Emissionsquellen und untermauern Sie diese mit Daten. Eine Emissionsquelle ist alles, was notwendig ist, um die Funktion Ihrer Lösung und/oder des Vergleichsszenarios zu erfüllen und gleichzeitig zu THG-Emissionen führt. Z.B. der Verbrauch von Energie, die Notwendigkeit für Transport oder der Verbrauch von Materialien.',
              },
              {
                id: 'B',
                label:
                  'Geben Sie eine geschätzte Gesamtmenge an THG-Emissionen für jedes Szenario ein',
                sublabel:
                  'Wählen Sie diese Option, wenn Sie bereits einen Produkt-Carbon-Footprint berechnet haben, der zu Ihrer funktionelle Einheit passt, oder wenn Sie eine fundierte Schätzung über die gesamten THG-Emissionen pro funktionelle Einheit jedes Szenarios machen möchten. Bitte beachten Sie, dass eine fundierte Schätzung viel einfacher zu liefern ist, das Ergebnis jedoch wahrscheinlich weniger genau ist und auch weniger überzeugend sein kann, da es schwierig zu verstehen sein kann, wenn keine Belege geliefert werden.',
              },
            ],
            scenarioDetails: {
              scenarioA: {
                warning: `Es scheint, dass Ihre Lösung zu mehr Treibhausgasemissionen
                      führt als das Vergleichsszenario. Dies kann daran liegen, dass Sie einige
                      Emissionsquellen ausgelassen haben oder einige Daten ungenau sind. Es ist auch
                      möglich, dass Ihre Lösung keine Emissionen reduziert. Bitte prüfen Sie, ob
                      Ihre Eingabe korrekt ist.`,
                label:
                  'Geben Sie eine geschätzte Gesamtmenge an THG-Emissionen für jedes Szenario ein',
                text: `<ol>
                            <li><strong>Wählen Sie die relevantesten Emissionsquellen aus:</strong> Konzentrieren Sie sich auf bis zu drei Emissionsquellen.</li>
                            <li><strong>Berücksichtigen Sie nur die Emissionsquellen, die sich zwischen den Szenarien unterscheiden:</strong> Da wir nur an den Unterschieden zwischen den Szenarien interessiert sind, lassen Sie Emissionen aus, die in beiden Szenarien in gleicher Menge vorkommen. Abhängig von Ihrem Wirkungsprofil, beachten Sie Rohstoff-, Mobilitäts- und Energiebedarfe, die für Ihre Lösung notwendig sind, sich aber in den Szenarien unterscheiden.</li>
                            <li><strong>Geben Sie den entsprechenden THG-Emissionsfaktor und eine Verbrauchsmenge ein:</strong> Sie finden integrierte Datenbanken, um passende THG-Emissionsfaktoren oder typische Referenzdaten zu finden.</li>
                            <li><strong>Berücksichtigen Sie die funktionelle Einheit und ein Jahr:</strong> Erwähnen Sie immer die Vergleichseinheit und, falls zutreffend, berücksichtigen Sie einen Zeitraum von einem Jahr.</li>
                            <li><strong>Details zu Unterschieden:</strong> Erklären Sie für jede Emissionsquelle, wie sich die Szenarien unterscheiden:<ul>
                            <li>Unterschiede im Verbrauch: Es könnte eine kleinere Menge benötigt sein, oder die Quelle wird in einem Szenario nicht, aber im anderen verwendet.</li>
                            </ul>
                            </li>
                            </ol>
                            <ul>
                            <ul>
                            <li>Änderung des THG-Emissionsfaktor: In den Szenarien können für eine Emissionsquelle unterschiedliche THG-Emissionsfaktoren angewendet werden.</li>
                            </ul>
                            </ul>`,
                addFactorAndAmount:
                  'Emissionsfaktor hinzufügen und Betrag angeben',
                guidancePerImpactProfile: {
                  CIRCULAR_ECONOMY: {
                    emissionSourceGuidanceForProfile: `<p>Bei Ihrem Wirkungsprofil finden Sie die relevanten Emissionsquellen in allen Lebensphasen Ihrer Innovation. Abhängig vom Szenario können sich relevante Emissionen in unterschiedlichen Lebensphasen finden. Denken Sie an jene Aktivitäten, die sich zwischen Ihrer Lösung und dem Vergleichsszenario unterscheiden.</p>
                                                <strong>Beispiel 1:</strong> Ein Unternehmen entwickelt ein Mehrwegpfandsystem für Trockenlebensmittelverpackungen. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Rohstoffverbrauch</strong> (Plastik von Mehrweg-Lebensmittelverpackungen vs. Plastik von Einwegverpackungen),</li>
                                                <li><strong>Transport</strong> (Entfernung vom Endverbraucher zurück zum Pfandsystem und zurück zum Supermarkt vs. kein Weg zurück),</li>
                                                <li><strong>Nutzungsphase</strong> (Reinigung von Mehrwegverpackungen vs. keine Reinigung),<br>Ende des Lebenszyklus (Wiederverwendung von Verpackungen vs. Entsorgung und Verbrennung oder Recycling von Verpackungen).</li>
                                                </ul>
                                                </br>
                                                <strong>Beispiel 2:</strong> Ein Unternehmen entwirft ein Smartphone mit modularen Komponenten, was es den Nutzern erleichtert, ihre Telefone zu reparieren und aufzurüsten. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Rohstoffgewinnung</strong> (Rohstoffgewinnung für Ersatzteile vs. Rohstoffgewinnung für ein neues Smartphone)</li>
                                                <li><strong>Produktion</strong> (von Ersatzteilen vs. ganzes neues Smartphone)</li>
                                                <li><strong>Ende des Lebenszyklus</strong> (Wiederverwendung oder verlängerte Nutzung des bestehenden Smartphones vs. Entsorgung des konventionellen Smartphones).</li>
                                                </ul>`,
                  },
                  EXTRACTION_AND_MANUFACTURING: {
                    emissionSourceGuidanceForProfile: `<p>Bei Ihrem Wirkungsprofil finden Sie Ihre relevanten Emissionsquellen normalerweise in den Phasen: Rohstoffgewinnung und/oder Produktionsphase und/oder Transportphase. Denken Sie an den Hauptunterschied zwischen der Produktion oder Bereitstellung Ihrer Lösung und dem Vergleichsszenario.</p>
                                                <strong>Beispiel 1:</strong> Ein Unternehmen optimiert die Zementproduktion durch Verwendung eines alternativen Rohmaterials. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Rohstoffgewinnung </strong>(verwendeter Rohstoff für CO2-reduzierten Zement vs. Rohstoffe für konventionellen Zement)</li>
                                                <li><strong>Produktion </strong>(optimierte Zementproduktion vs. konventionelle Zementproduktion)</li>
                                                </ul>
                                                </br>
                                                <strong>Beispiel 2:</strong> Ein Unternehmen stellt Kleidung aus ausrangiertem Textilabfall her, hauptsächlich aus Baumwollbettwäsche. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Rohstoffgewinnung </strong>(Neue Fasern vs. Second-Hand-Faser)</li>
                                                <li><strong>Transport </strong>(Import von neuen Fasern vs. Inlandsbereitstellung von Second-Hand-Fasern)</li>
                                                </ul>`,
                  },
                  LOGISTICS: {
                    emissionSourceGuidanceForProfile: `<p>Bei Ihrem Wirkungsprofil finden Sie Ihre relevanten Emissionsquellen normalerweise in den Transport- und Logistikprozessen. Denken Sie an jene Aktivitäten, die sich zwischen Ihrer Lösung und dem Vergleichsszenario unterscheiden.</p>
                                                  <strong>Beispiel 1:</strong> Ein Unternehmen nutzt eine digitale Plattform, die Lebensmitteleinzelhändler mit lokalen Bauernhöfen vernetzt, um das Angebot an saisonalen und regionalen Produkten mit E-Transportern und kurzen Transportwegen zu erhöhen. Relevante Emissionsquellen sind:
                                                  <ul>
                                                  <li><strong>Nutzungsphase</strong> (Nutzung der digitalen Plattform vs. keine Plattformnutzung), </li>
                                                  <li><strong>Transport</strong> (Lieferung von lokalen Lebensmitteln vs. Lebensmittel aus entfernten Regionen) </li>
                                                  <li><strong>Produktion von Lebensmitteln</strong> (saisonale Lebensmittel vs. nicht saisonale Lebensmittel)</li>
                                                  </ul>
                                                  </br>
                                                  <strong>Beispiel 2:</strong> Ein Unternehmen bietet Fahrradlieferungen von Produkten für die letzte Meile an. Relevante Emissionsquellen sind:
                                                  <ul>
                                                  <li><strong>Produktion</strong> (von konventionellen Transportern vs. Fahrrad)</li>
                                                  <li><strong>Transport</strong> (mit konventionellem Transporter vs. Fahrrad)</li>
                                                  </ul>`,
                  },
                  PRODUCT_APPLICATION: {
                    emissionSourceGuidanceForProfile: `<p>Bei Ihrem Wirkungsprofil finden Sie normalerweise die relevanten Emissionsquellen in der Nutzungsphase Ihrer Lösung sowie der Vergleichslösung oder in den Geschäftsaktivitäten (einschließlich der vorgelagerten Ketten) Ihrer Kunden. Denken Sie an die Veränderungen, die auftreten, wenn Ihre Lösung anstelle des Vergleichsszenarios verwendet wird.</p>
                                                <strong>Beispiel 1:</strong> Ein Unternehmen entwickelt kleine Windturbinen, die in dicht besiedelten städtischen oder industriellen Gebieten installiert werden können. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Stromerzeugung”</strong> (kleine Windturbinen vs. Strommix aus dem Netz)</li>
                                                </ul>
                                                </br>
                                                <strong>Beispiel 2:</strong> Ein Unternehmen entwickelt eine neue Reihe von Waschmaschinen, die mit fortschrittlichen Waschalgorithmen ausgestattet sind, die den Waschprozess für maximale Effizienz und Leistung optimieren. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Stromverbrauch</strong> (effiziente Waschmaschine vs. konventionelle Waschmaschine)</li>
                                                </ul>`,
                  },
                  CUSTOMER_EMPOWERMENT: {
                    emissionSourceGuidanceForProfile: `<p>Bei Ihrem Wirkungsprofil finden Sie die relevanten Emissionsquellen normalerweise in den Geschäftsaktivitäten (einschließlich der vorgelagerten Ketten) Ihrer Kunden oder bei Dritten. Denken Sie an die Veränderungen, die eintreten, wenn Ihre Kunden Ihre Lösung anstelle des Vergleichsszenarios nutzen.</p>
                                                <strong>Beispiel 1:</strong> Ein Unternehmen hat eine Software entwickelt, die es Unternehmen ermöglicht, ihre geschäftlichen Mitarbeiterreisen klimafreundlicher zu gestalten, indem sie Mitarbeiter einbeziehen. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Nutzungsphase</strong> (Betrieb der Software vs. keine Software)</li>
                                                <li><strong>Transport</strong> (z. B. Bahnfahrt vs. Flug für Inlandsdienstreisen)</li>
                                                </ul>
                                                </br>
                                                <strong>Beispiel 2:</strong> Ein Unternehmen bietet eine Webplattform zur Überwachung industrieller Systeme an, um kritische Betriebszustände zu erkennen und zu verbessern. Relevante Emissionsquellen sind:
                                                <ul>
                                                <li><strong>Nutzungsphase der Anlagen</strong> (z. B. effizienter vs. nicht effizienter Energieverbrauch)</li>
                                                </ul>`,
                  },
                },
              },
              scenarioB: {
                title:
                  'Geben Sie eine geschätzte Gesamtmenge an THG für jedes Szenario ein',
                comparativeScenario: {
                  title: 'Vergleichsszenario',
                  subtitle: 'THG-Menge in kg CO2',
                  placeholder: 'THG-Menge in kg CO2',
                },
                solution: {
                  title: 'Ihre Lösung',
                  subtitle: 'THG-Menge in kg CO2',
                  placeholder: 'THG-Menge in kg CO2',
                },
                comments: {
                  title:
                    'Bitte rechtfertigen Sie Ihre Annahmen mit Belegen oder anderen Beweisen sowie Berechnungsmethoden!',
                  placeholder: 'Annahmen',
                },
              },
            },
            emissionSourceGuidelines: {
              introduction: `<p>Um Ihr Reduktionspotenzial zu berechnen, müssen die relevanten Emissionsdaten für Ihre Lösung und das Vergleichsszenario pro funktionelle Einheit erfasst werden. Dazu müssen Sie die THG-Emissionen für beide Szenarien angeben. Leitfragen, Beispiele und Verweise oder Links zu externen Datenbanken helfen Ihnen, alle notwendigen Werte zu ermitteln und auch Belege zur Unterstützung Ihrer Ansprüche bereitzustellen.</p>
<p>Es gibt zwei Berechnungsmethoden. Bitte wählen Sie eine Option:</p>`,
            },
            emissionSourceFormsTooltips: {
              ghgFactorTooltip: `<p>Ein THG-Emissionsfaktor ist ein numerischer Wert, der hilft, die Beziehung zwischen menschlichen Aktivitäten und den von ihnen produzierten Treibhausgasemissionen zu quantifizieren. Der THG-Emissionsfaktor stellt die Menge der Treibhausgasemissionen dar, die pro Einheit der Aktivität oder des Outputs produziert werden, wie zum Beispiel Emissionen pro Einheit der erzeugten Energie oder Emissionen pro zurückgelegtem Kilometer in einem bestimmten Verkehrsmittel.</p>
        <p><strong>Beispiel:</strong> Vom Netz gelieferter Strom - Produktionsmix in Deutschland 2022: <strong>0,3876 kg CO2e/kWh</strong></p>
        <p>Sie können in der Datenbank nach einem THG-Faktor suchen oder Ihren eigenen Faktor eingeben.</p>
        <p>Wenn Sie einen THG-Emissionsfaktor auswählen, achten Sie auf die Lebenszyklusphasen (manchmal auch LCA-Aktivität genannt), die der THG-Faktor abdeckt. Versuchen Sie, mit einem THG-Faktor so viele Lebenszyklen wie möglich abzudecken oder keine Lebenszyklusphase doppelt abzudecken, wenn Sie mehr als eine Emissionsquelle hinzufügen.</p>`,
              tooltipPerImpactProfile: {
                PRODUCT_APPLICATION: {
                  consumptionAmountTooltip: `<p>Dies ist die Menge, die auf Basis der funktionellen Einheit verbraucht wird. Die Einheit muss mit der Einheit des THG-Faktors übereinstimmen.</p>
            <p>Zum Beispiel, wenn die Einheit des THG-Faktors <strong>kg CO2e / kWh</strong> ist, muss Ihr Verbrauch in <strong>kWh</strong> ausgedrückt werden.</p>
            <p>Die Referenzwertdatenbank hilft Ihnen, typische Verbrauchsmengen zu finden.</p>`,
                },
                CIRCULAR_ECONOMY: {
                  consumptionAmountTooltip: `<p>Dies ist die Menge, die auf Basis der funktionellen Einheit verbraucht wird. Beachten Sie bei Ihrem Wirkungsprofil, dass Produkte der Kreislaufwirtschaft oft mehrfach oder über einen längeren Zeitraum verwendet werden. Das bedeutet, dass Sie möglicherweise einen Faktor für Wiederverwendung oder Lebenszeitverlängerung anwenden müssen.</p>
            <p><strong>Beispiel 1:</strong> Ein Unternehmen entwickelt ein Mehrwegpfandsystem für Trockenlebensmittelverpackungen. Die funktionelle Einheit ist die Verpackung von 500g Trockenlebensmitteln. Die wiederverwendbare Lebensmittelverpackung hat einen durchschnittlichen Umlauf von 35 Zyklen. Daher werden nur 1/35 der Produktions-Emissionen der wiederverwendbaren Lebensmittelverpackung benötigt.</p>
            <p>Die Einheit muss mit der Einheit des THG-Faktors übereinstimmen.</p>
            <p>Zum Beispiel, wenn die Einheit des THG-Faktors <strong>kg CO2e / kWh</strong> ist, muss Ihr Verbrauch in <strong>kWh</strong> ausgedrückt werden.</p>
            <p>Die Referenzwertdatenbank hilft Ihnen, typische Verbrauchsmengen zu finden.</p>`,
                },
                EXTRACTION_AND_MANUFACTURING: {
                  consumptionAmountTooltip: `<p>Dies ist die Menge, die auf Basis der funktionellen Einheit verbraucht wird. Die Einheit muss mit der Einheit des THG-Faktors übereinstimmen.</p>
            <p>Zum Beispiel, wenn die Einheit des THG-Faktors <strong>kg CO2e / kWh</strong> ist, muss Ihr Verbrauch in <strong>kWh</strong> ausgedrückt werden.</p>
            <p>Die Referenzwertdatenbank hilft Ihnen, typische Verbrauchsmengen zu finden.</p>`,
                },
                LOGISTICS: {
                  consumptionAmountTooltip: `<p>Dies ist die Menge, die auf Basis der funktionellen Einheit verbraucht wird. Die Einheit muss mit der Einheit des THG-Faktors übereinstimmen.</p>
            <p>Zum Beispiel, wenn die Einheit des THG-Faktors <strong>kg CO2e / kWh</strong> ist, muss Ihr Verbrauch in <strong>kWh</strong> ausgedrückt werden.</p>
            <p>Die Referenzwertdatenbank hilft Ihnen, typische Verbrauchsmengen zu finden.</p>`,
                },
                CUSTOMER_EMPOWERMENT: {
                  consumptionAmountTooltip: `<p>Dies ist die Menge, die auf Basis der funktionellen Einheit verbraucht wird. Die Einheit muss mit der Einheit des THG-Faktors übereinstimmen.</p>
            <p>Zum Beispiel, wenn die Einheit des THG-Faktors <strong>kg CO2e / kWh</strong> ist, muss Ihr Verbrauch in <strong>kWh</strong> ausgedrückt werden.</p>
            <p>Die Referenzwertdatenbank hilft Ihnen, typische Verbrauchsmengen zu finden.</p>`,
                },
              },
            },
            idematComparisonModal: {
              title: 'Finden Sie die passende Emissionsquelle',
              keywordLabel: 'Suchbegriff (*)',
              noDataText:
                'Keine Daten verfügbar. Überprüfen Sie Suchbegriff und Filter.',
              searchInfo: `<i>Nur auf Englisch suchen*</i>`,
              closeModal: 'Abbrechen',
              addAndCloseModal: 'Quelle hinzufügen',
              tableHeaders: {
                name: 'Name',
                category: 'Kategorie',
                ghgFactor: 'THG-Faktor',
                unit: 'Einheit',
                notes: 'Notizen',
                source: 'Quelle',
              },
            },
            comparativeEmissionSourceForm: {
              title: 'Vergleichsszenario',
              guidance:
                'Fügen Sie einen Emissionsfaktor und eine Verbrauchsmenge für das Vergleichsszenario hinzu:',
              baseline: {
                ownGhgText: 'Eigenen Faktor eingeben',
                searchGhgText: 'Suchen Sie nach THG-Faktor',
                conceptLabel: 'Name des THG-Faktor',
                customEvidenceLabel: 'Quelle / URL',
                ghgFactorLabel: 'THG-Faktor',
                consumptionAmountLabel: 'Menge',
                evidenceLabel: 'Begründung / unterstützende Dokumente',
                unitLabel: 'Einheit',
                assumptionLabel:
                  'Diese Daten repräsentieren das Vergleichsszenario, weil...',
              },
              closeModal: 'Speichern',
              cancelModal: 'Abbrechen',
            },
            yourSolutionEmissionSourceForm: {
              title: 'Ihre Lösung',
              guidance:
                'Was ist der Unterschied zwischen dem Vergleichsszenario und Ihrer Lösung? Erklären Sie, warum der Unterschied auftritt.',
              solution: {
                title: 'Ihre Lösung',
                ownGhgText: 'Eigenen Faktor eingeben',
                searchGhgText: 'Suchen Sie nach THG-Faktor',
                conceptLabel: 'Name des THG-Faktor',
                customEvidenceLabel: 'Quelle / URL',
                ghgFactorLabel: 'THG-Faktor (CO2e)',
                consumptionAmountLabel: 'Menge',
                consumptionCheckBoxLabel: 'Änderung im Verbrauch',
                ghgCheckBoxLabel: 'Alternativer THG-Emissionsfaktor',
                bothCheckBoxLabel: 'Beides',
                howSolutionWorksLabel: 'Dieser Unterschied tritt auf, weil...',
                assumptionTitle:
                  'Fügen Sie einen alternativen Emissionsfaktor hinzu und/oder geben Sie eine andere Verbrauchsmenge für Ihre Lösung ein:',
                unitLabel: 'Einheit',
                assumptionLabel:
                  'Diese Annahme stellt meine Lösung fair dar, weil...',
              },
              closeModal: 'Speichern',
              cancelModal: 'Abbrechen',
            },
            customComparisonModal: {
              title: 'Eigenen THG-Emissionsfaktor eingeben',
              guidanceLabel: 'Anleitung zur Auswahl eines Emissionsfaktors',
              guidanceText: `<p>Wo immer möglich, verwenden Sie den Emissionsfaktor für die genaue Aktivität/Material/Prozess, mit dem Sie arbeiten, und verwenden Sie dieselbe Datenbank für alle Faktoren. Wenn der genaue Faktor nicht verfügbar ist, werden die folgenden Alternativen empfohlen (in der Reihenfolge der Präferenz):</p> <ol> <li><strong>Verwenden Sie einen Proxy-Emissionsfaktor:</strong> Wählen Sie die Option, die eng mit einem konservativen Ansatz übereinstimmt.<br>Wenn beispielsweise spezifische Daten für Benzinmotorräder nicht verfügbar sind, erwägen Sie die Auswahl der Option für benzinbetriebene Kleinwagen.</li> <li><strong>Verwenden Sie einen angepassten Emissionsfaktor:</strong> Führen Sie einen Emissionsfaktor aus einer anderen Datenbank ein (geben Sie die Quelle an und erläutern Sie gegebenenfalls die durchgeführten Berechnungen). Wenn mehrere Datensätze verfügbar sind, wählen Sie denjenigen, der alle Lebenszyklusphasen umfasst, für Ihre Region berechnet wird und am aktuellsten ist (in dieser Reihenfolge).</li> <li><strong>Verwenden Sie interne Daten:</strong> Fügen Sie Belege hinzu und rechtfertigen Sie die Berechnung.</li> </ol>`,
              customName: 'Name des THG-Faktor',
              customGhg: `THG-Faktor (kg CO2e)`,
              customUnit: 'Einheit',
              exampleName: `_Beispiel:_ Stromnetzmix Deutschland 2023`,
              exampleGhg: `_Beispiel:_ 0.3876`,
              exampleUnit: `_Beispiel:_ kWh`,
              customEvidence: 'Quelle / URL',
              closeModal: 'Abbrechen',
              addAndCloseModal: 'Speichern',
            },
          },
          growthFactor: {
            title: 'Prognose',
            fiveYearQuestion:
              'Wie viele Einheiten Ihrer funktionalen Einheit plant Ihr Unternehmen in jedem der nächsten fünf Jahre bereitzustellen?',
            fiveYearQuestionSubtitle:
              'Wir empfehlen die Verwendung einer 5-Jahres-Prognose, aber Sie können auch einen längeren oder kürzeren Zeitraum und ein anderes Anfangsdatum wählen.',
            text: `<p>Mit dieser Prognose können Sie die potenzielle Treibhausgasreduzierung im nächsten Fünfjahreszeitraum abschätzen. Dabei handelt es sich um eine Schätzung der Anzahl der Einheiten (Ihrer Funktionseinheit), die Ihr Unternehmen in den nächsten fünf Jahren jeweils bereitstellen möchte. Wenn Ihre Funktionseinheit eine Auswirkung beschreibt, sollte die Prognose widerspiegeln, wie oft die Auswirkung jedes Jahr auftreten wird.</p>
                    Ihre Funktionseinheit ist: `,
            explanationTitle:
              'Bitte erklären und begründen Sie die von Ihnen erstellte Prognose',
            explanationPlaceholder: 'Erklärung / Begründung',
            durationOfImpactLabel:
              'Meine Lösung führt zu wiederkehrenden Einsparungen',
            durationOfImpactMessages:
              'Eine Lösung führt zu wiederkehrenden THG-Einsparungen, wenn jede produzierte Einheit die THG-Reduktion im Laufe ihrer Lebensdauer mehr als einmal erbringt. Wenn dies der Fall ist, kreuzen Sie bitte das Kästchen an und geben Sie die Dauer (in Jahren) an, die jede Einheit weiterhin Einsparungen erbringen wird. In den meisten Fällen handelt es sich um die Lebensdauer des Produkts in Jahren.',
            confirmForecastLabel:
              'Ich bestätige, dass ich eine genaue und realistische Prognose abgegeben habe.',
            exampleSolutionRecurringGhgSavingsLabel:
              'Beispiel für eine Lösung mit wiederkehrenden THG-Einsparungen',
            exampleSolutionRecurringGhgSavingsText:
              'Ein typisches Beispiel ist die Installation eines erneuerbaren Energiesystems wie PV (Solarmodule), bei dem jede installierte Einheit zu einer jährlichen Reduktion der THG-Emissionen für etwa 20 Jahre führen wird, da es fortlaufend erneuerbare Energie produziert.',
          },
          result: {
            title: 'Ergebnis',
          },
        },
      },
    },
  },
}
